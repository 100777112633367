import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  env = environment;
  constructor(private http: HttpClient) { }

  creditHistory(params: any) {
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/CreditHistory', params);
  }

  creditHistoryMonths() {
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/CreditHistoryMonths', null);
  }

  creditHealthInfo(params: any) {
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/CreditHealthInfo', params);
  }

  radarValues(){
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/GetRadarValues', null);
  }

  getChart(){
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/GetChart', null);
  }
  
  getCreditScorePercentChart(){
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/GetCreditScorePercent', null);
  }

  getProgressIndicatorScore(params: any){
    return this.http.get(this.env.webApiUrl + 'api/Dashboard/GetProgressIndicator', {params});
  }

  getSimplicityDetails(){
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/GetSimplicityDetails', null);
  }

  getYumpuMagazine(){
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/GetYumpuMagazine', null);
  }
  Load(){
    return this.http.post(this.env.webApiUrl + 'api/DBDashboard/LoadDashboard', null);
  }
  InsertUtmTracking(params: any){
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/InsertUtmTracking', params);
  }
  getCampaignAndLinkedBannerData(data:any){
    return this.http.post(this.env.webApiUrl + 'api/LeaderBoardBanner/GetCampaignAndBannerData',data );
  }
  fetchReferralData(){ 
    return this.http.post(this.env.webApiUrl + 'api/Dashboard/FetchReferralData', null);
  }
}
