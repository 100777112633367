import { FormGroup, AbstractControl } from '@angular/forms';
import { RegExValidation } from '../constants/regex-validation.constants';

// custom validator to check that two fields match
export class InputValidationService {

    static isValidMobile(control: AbstractControl) {
        const value = (control.value || '').toString();
        if (value.length !== 10) {
            // return if another validator has already found an error on the matchingControl
            return null;
        }

        // set error on matchingControl if validation fails
        if (!(RegExValidation.cellNumber.test(control.value))) {
            return { isValidMobile: true };
        } else {
            return null;
        }
    }

    static isValidIdNumber(control: AbstractControl) {
        const value = control.value ? control.value.toString() : '';
        if (value.length !== 13) {
            // return if another validator has already found an error on the matchingControl
            return null;
        }

        if (value.length === 0) { return { isValidIdNumber: true }; }

        const tempDate = new Date(value.substring(0, 2), value.substring(2, 4) - 1, value.substring(4, 6));
        if (!((String(tempDate.getFullYear()).substring(2, 4) === value.substring(0, 2)) &&
          (tempDate.getMonth() === Number(value.substring(2, 4)) - 1) &&
          (tempDate.getDate() === Number(value.substring(4, 6))))) {
          return { isValidIdNumber: true };
        } else {
          // Apply Luhn Algorithm for check-digits
          let tempTotal = 0;
          let checkSum = 0;
          let multiplier = 1;
          for (let i = 0; i < 13; ++i) {
            tempTotal = parseInt(value.charAt(i)) * multiplier;
            if (tempTotal > 9) {
              tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
            }
            checkSum = checkSum + tempTotal;
            multiplier = (multiplier % 2 === 0) ? 1 : 2;
          }
          if ((checkSum % 10) !== 0) { return { isValidIdNumber: true }; }
        }

        return null;
    }

    static passwordMustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    static isValidEmail(control: AbstractControl) {
        const value = (control.value || '').toString();
        if (value.length === 0) {
            // return if another validator has already found an error on the matchingControl
            return null;
        }

        // set error on matchingControl if validation fails
        if (!(RegExValidation.email.test(value))) {
            return { isValidEmail: true };
        } else {
            return null;
        }
    }
    static isValidDob(control: AbstractControl) {
        const value = control.value;

        if (value.length === 0) { return { isValidDob: true }; }

        var dob = new Date(value);
        var currDate = new Date();
        var validate = new Date(currDate.getFullYear()-18,currDate.getMonth(),currDate.getDate());   
        
        if(!(dob<validate)){          
            return { isValidDob: false };
          }
        else{ 
            return null;
        }
    }
    static isValidIncome(control: AbstractControl) {
        const value = control.value;

        if (value === null || value === undefined || value === '') {
            // If the value is empty, consider it valid (you may adjust this based on your requirements)
            return null;
          }
        const numbersOnlyRegex = /^[0-9]+$/;

        if (!numbersOnlyRegex.test(value)) {
        // Validation failed
        return { isValidIncome: true };
        }
        // Validation passed
        return null;
    }
    static minimumAgeValidator(control: AbstractControl): {[key: string]: any} | null {

        const value = control.value;

        if (!value || value.trim() === '') {
            return null;
        }

        const dob = new Date(control.value);
        const currDate = new Date();
        const currMonth = currDate.getMonth();
        const currDay = currDate.getDate();
        const ageLimit = new Date(currDate.getFullYear()-18, currMonth, currDay);
        
        // Check if the user's age is less than 18
        if (dob >= ageLimit) {
          return { 'underAge': true };
        }       
        // Return null if validation passes
        return null;
      }
      

}
