import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class FinancialWellnessService {

  env = environment;
  constructor(private http: HttpClient) { }

  getSurveyAnswered() {
    return this.http.post(this.env.webApiUrl + 'api/survey/GetSurveyAnswered', null);
  }

  getSurveyIndicator() {
    return this.http.post(this.env.webApiUrl + 'api/survey/GetSurveyIndicator', null);
  }

  getComputeResultForSalaryAndAgeSegment() {
    return this.http.post(this.env.webApiUrl + 'api/survey/SurveyIndicatorResult', null);
  }
  
  getPotentialDebtCounsellingUserCheck() {
    return this.http.post(this.env.webApiUrl + 'api/survey/PotentialDebtCounsellingUserCheck', null);
  }
}
