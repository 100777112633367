<section class="payments pt-5 pb-4 pb-md-5 b5containeradjust" *ngIf="!isRedisCacheDown; else RedisCacheIsDown">
    <div class="container" >
        <div class="payment-box p-4 mb-custom d-none d-md-block" *ngIf="nextPayment != null">
            <h3 class="payment-caption mb-3">Next Month's Payment</h3>
            <div class="d-flex w-100">
                <div class="col-md-2 py-2 bg-gry text-charcoal text-bold">
                    Month
                </div>
                <div class="col-md-2 py-2 bg-gry text-charcoal text-bold text-center">
                    Expected Amount
                </div>
            </div>
            <div class="d-flex w-100">
                <div class="col-md-2 py-3 text-charcoal text-semibold textsm">
                    {{nextPayment?.period | date:"LLL yyyy" }}
                </div>
                <div class="col-md-2 py-3 text-charcoal text-center textsm">
                    {{nextPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0' }}
                </div>
            </div>
        </div>


        <div class="payment-box p-4 mb-custom d-none d-md-block" *ngIf="currentPayment != null">
            <h3 class="payment-caption mb-3">This Month's Payment</h3>
            <div class="paymenttable">
                <div class="d-flex w-100 rowborder">
                    <div class="col-md-2 py-2 bg-gry text-charcoal text-bold">
                        Month
                    </div>
                    <div class="col-md-2 py-2 text-center bg-gry text-charcoal text-bold">
                        Expected Amount
                    </div>
                    <div class="col-md-3 py-2 text-center text-center bg-gry text-charcoal text-bold">
                        Paid Amount
                    </div>
                    <div class="col-md-3 py-2 text-center bg-gry text-charcoal text-bold">
                        Debt Review Balance
                    </div>
                    <div class="col-md-2 py-2 bg-gry text-charcoal text-bold ps-5">
                        Status
                    </div>
                </div>
                <div class="d-flex w-100 rowborder">
                    <div class="col-md-2 py-3 text-charcoal text-semibold textsm">
                        {{currentPayment?.period | date:"LLL yyyy" }}
                    </div>
                    <div class="col-md-2 text-center py-3 text-charcoal textsm">
                        {{currentPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0'}}
                    </div>
                    <div class="col-md-3 text-center py-3 text-charcoal textsm">
                        {{currentPayment?.paidAmount | currency:'R ': 'symbol' : '1.0-0'}}
                    </div>
                    <div class="col-md-3 text-center py-3 text-charcoal textsm">
                        {{currentPayment?.balance | currency:'R ': 'symbol' : '1.0-0'}}
                    </div>
                    <div class="col-md-2 py-3 text-charcoal textsm d-flex align-previousPayments-center ps-5">
                       <span *ngIf="currentPayment?.status.toLowerCase() === 'paid'">
                        <i class="payment-status-paid me-1"></i> {{currentPayment?.status}}
                     </span>
                    <span *ngIf="currentPayment?.status.toLowerCase() === 'unpaid'">
                       <i class="payment-status-unpaid me-1"></i>{{currentPayment?.status}}
                     </span>
                     <span *ngIf="currentPayment?.status.toLowerCase() === 'expected'">
                         <i class="payment-status-expected me-1"></i>{{currentPayment?.status}}
                     </span>
                     <span *ngIf="currentPayment?.status.toLowerCase() === 'partially paid'">
                        <i class="payment-status-unpaid me-1"></i>{{currentPayment?.status}}
                    </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="payment-box p-4 mb-custom d-none d-md-block" *ngIf="previousPayments != null">
            <h3 class="payment-caption mb-3">Payment History</h3>
            <div class="paymenttable">
                <div class="d-flex w-100 rowborder">
                    <div class="col-md-2 py-2 bg-gry text-charcoal text-bold">
                        Month
                    </div>
                    <div class="col-md-2 py-2 text-center bg-gry text-charcoal text-bold">
                        Expected Amount
                    </div>
                    <div class="col-md-3 py-2 text-center text-center bg-gry text-charcoal text-bold">
                        Paid Amount
                    </div>
                    <div class="col-md-3 py-2 text-center bg-gry text-charcoal text-bold">
                        Debt Review Balance
                    </div>
                    <div class="col-md-2 py-2 bg-gry text-charcoal text-bold ps-5">
                        Status
                    </div>
                </div>
                <div class="d-flex w-100 rowborder" *ngFor="let previousPayment of previousPayments;">
                    <div class="col-md-2 py-3 text-charcoal text-semibold textsm">
                        {{previousPayment?.period | date:"LLL yyyy" }}
                    </div>
                    <div class="col-md-2 text-center py-3 text-charcoal textsm">
                        {{previousPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0'}}
                    </div>
                    <div class="col-md-3 text-center py-3 text-charcoal textsm">
                       {{previousPayment?.paidAmount | currency:'R ': 'symbol' : '1.0-0'}}
                    </div>
                    <div class="col-md-3 text-center py-3 text-charcoal textsm">
                        {{previousPayment?.balance | currency:'R ': 'symbol' : '1.0-0'}}
                    </div>
                    <div class="col-md-2 py-3 text-charcoal textsm d-flex align-previousPayments-center ps-5">
                       <span *ngIf="previousPayment?.status.toLowerCase() === 'paid'">
                           <i class="payment-status-paid me-1"></i> {{previousPayment?.status}}
                        </span>
                       <span *ngIf="previousPayment?.status.toLowerCase() === 'unpaid'">
                          <i class="payment-status-unpaid me-1"></i>{{previousPayment?.status}}
                        </span>
                        <span *ngIf="previousPayment?.status.toLowerCase() === 'expected'">
                            <i class="payment-status-expected me-1"></i>{{previousPayment?.status}}
                        </span>
                        <span *ngIf="previousPayment?.status.toLowerCase() === 'partially paid'">
                            <i class="payment-status-expected me-1"></i>{{previousPayment?.status}}
                        </span>
                    </div>
                </div> 
               

                <!-- <div class="collapse" id="seeMore">
                        <div class="d-flex w-100 rowbordercollapse">
                            <div class="col-md-2 py-3 text-charcoal text-semibold textsm">
                                July 2022
                            </div>
                            <div class="col-md-2 text-center py-3 text-charcoal textsm">
                                R5 500.00
                            </div>
                            <div class="col-md-3 text-center py-3 text-charcoal textsm">
                                R5 054.20
                            </div>
                            <div class="col-md-3 text-center py-3 text-charcoal textsm">
                                R5 500.00
                            </div>
                            <div class="col-md-2 py-3 text-charcoal textsm d-flex align-previousPayments-center ps-5">
                               <i class="payment-status-paid me-1"></i> paid
                            </div>
                        </div>
                        <div class="d-flex w-100 rowbordercollapse">
                            <div class="col-md-2 py-3 text-charcoal text-semibold textsm">
                                July 2022
                            </div>
                            <div class="col-md-2 text-center py-3 text-charcoal textsm">
                                R5 500.00
                            </div>
                            <div class="col-md-3 text-center py-3 text-charcoal textsm">
                                R5 054.20
                            </div>
                            <div class="col-md-3 text-center py-3 text-charcoal textsm">
                                R5 500.00
                            </div>
                            <div class="col-md-2 py-3 text-charcoal textsm d-flex align-previousPayments-center ps-5">
                               <i class="payment-status-unpaid me-1"></i> unpaid
                            </div>
                        </div>
                    
                </div> -->
            </div>

            <!-- <div class="text-center pt-3">
                <button class="seemore collapsed" type="button" data-toggle="collapse" data-target="#seeMore" aria-expanded="false" aria-controls="seeMore">
                    <span class="seemorebtmborder">See more</span>
                    <span class="arrowrotate">
                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 34" width="51" height="34"><defs><clipPath clipPathUnits="userSpaceOnUse" id="cp1"><path d="m-939-1552h1920v1756h-1920z"/></clipPath></defs><style>.a{fill:none;stroke:#2b2b2b;stroke-width:6}</style><g clip-path="url(#cp1)"><path class="a" d="m42 8.8l-6.9 6.9-9.7 9.6-16.5-16.5"/></g></svg>
                    </span>
                </button>
            </div> -->
            
        </div>


        <div class="payment-box missedPayment p-4 d-none d-md-block" id="divMissedPayment" *ngIf="missedPayments != null && missedPayments.length > 0">
            <h3 class="payment-caption mb-3">Missed Payments</h3>
            <div class="paymenttable col-md-7 px-0">
                <div class="d-flex w-100 rowborder">
                    <div class="col-md-3 py-2 bg-gry text-charcoal text-bold">
                        Month
                    </div>
                    <div class="col-md-4 py-2 text-center bg-gry text-charcoal text-bold">
                        Expected Amount
                    </div>
                    <div class="col-md-5 py-2 text-center text-center bg-gry text-charcoal text-bold">
                        Paid Amount
                    </div>
                </div>
                <div class="d-flex w-100 rowborder" *ngFor="let missedPayment of missedPayments | slice:0:6">
                    <div class="col-md-3 py-3 text-charcoal text-semibold textsm">
                        {{missedPayment?.period | date:"LLL yyyy" }}
                    </div>
                    <div class="col-md-4 text-center py-3 text-charcoal textsm">
                        {{missedPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0'}}
                    </div>
                    <div class="col-md-5 text-center py-3 text-charcoal textsm">
                       {{missedPayment?.paidAmount | currency:'R ': 'symbol' : '1.0-0'}}
                    </div>
                </div>
                <div *ngIf="missedPayments.length > 6">
                    
                    <div class="collapse" id="collapseExample">
                        <div class="d-flex w-100 rowborder ndrow" *ngFor="let missedPayment of missedPayments | slice:6">
                            <div class="col-md-3 py-3 text-charcoal text-semibold textsm">
                                {{missedPayment?.period | date:"LLL yyyy" }}
                            </div>
                            <div class="col-md-4 text-center py-3 text-charcoal textsm">
                                {{missedPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0'}}
                            </div>
                            <div class="col-md-5 text-center py-3 text-charcoal textsm">
                            {{missedPayment?.paidAmount | currency:'R ': 'symbol' : '1.0-0'}}
                            </div>
                        </div> 
                    </div>
                    <div class="button text-center py-md-3">
                        <a class="btn paynow more-payment collapsed" id="anchor-MorePaymentCollapse-Payment" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                            
                        </a>
                    </div> 

                    
                </div>
                
                
               

            </div>
            
        </div>

        <!--mobile view only-->
        <div class="d-md-none d-block" >
            <div class="mobile-paymeny-row mb-3" *ngIf="nextPayment != null">
                <h3 class="payment-caption mb-3"   >Next Month's Payment</h3>
                <div class="payment-box p-1 mb-custom">
                    <div class="mobile-payment-row d-flex w-100 align-previousPayments-center">
                        <div class="col-md-2 py-3 px-3 col-6 text-charcoal text-semibold mobviewtext">
                            {{nextPayment?.period | date:"LLL yyyy" }}
                        </div>
                        
                        <div class="col-md-2 py-3 px-3 col-6 text-charcoal text-center textsm">
                            <span class="d-block table-caption-mobile">Expected Amount</span>
                            <span class="d-block table-text">{{nextPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0' }}</span>
                        </div>
                    </div>
                </div>
            </div>


            <div class="mobile-paymeny-row mb-3 paymentmobile"  *ngIf="currentPayment != null">
                <h3 class="payment-caption mb-3" >This Month's Payment</h3>
                <ngb-accordion [closeOthers]="true">
                    <ngb-panel id="thisMontsPayment">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-previousPayments-center justify-content-between">
                              <button ngbPanelToggle id="btn-ngbPanelToggle1-Payment" class="accordion-title p-0 w-100">
                                <div class="w-100 d-flex align-previousPayments-center">
                                  <div class="col-md-10 col-10 ps-md-3 ps-1 d-flex align-previousPayments-center justify-content-between">
                                    <span class="accordion-heading ">
                                      <span class="mobviewtext d-flex">
                                        <div class="pymentstatus">
                                            <i class="payment-status-paid"></i>
                                          </div>
                                          {{currentPayment?.period | date:"LLL yyyy" }}
                                      </span>
                                      <span class="payment-status">{{currentPayment?.status}}</span>
                                    </span>
                                    <div class="currntBalTop">
                                      <span class="d-block table-caption-mobile">Paid Amount</span>
                                      <span class="d-block table-text">{{currentPayment?.paidAmount | currency:'R ': 'symbol' : '1.0-0' }}</span>
                                    </div>
                                  </div>
                                  <i class="down-arrow arrow-blue ms-auto align-self-center">
                                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 29" width="25" height="15"><defs><clipPath clipPathUnits="userSpaceOnUse" id="cp1"><path d="m-1006-1407h1119v2640h-1119z"/></clipPath></defs><style>.a{fill:none;stroke:#2b2b2b;stroke-width:4}</style><g clip-path="url(#cp1)"><path class="a" d="m6.3 22l6.6-6.6 9.3-9.2 15.8 15.8"/></g></svg>  
                                  
                                </i>
                                </div>
                              </button>
                            </div>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="d-flex align-previousPayments-end ps-dm pt-3">
                              <div class="d-flex flex-wrap w-100 justify-content-between">
                                <div class="col-6 ps-0">
                                    <span class="d-block table-caption-mobile text-charcoal">Expected Amount</span>
                                    <span class="d-block table-text text-charcoal">{{currentPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0' }}</span>
                                </div>
                                <div class="pe-0 col-6">
                                    <span class="d-block table-caption-mobile text-charcoal text-center">Paid Amount</span>
                                    <span class="d-block table-text text-charcoal text-center">{{currentPayment?.paidAmount | currency:'R ': 'symbol' : '1.0-0' }}</span>
                                </div>
                                <span class="divider w-100 d-block pt-3 mt-3"></span>
                                <div class="col-6 ps-0">
                                    <span class="d-block table-caption-mobile text-charcoal">Debt Review Balance</span>
                                </div>
                                <div class="col-6 pe-0">
                                    <span class="d-block table-caption-mobile text-charcoal text-center text-semibold"> {{currentPayment?.balance | currency:'R ': 'symbol' : '1.0-0' }}</span>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>

            <div class="mobile-paymeny-row mb-3 paymentmobile" *ngIf="previousPayments != null">
                <h3 class="payment-caption mb-3">Payment History</h3>
                <ngb-accordion>

                    
                    <ngb-panel id="accordianPaymentHistory-{{i+1}}" *ngFor="let previousPayment of previousPayments; let i = index">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-previousPayments-center justify-content-between">
                              <button ngbPanelToggle id="btn-ngbPanelToggle2-Payment" class="accordion-title p-0 w-100">
                                <div class="w-100 d-flex align-previousPayments-center">
                                  <div class="col-md-10 col-10 ps-md-3 ps-1 d-flex align-previousPayments-center justify-content-between">
                                    <span class="accordion-heading ">
                                      <span class="mobviewtext d-flex">
                                        <div class="pymentstatus">
                                            <i class="payment-status-paid"></i>
                                          </div>
                                          {{previousPayment?.period | date:"LLL yyyy" }}
                                      </span>
                                      <span class="payment-status">{{previousPayment?.status}}</span>
                                    </span>
                                    <div class="currntBalTop">
                                      <span class="d-block table-caption-mobile">Paid Amount</span>
                                      <span class="d-block table-text">{{previousPayment?.paidAmount | currency:'R ': 'symbol' : '1.0-0'}}</span>
                                    </div>
                                  </div>
                                  <i class="down-arrow arrow-blue ms-auto align-self-center">
                                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 29" width="25" height="15"><defs><clipPath clipPathUnits="userSpaceOnUse" id="cp1"><path d="m-1006-1407h1119v2640h-1119z"/></clipPath></defs><style>.a{fill:none;stroke:#2b2b2b;stroke-width:4}</style><g clip-path="url(#cp1)"><path class="a" d="m6.3 22l6.6-6.6 9.3-9.2 15.8 15.8"/></g></svg>  
                                  </i>
                                </div>
                              </button>
                            </div>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="d-flex align-previousPayments-end ps-dm pt-3" >
                              <div class="d-flex flex-wrap w-100 justify-content-between">
                                <div class="col-6 ps-0">
                                    <span class="d-block table-caption-mobile text-charcoal">Expected Amount</span>
                                    <span class="d-block table-text text-charcoal">{{previousPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0'}}</span>
                                </div>
                                <div class="pe-0 col-6">
                                    <span class="d-block table-caption-mobile text-charcoal text-center">Paid Amount</span>
                                    <span class="d-block table-text text-charcoal text-center">{{previousPayment?.paidAmount | currency:'R ': 'symbol' : '1.0-0'}}</span>
                                </div>
                                <span class="divider w-100 d-block pt-3 mt-3"></span>
                                <div class="col-6 ps-0">
                                    <span class="d-block table-caption-mobile text-charcoal">Debt Review Balance</span>
                                </div>
                                <div class="col-6 pe-0">
                                    <span class="d-block table-caption-mobile text-charcoal text-center text-semibold">{{previousPayment?.balance | currency:'R ': 'symbol' : '1.0-0' }}</span>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div> 


            <div class="mobile-paymeny-row mb-3 paymentmobile"  *ngIf="missedPayments != null && missedPayments.length > 0">
                <h3 class="payment-caption mb-3">Missed Payments</h3>
                <ngb-accordion>
                    <ngb-panel id="accordianmissedPaymentHistory-{{i+1}}" *ngFor="let missedPayment of missedPayments | slice:0:6; let i = index">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-previousPayments-center justify-content-between">
                              <button ngbPanelToggle id="btn-ngbPanelToggle3-Payment" class="accordion-title p-0 w-100">
                                <div class="w-100 d-flex align-previousPayments-center">
                                  <div class="col-md-10 col-10 ps-md-3 ps-1 d-flex align-previousPayments-center justify-content-between">
                                    <span class="accordion-heading ">
                                      <span class="mobviewtext d-flex">
                                          {{missedPayment?.period | date:"LLL yyyy" }}
                                      </span>
                                      
                                    </span>
                                    <div class="currntBalTop">
                                      <span class="d-block table-caption-mobile">Expected Amount</span>
                                      <span class="d-block table-text">{{missedPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0'}}</span>
                                    </div>
                                  </div>
                                  <i class="down-arrow arrow-blue ms-auto align-self-center">
                                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 29" width="25" height="15"><defs><clipPath clipPathUnits="userSpaceOnUse" id="cp1"><path d="m-1006-1407h1119v2640h-1119z"/></clipPath></defs><style>.a{fill:none;stroke:#2b2b2b;stroke-width:4}</style><g clip-path="url(#cp1)"><path class="a" d="m6.3 22l6.6-6.6 9.3-9.2 15.8 15.8"/></g></svg>  
                                  </i>
                                </div>
                              </button>
                            </div>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="d-flex align-previousPayments-end ps-dm pt-3" >
                              <div class="d-flex flex-wrap w-100 justify-content-between">
                                <div class="col-6 ps-0">
                                    <span class="d-block table-caption-mobile text-charcoal">Expected Amount</span>
                                    <span class="d-block table-text text-charcoal">{{missedPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0'}}</span>
                                </div>
                                <div class="pe-0 col-6">
                                    <span class="d-block table-caption-mobile text-charcoal text-center">Paid Amount</span>
                                    <span class="d-block table-text text-charcoal text-center">{{missedPayment?.paidAmount | currency:'R ': 'symbol' : '1.0-0'}}</span>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div *ngIf="missedPayments.length > 6">
                    <div class="collapse" id="collapseExample">
                        <ngb-accordion>
                            <ngb-panel id="accordianmissedMorePaymentHistory-{{i+1}}" *ngFor="let missedPayment of missedPayments | slice:6; let i = index">
                                <ng-template ngbPanelHeader let-opened="opened">
                                    <div class="d-flex align-previousPayments-center justify-content-between">
                                      <button ngbPanelToggle id="btn-ngbPanelToggle4-Payment" class="accordion-title p-0 w-100">
                                        <div class="w-100 d-flex align-previousPayments-center">
                                          <div class="col-md-10 col-12 ps-md-3 ps-1 d-flex align-previousPayments-center justify-content-between">
                                            <span class="accordion-heading ">
                                              <span class="mobviewtext d-flex">
                                                  {{missedPayment?.period | date:"LLL yyyy" }}
                                              </span>
                                              
                                            </span>
                                            <div class="currntBalTop">
                                              <span class="d-block table-caption-mobile">Expected Amount</span>
                                              <span class="d-block table-text">{{missedPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0'}}</span>
                                            </div>
                                          </div>
                                          <i class="down-arrow arrow-blue ms-auto align-self-center">
                                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 29" width="25" height="15"><defs><clipPath clipPathUnits="userSpaceOnUse" id="cp1"><path d="m-1006-1407h1119v2640h-1119z"/></clipPath></defs><style>.a{fill:none;stroke:#2b2b2b;stroke-width:4}</style><g clip-path="url(#cp1)"><path class="a" d="m6.3 22l6.6-6.6 9.3-9.2 15.8 15.8"/></g></svg>  
                                          </i>
                                        </div>
                                      </button>
                                    </div>
                                  </ng-template>
                                  <ng-template ngbPanelContent>
                                    <div class="d-flex align-previousPayments-end ps-dm pt-3" >
                                      <div class="d-flex flex-wrap w-100 justify-content-between">
                                        <div class="col-6 ps-0">
                                            <span class="d-block table-caption-mobile text-charcoal">Expected Amount</span>
                                            <span class="d-block table-text text-charcoal">{{missedPayment?.expectedAmount | currency:'R ': 'symbol' : '1.0-0'}}</span>
                                        </div>
                                        <div class="pe-0 col-6">
                                            <span class="d-block table-caption-mobile text-charcoal text-center">Paid Amount</span>
                                            <span class="d-block table-text text-charcoal text-center">{{missedPayment?.paidAmount | currency:'R ': 'symbol' : '1.0-0'}}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                    
                    <div class="button text-center py-md-3">
                        <a class="btn paynow more-payment ps-md-4 collapsed" id="anchor-PayNowCollapse-Payement" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                        </a>
                    </div> 
                </div>
                
            </div>

        </div>
    </div>
</section>


<ng-template #RedisCacheIsDown>
    <div class="d-flex align-items-center w-100 justify-content-between pb-3">
      <div class="col-md-6">
        <h1 class="cache-caption">Something’s wrong here. </h1>
        <p class="cache-details mt-3">
          <span class="d-block ">We’ll be back in a few minutes</span> 
          to get you sorted. 
        </p>
      </div>
      <div class="col-md-6 text-right">
        <img src="../../assets/images/404.png" class="cache-image" alt=""/>
      </div>
    </div>
</ng-template>