  <!-- Banner HTML Start -->
  <!--<section class="banner-hld">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
            <h1 class="text-center">Offers Tailored for you</h1>
        </div>

      </div>
    </div>
  </section>-->
  <!-- Banner HTML End -->
  
  <!-- Home content HTML Start -->
  <section class="product-cont-hld bg-white b5containeradjust">
    <div class="container">
      <div class="row">
        <div class="col-md-12  bg-white cshadow rounded px-md-4 mb-md-5">
            <h2 class="sol-caption pt-4">Solutions for You</h2>
            <p class="sol-text">A range of financial products suited to your profile.</p>

            <div class="products-hld">
              <h2 class="product-caption">Get Money</h2>
              <div class="products-inn">
                  <section class="products-list-out">
                  <ul class="products-list d-flex align-items-stretch">
                    <li class="d-flex align-items-stretch flex-wrap perloan">
                        <span class="img-left">
                            <img src="/assets/images/pro-img-3.jpg" />
                        </span>
                        <article class="txt-right d-flex align-items-stretch">
                            <div class="position-relative">
                              <h3>Personal Loans</h3>
                              <h4>DebtBusters Personal Loans</h4>
                              <p>Get a loan of up to R200 000 at a fixed interest rate.</p>
                              <a id="anchor-FindOutMore1-Products" href="#" target="_blank" class="btn">Find out More</a>
                            </div>
                          </article>
                    </li> 
                  </ul>
                  <!--<a href="javascript:void(0)" class="btn-border">View all products</a>-->
                </section>
              </div>
              
          </div>

            

            
            <hr/>
          <div class="products-hld">
            <h2  class="product-caption">Protect Money</h2>
            <div class="products-inn">
                <section class="products-list-out">
                <ul class="products-list">
                  <li class="d-flex align-items-stretch flex-wrap">
                      <span class="img-left">
                          <img src="/assets/images/pro-img-5.jpg" />
                      </span>
                      <article class="txt-right d-flex align-items-stretch">
                          <div class="position-relative">
                            <h3>Life Insurance</h3>
                            <h4>DebtBusters Life Insurance</h4>
                            <p>DebtBusters advisers aim to provide life insurance that is simpler and more rewarding.</p>
                            <a href="#" id="anchor-FindOutMore2-Products" target="_blank" class="btn">Find out More</a>
                          </div>
                      </article>
                  </li>
                  <li class="d-flex align-items-stretch flex-wrap">
                    <span class="img-left">
                        <img src="/assets/images/pro-img-6.jpg" />
                    </span>
                    <article class="txt-right d-flex align-items-stretch">
                        <div class="position-relative">
                          <h3>Funeral Cover</h3>
                          <h4>DebtBusters Funeral Cover</h4>
                          <p>Get up to R100 000 funeral cover that pays out most claims in four hours.</p>
                          <a href="#" id="anchor-FindOutMore3-Products" target="_blank" class="btn">Find out More</a>
                        </div>
                      </article>
                </li>
                </ul>
                <!--<a href="javascript:void(0)" class="btn-border">View all products</a>-->
              </section>
            </div>
            
        </div>
        <hr/>
        
        <div class="products-hld">
          <h2 class="product-caption">Save Money</h2>
            <div class="products-inn">
                
                <section class="products-list-out">
                <ul class="products-list">
                  <li class="d-flex align-items-stretch flex-wrap">
                      <span class="img-left">
                          <img src="/assets/images/pro-img-1.jpg" />
                      </span>
                      <article class="txt-right d-flex align-items-stretch">
                        <div class="position-relative">
                          <h3>Retirement Plan</h3>
                          <h4>Cumulus Echo Retirement Plan</h4>
                          <p>Boost your retirement savings with a retirement plan that pays a bonus.
                              The longer you save, the bigger the bonus.</p>
                          <a href="#" id="anchor-FindOutMore4-Products" target="_blank" class="btn">Find out More</a>
                      
                        </div>    
                      </article>
                  </li>
                  <li class="d-flex align-items-stretch flex-wrap">
                    <span class="img-left">
                        <img src="/assets/images/pro-img-2.jpg" />
                    </span>
                    <article class="txt-right d-flex align-items-stretch">
                        <div class="position-relative">
                          <h3>Tax-free Savings</h3>
                          <h4>Save for your long-term goals</h4>
                          <p>Save for your long-term goals without having to pay tax on interest, dividends, and capital gains.</p>
                          <a href="#" id="anchor-FindOutMore5-Products" target="_blank" class="btn">Find out More</a>
                        </div>
                      </article>
                </li>
                </ul>
               <!-- <a href="javascript:void(0)" class="btn-border">View all products</a>-->
              </section>
            </div>
            
        </div>
        <hr/>
        <div class="products-hld">
          <h2  class="product-caption">Financial Planning</h2>
          <div class="products-inn">
              <section class="products-list-out">
              <ul class="products-list">
                <li class="d-flex align-items-stretch flex-wrap">
                    <span class="img-left">
                        <img src="/assets/images/pro-img-5.jpg" />
                    </span>
                    <article class="txt-right d-flex align-items-stretch">
                      <div class="position-relative">
                        <h3>Get Advice</h3>
                        <h4>Find the right financial planner</h4>
                        <p>DebtBusters advisers aim to provide you with professional advice and can help you shape a complete financial plan.</p>
                        <a href="#" id="anchor-FindOutMore6-Products" target="_blank" class="btn">Find out More</a>
                      </div>
                    </article>
                </li>
                <li class=" align-items-stretch flex-wrap">
                  <span class="img-left">
                      <img src="/assets/images/pro-img-6.jpg" />
                  </span>
                  <article class="txt-right align-items-stretch">
                      <div class="position-relative">
                        <h3>Online Will</h3>
                        <h4>Draft your will online in minutes</h4>
                        <p>A will stipulates how your property, possessions, money and other assets will be distributed when you pass away.</p>
                        <a href="#" id="anchor-FindOutMore7-Products" target="_blank" class="btn">Find out More</a>
                      </div>
                    </article>
              </li>
              </ul>
              <!--<a href="javascript:void(0)" class="btn-border">View all products</a>-->
            </section>
          </div>
          
      </div>
      </div>
      </div>
    </div>
  </section>
  <!-- Home content HTML End -->

  <!-- Contact HTML Start 
  <app-contact></app-contact>-->
  <!-- Contact HTML End -->
  