import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class QuestionnaireStepService {

  env = environment;
  constructor(private http: HttpClient) { }

  getSurveyQuestions() {
    return this.http.post(this.env.webApiUrl + 'api/survey/GetSurveyQuestions', null);
  }

  upsertSurvey(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/survey/UpsertSurvey', data);
  }
}
