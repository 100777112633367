import { Component, OnInit } from '@angular/core';
import { QuestionnaireStepService } from '../services/questionnaire-step.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import * as $ from 'jquery';
import { Router } from '@angular/router';

@Component({
  selector: 'app-questionnaire-step',
  templateUrl: './questionnaire-step.component.html',
  styleUrls: ['./questionnaire-step.component.css']
})
export class QuestionnaireStepComponent implements OnInit {

  questionList;
  currDiv = 1;
  questionType = 1;
  isSelectedAny = false;
  isOptionalChange = false;

  constructor(private questionnaireStepService: QuestionnaireStepService, public router: Router) { }

  ngOnInit() {
    $('#spinner').hide();
    this.getSurveyQuestions(() => {
      if (this.questionList[0].attemptsCount === this.questionList.length) {
        this.router.navigate(['/portal/financial-wellness']);
      }

      if (this.questionList[0].attemptsCount < this.questionList.length) {
        this.currDiv = parseInt(this.questionList[0].attemptsCount) + 1;
      }
    });
  }

  getSurveyQuestions(callback) {
    this.questionList = [];
    this.questionnaireStepService.getSurveyQuestions().subscribe((data: any) => {
      this.questionList = data;
      $('#spinner').hide();
      if (callback) {
        callback();
      }
    }, (err: HttpErrorResponse) => {

    });
  }

  showDiv(stepVal, questionId, isAttempt, finished) {

    console.log(isAttempt, 'isAttempt');
    console.log(this.isSelectedAny, 'isSelectedAny');
    console.log(this.isOptionalChange, 'isOptionalChange');
    console.log(finished, 'finished');

    if (finished === 'previous') {
      this.currDiv = stepVal;
      this.isSelectedAny = false;
      this.isOptionalChange = false;
      return;
    }

    if (isAttempt === true && this.isSelectedAny === false && this.isOptionalChange === false) {
      this.currDiv = stepVal;
    } else {
      const retValue = this.questionForm(questionId);
      if (retValue === 1) {
        //this.currDiv = stepVal;
        this.isSelectedAny = false;
        this.isOptionalChange = false;
        setTimeout(() => {
          $('#spinner').show();
            this.getSurveyQuestions(() => {
              this.currDiv = stepVal;
            }
          );
        }, 1200);
      } else {
        alert('You need to select an option to move to move further');
        return;
      }
    }

    if (finished === 'finished') { 
      localStorage.setItem("SurveySuccessfullyCompeleted","true");
      setTimeout(() => {
        this.router.navigate(['/portal/financial-wellness']);
      }, 1000);
    }
  }

  onItemChange(ans, questionId, value) {
    this.isSelectedAny = value;
    const data = this.questionList.find(item => item.id === questionId);
    this.questionType = 2;
    if (data.type === 1) {
      for (const item of data.surveyAnswers) {
        item.selected = false;
      }
      ans.selected = true;
      this.questionType = 1;
      this.isOptionalChange = false;
    } else {
      this.isOptionalChange = true;
      this.isSelectedAny = true;
    }
  }

  questionForm(questionId) {
    if (this.isSelectedAny === false) {
      return 0;
    }

    const answers = this.questionList.find(item => item.id === questionId).surveyAnswers;

    const answer = [];
    if (this.questionType === 2) {
      $('input:checkbox[name=answer]:checked').each(function () {
        answer.push(parseInt($(this).attr('answer')));
      });
    } else {
      answer.push(answers.find(i => i.selected === true).answerId);
    }

    if (Array.isArray(answer) && answer.length > 0) {
      const param = { QuestionId: questionId, AnswerIds: answer };
      this.questionnaireStepService.upsertSurvey(param).subscribe((data: any) => {            
      }, (err: HttpErrorResponse) => {
        console.log(err);
      });
    } else {
      return 0;
    }

    return 1;
  }

}
