import { Component, HostListener, ViewChild, ElementRef, OnInit, Input,Renderer2 } from '@angular/core';
import { NavigationStart, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Event as NavigationEvent } from '@angular/router';
import { UserService } from './services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Guid } from 'guid-typescript';
import { OAuthService, NullValidationHandler, AuthConfig } from 'angular-oauth2-oidc';
import * as $ from 'jquery';
import { MessagingService } from './services/messaging.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from './services/app.service';
import { AppInsightsService } from './services/appinsights.service';
import { MessagesService } from './services/messages.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  host: { 'class': 'd-flex flex-column h-100' },
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('logoutModel', { static: false }) logoutModel: ElementRef;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  env = environment;
  public modalOptions: NgbModalOptions;
  appInsights: ApplicationInsights;
  idNumber;
  notificationClickTrack;
  userType;
  userData;
  getUserType: boolean = false;
  messages: any[] = [];
  subscription: Subscription;
  isFirstLoad = true;

  constructor(
    private router: Router,
    private userService: UserService,
    private idle: Idle,
    private keepalive: Keepalive,
    private modalService: NgbModal,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private messagingService: MessagingService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private appService: AppService,
    private appInsightsService: AppInsightsService,
    private messageService: MessagesService,
    private renderer:Renderer2
  ) {

    this.configureAzureAd();
    this.oauthService.tryLoginImplicitFlow();

    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      keyboard: false
    };

    if (this.cookieService.get('submissionId') === '') {
      this.cookieService.set('submissionId', Guid.create().toString(), null, '/', undefined, true);
    }
    if (sessionStorage.getItem('sessionId') == null) {  
      //  this.cookieService.set('sessionId', Guid.create().toString(), null, '/', undefined, isSecure,'Strict');
      sessionStorage.setItem('sessionId', Guid.create().toString());
    } 

    this.appInsights = appInsightsService.appInsightsObj();
    if (this.cookieService.get('userData') != null && this.cookieService.get('userData') !== '') {
      this.idNumber = JSON.parse(this.cookieService.get('userData')).idNumber;
    }

    router.events
      .pipe(
        filter((event: NavigationEvent) => {
          return (event instanceof NavigationStart);
        })
      )
      .subscribe((event: NavigationStart) => {
        console.log('Navigation Start');
        setTimeout(() => {
          if (event.url === '/register') {
            //$('.navlinks').hide();
            // this.showNavButtons(window.innerWidth);
          } else {
            //$('.navlinks').show();
          }
        }, 200);

        var isEmptyState = localStorage.getItem('isEmptyState');
        if ((event.url === '/portal/financial-wellness' || event.url === '/portal/account') && (isEmptyState != '' && isEmptyState == 'true')) {
          this.router.navigateByUrl('/portal/dashboard');
        }

        if (this.oauthService.getIdentityClaims()) {
          this.reset();
        } else {
          this.idle.stop();
        }

        
          

      });

    router.events
      .pipe(
        filter((event: NavigationEvent) => {
          return (event instanceof NavigationEnd);
        })
      )
      .subscribe((e: NavigationEnd) => {
        console.log('Navigation End');
       

        const userId = this.cookieService.get('userId');
        this.appInsights.trackPageView({ properties: { SubmissionId: this.cookieService.get('submissionId'), UserId: userId } });

        if (e.url === '/portal/security-question2') {
          localStorage.removeItem('OnLoadCheck');
        }

        this.appService.showHideTopMenubar();

        if(!window.location.hash)
        {
          this.trackPageViews();
        }
        // const trackService = window["trackingService"];
        
        // if(!(this.isFirstLoad && trackService)){
        //   trackService?.trackPageViews(environment.trackingId);
        //   console.log("trackService in navigation end",window.location.href);
        // }
        // this.isFirstLoad = false;
      });


      this.subscription = this.messageService.getMessage().subscribe(message => {
        if (message) {
          this.messages.push(message);
          this.createfreshChatForUserType1()
        } else {
          // clear messages when empty message received
          this.messages = [];
        }
      });
  }

  trackPageViews() {
    const trackingService = (window as any).trackingService;
    console.log('trackingservice', trackingService);
    console.log('trackingservice href', window.location.href);
    if (trackingService) {
      trackingService.trackPageViews(environment.trackingId);
    } else {
      setTimeout(() => {
        this.trackPageViews();
      }, 100);
    }
  }


  @HostListener('window:resize', [])
  public onResize() {
    if (location.pathname === '/register') {
      //this.showNavButtons(window.innerWidth);
    }
  }

  private showNavButtons(width) {
    if (width > 767) {
      $('.navlinks').hide();
    } else {
      $('.navlinks').show();
    }
  }


  ngOnInit() {
    console.log("in app component init");
    //tracking script
    let script = this.renderer.createElement('script');
    script.type = 'module';
    script.innerHTML = `import * as tl from "` + environment.trackingUrl + `"
    tl.trackEvent('` + environment.trackingId + `',["span"]);
    window.trackingService = tl;`;
    this.renderer.appendChild(document.head, script);
    this.checkTypeForFreshChat();
    // sets an idle timeout of 3 seconds, for testing purposes.
    this.idle.setIdle(this.env.sessionTimeOut);
    // sets a timeout period of 3 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      // this.idleState = 'No longer idle.';
      // console.log(this.idleState);
      this.idle.stop();
      // this.reset();
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    });

    this.idle.onTimeout.subscribe(() => {
      this.modalService.dismissAll();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.logout(() => {
        this.idle.stop();
      });
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve session gone expire!';
      // console.log(this.idleState);
      this.modalService.open(this.logoutModel, this.modalOptions);
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Your session will time out in ' + countdown + ' seconds.';
      // console.log(this.idleState);
      if (this.modalService.hasOpenModals) {
        this.idle.clearInterrupts();
        // console.log('clear inter');
      }
    });

    // sets the ping interval to 3 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    if (this.oauthService.getIdentityClaims()) {
      // this.idle.watch();
      // this.timedOut = false;
      this.reset();
    } else {
      this.idle.stop();
    }


    this.reset();

    this.showHideFinancialWelnessPage();

    this.checkPWA();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();

    // this.token = this.messagingService.currentMessage;

    this.activatedRoute.queryParams.subscribe(params => {
      if (params && (params.track || params.Track)) {
        this.notificationClickTrack = params.track || params.Track;
        this.pushNotificationTracking(this.notificationClickTrack);
      }

    });

  }



  showHideFinancialWelnessPage() {
    setTimeout(() => {
      const type = this.cookieService.get('uType');
      if (type === '3' || type === '2') {
        $('#liFinancialWellness').show();
      } else {
        $('#liFinancialWellness').hide();
      }
    }, 500);
  }



  reset() {
    this.idle.watch();
    // xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.modalService.dismissAll();
  }

  stay() {
    this.modalService.dismissAll();
    this.reset();
  }

  logout(callback) {
    this.spinner.show();
    setTimeout(() => {
      if (callback) {
        callback();
      }
      this.modalService.dismissAll();
      (window as any).fcWidget.destroy();
      this.cookieService.delete('submissionId', '/');
      this.cookieService.delete('uType', '/');
      this.cookieService.delete('userId', '/');
      this.cookieService.delete('setDotClass', '/');
      this.cookieService.delete('hasInsurance', '/');
      sessionStorage.removeItem('simplicityData');
      this.cookieService.delete('CreditHistoryId', '/');
      this.oauthService.logOut();
      sessionStorage.clear();
      this.spinner.hide();
    }, 3000);
  }

  private configureAzureAd() {
    this.oauthService.configure(this.env.azureAdConfig.authConfig as AuthConfig);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    this.oauthService.loadDiscoveryDocument(this.env.azureAdConfig.discoveryDocumentConfig.url);
    this.oauthService.setStorage(localStorage);
  }

  checkPWA() {
    window.addEventListener('appinstalled', (eve) => {

      this.cookieService.set('isPwaActive', '1', null, '/', undefined, true);

      this.appInsights.trackEvent({ name: 'PwaLog' }, {
        Type: 'customLog',
        Name: 'pwa',
        Value: 'appinstalled',
        Page: window.location.pathname,
        UserId: this.cookieService.get('userId'),
        SubmissionId: this.cookieService.get('submissionId')
      });
    });

    window.addEventListener('beforeinstallprompt', (eve) => {
      this.cookieService.set('isPwaActive', '0', null, '/', undefined, true);
    });
  }

  pushNotificationTracking(value) {
    this.appInsights.trackEvent({ name: 'NotificationLog' }, {
      Type: 'customLog',
      Name: 'Notification',
      Value: value,
      Page: window.location.pathname,
      UserId: this.cookieService.get('userId'),
      SubmissionId: this.cookieService.get('submissionId')
    });
  }


  createfreshChatForUserType1() {

    this.userData = this.oauthService.getIdentityClaims();
    var date = new Date();
    var hour = date.getUTCHours() + 2;
    var day = date.getDay();
    var minutes = date.getUTCMinutes();
    console.log("this is hour", hour, day, minutes);
    console.log("time1", (hour >= 7 && hour <= 18) && (day > 0 && day < 6));
    if (day == 1 || day == 2 || day == 3 || day == 4) {

      if (hour == 8 && minutes >= 30) {
        this.createChat();
      }
      if (hour >= 8 && hour <= 16) {
        this.createChat();
      }
    }
    if (day == 5) {
      if (hour == 8 && minutes >= 30) {
        this.createChat();
      }
      if (hour >= 8 && hour <= 15) {
        this.createChat();
      }
    }

  }


  createChat() {
    if ((window as any).fcWidget) {
      console.log("window service__2", document.referrer)
      if (this.cookieService.get('chatEnabledForUserType1') === "true") {
        (window as any).fcWidget.init({
          token: this.env.freshChatTokenForUserType1,
          host: this.env.freshChatHost,
          tags: ["DBclient"],
          externalId: this.userData.name,     // user’s id unique to your system
          firstName: this.userData.given_name,              // user’s first name
          lastName: this.userData.family_name
        });
      }
      else {
        setTimeout(() => {
          console.log("calling set timeout");
          this.cookieService.set('chatEnabledForUserType1', "true", null, '/', undefined, true);
          (window as any).fcWidget.init({
            token: this.env.freshChatTokenForUserType1,
            host: this.env.freshChatHost,
            tags: ["DBclient"],
            externalId: this.userData.name,     // user’s id unique to your system
            firstName: this.userData.given_name,              // user’s first name
            lastName: this.userData.family_name
          });
        }, 10);
      }
    }
  }


  checkTypeForFreshChat() {
    const uType = this.cookieService.get('uType');
    if (uType === '1') {
      this.createfreshChatForUserType1();
    }
  }

}
