import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AccountService {

  env = environment;
  constructor(private http: HttpClient) { }

  accountSummary() {
    return this.http.post(this.env.webApiUrl + 'api/Account/AccountSummary', null);
  }

  otherAccounts() {
    return this.http.post(this.env.webApiUrl + 'api/Account/OtherAccounts', null);
  }

}
