import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class RegistrationService {

  env = environment;

  constructor(private http: HttpClient) { }
  registrationDetails(data: any){
    return this.http.post(this.env.webApiUrl + 'api/Registration/RegistrationDetails', data);
  }

}