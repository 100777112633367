<section class="faq b5containeradjust">
    <div class="container ">
        <div class="darkbg mt-md-5 px-md-5 px-3 pt-4 mb-md-5">
            <div class="faqcaption">
                <h2 class="text-center faq-caption">Frequently Asked Questions</h2>
                <p class="text-center faq-capptext mt-4 pb-1">What can we help you with?</p>
            </div>

            
            <div class="faq-content">
                    <div id="accordion">
                        <!---Insurance information-->
                        <div class="card">
                        <div class="card-header" id="heading-1">
                            <h5 class="mb-0">
                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                                Insurance information
                            </a>
                            </h5>
                        </div>
                        <div id="collapse-1" class="collapse subaccordian darkbg" data-parent="#accordion" aria-labelledby="heading-1">
                            <div id="accordion-1">
                                <div class="py-4 darkbg pxt text-center">
                                    and more specifically...
                                </div>
                                <div class="card">
                                <div class="card-header" id="heading-1-1">
                                    <h5 class="mb-0">
                                    <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-1-1" aria-expanded="false" aria-controls="collapse-1-1">
                                        What is credit life insurance?
                                    </a>
                                    </h5>
                                </div>
                                <div id="collapse-1-1" class="collapse" data-bs-parent="#accordion-1" aria-labelledby="heading-1-1">
                                    <div class="card-body">
                    
                                        <p class="mb-0">
                                            Credit life insurance is a form of insurance that covers your monthly debt repayments (for a specific period) or settles your outstanding balances in the event of disability, retrenchment, or death.
                                            <br/>
                                            <br/>
                                            These agreements are signed with the original loan/credit agreements and are often compulsory to have if you accept the terms of credit. In many cases, DebtBusters will replace your original credit life insurance with a more affordable option which has all the same benefits of your original policy. This would have been discussed with you at the negotiations phase of the debt review process.
                                        </p>
                    
                                    </div>
                                </div>
                                </div>
                                <div class="card">
                                <div class="card-header" id="heading-1-2">
                                    <h5 class="mb-0">
                                    <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-1-2" aria-expanded="false" aria-controls="collapse-1-2">
                                        Why do I need credit life insurance?
                                    </a>
                                    </h5>
                                </div>
                                <div id="collapse-1-2" class="collapse" data-bs-parent="#accordion-1" aria-labelledby="heading-1-2">
                                    <div class="card-body">
                                        <p class="mb-0">
                                            Most of your short-term debt should have a credit life insurance agreement attached to it.
                                            <br/><br/>
                                            As we all know, life happens and in these tough economic times, if you are unable to pay your monthly Debt Counselling payment due to retrenchment, disability or even death, then your Credit Life Insurance will cover you. In cases of retrenchment or disability your Credit Life Insurance will cover your monthly Debt Counselling payment. In the case of death, it will settle any outstanding amounts you owe.
                                        </p>
                                    </div>
                                </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-1-2">
                                    <h5 class="mb-0">
                                        <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-1-3" aria-expanded="false" aria-controls="collapse-1-3">
                                            How are insurance payments made?
                                        </a>
                                    </h5>
                                    </div>
                                    <div id="collapse-1-3" class="collapse" data-bs-parent="#accordion-1" aria-labelledby="heading-1-3">
                                    <div class="card-body">
                                        <p class="mb-0">
                                            These premiums form part of your monthly debt review payments. After you make your monthly payment, the necessary money will be allocated to the respective insurance provider.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-1-4">
                                    <h5 class="mb-0">
                                        <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-1-4" aria-expanded="false" aria-controls="collapse-1-4">
                                            How do I log a claim?
                                        </a>
                                    </h5>
                                    </div>
                                    <div id="collapse-1-4" class="collapse" data-bs-parent="#accordion-1" aria-labelledby="heading-1-4">
                                    <div class="card-body">
                                        <p class="mb-0">
                                            Please refer to the claims section in your policy document. Claims will be assessed on a case-by-case basis. Please get in touch with us to find out if you could qualify for a claim assessment. Our claims team will then send you a claim form with all the details we need to assess your claim.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                                
                            </div> 
                        </div>
                        </div>
                        <!--Payments and debit orders-->
                        <div class="card">
                        <div class="card-header" id="heading-2">
                            <h5 class="mb-0">
                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                Payments and debit orders
                            </a>
                            </h5>
                        </div>
                        <div id="collapse-2" class="collapse subaccordian darkbg" data-bs-parent="#accordion" aria-labelledby="heading-2">
                            <div id="accordion-2">
                                <div class="py-4 darkbg pxt text-center">
                                    and more specifically...
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-2-1">
                                        <h5 class="mb-0">
                                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-2-1" aria-expanded="false" aria-controls="collapse-2-1">
                                                How do I change my banking details?
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-2-1" class="collapse" data-bs-parent="#accordion-2" aria-labelledby="heading-2-1">
                                        <div class="card-body">
                                            <p class="mb-0">
                                                Should you want us to debit your monthly debt review payment from a different account, you can simply email us the new banking details and the date you wish this bank account to be debited. 
                                                <br/>
                                                <strong>Note:</strong> It is strongly advised that this date be the same as the date your salary is paid to you by your employer.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-2-2">
                                        <h5 class="mb-0">
                                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-2-2" aria-expanded="false" aria-controls="collapse-2-1">
                                                Can I change my debit order date?
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-2-2" class="collapse" data-parent="#accordion-2" aria-labelledby="heading-2-2">
                                        <div class="card-body">
                                            <p class="mb-0">
                                                Yes, if the new date falls in the same month. 
                                                <br/>
                                                <strong>Note:</strong> It is strongly advised that this date be the same as the date your salary is paid to you by your employer.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-2-3">
                                        <h5 class="mb-0">
                                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-2-3" aria-expanded="false" aria-controls="collapse-2-3">
                                                Can I reduce my monthly payment?
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-2-3" class="collapse" data-parent="#accordion-2" aria-labelledby="heading-2-3">
                                        <div class="card-body">
                                            <p class="mb-0">
                                                Unfortunately, not. If a certain credit provider does not receive the agreed upon restructured amount, they might terminate that account from debt review. However, in some instances, should your circumstances have changed - for example, if you have lost your job - you may apply for “change of circumstance” payment relief from your credit providers. There are very specific criteria around the granting of this relief. You are welcome to contact us for more information in this regard should you need to.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="card">
                                    <div class="card-header" id="heading-2-5">
                                        <h5 class="mb-0">
                                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-2-5" aria-expanded="false" aria-controls="collapse-2-5">
                                                Why do I have to pay a monthly service fee to DebtBusters?
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-2-5" class="collapse" data-parent="#accordion-2" aria-labelledby="heading-2-5">
                                        <div class="card-body">
                                            <p class="mb-0">
                                                DebtBusters provides administration and value-add services to you and liaises with credit providers on your behalf throughout your Debt Counselling journey.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-2-6">
                                        <h5 class="mb-0">
                                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-2-6" aria-expanded="false" aria-controls="collapse-2-6">
                                                Why would credit providers still debit my account?
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-2-6" class="collapse" data-parent="#accordion-2" aria-labelledby="heading-2-6">
                                        <div class="card-body">
                                            <p class="mb-0">
                                                When you sign up for Debt Counselling, DebtBusters will send all your credit providers an instruction to cancel their existing debit order with you. In some instances, credit providers do not comply with these instructions. Should a credit provider debit your account, please let us know immediately. In addition to this, should you receive calls from any of your credit providers claiming that you still need to make payments to them, please ask them to direct their queries to DebtBusters.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <!--Balances and statements-->
                        <div class="card">
                        <div class="card-header" id="heading-3">
                            <h5 class="mb-0">
                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                Balances and statements
                            </a>
                            </h5>
                        </div>
                        <div id="collapse-3" class="collapse subaccordian darkbg" data-parent="#accordion" aria-labelledby="heading-3">
                            <div id="accordion-3">
                                <div class="py-4 darkbg pxt text-center">
                                    and more specifically...
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-3-1">
                                        <h5 class="mb-0">
                                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-3-1" aria-expanded="false" aria-controls="collapse-3-1">
                                                What is my balance? 
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-3-1" class="collapse" data-parent="#accordion-3" aria-labelledby="heading-3-1">
                                        <div class="card-body">
                                            <p class="mb-0">
                                                You can check your balance on the dashboard page on this platform.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-3-2">
                                        <h5 class="mb-0">
                                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-3-2" aria-expanded="false" aria-controls="collapse-3-2">
                                                Why is my Hyphen statement balance different to my credit provider balance (or vice versa)? 
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-3-2" class="collapse" data-parent="#accordion-3" aria-labelledby="heading-3-2">
                                        <div class="card-body">
                                            <p class="mb-0">
                                                There are instances where credit providers do not update their interest rates or are still charging account fees. We continue to dispute this on your behalf.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-3-3">
                                        <h5 class="mb-0">
                                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-3-3" aria-expanded="false" aria-controls="collapse-3-3">
                                                Why is my Hyphen statement balance different to my credit bureau balance?
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-3-3" class="collapse" data-parent="#accordion-3" aria-labelledby="heading-3-3">
                                        <div class="card-body">
                                            <p class="mb-0">
                                                Credit bureaus display information provided to them by credit providers. These changes reflect at different times of the month. So, the timing of the updates between Hyphen and the credit bureaus might differ. The best option would be to look at your Hyphen statement.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-3-4">
                                        <h5 class="mb-0">
                                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-3-4" aria-expanded="false" aria-controls="collapse-3-4">
                                                Why is my Credit Bureau balance different to my credit provider balance?
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-3-4" class="collapse" data-parent="#accordion-3" aria-labelledby="heading-3-4">
                                        <div class="card-body">
                                            <p class="mb-0">
                                                Credit providers are supposed to send their updated balances and information to credit bureaus monthly. Different credit providers do this at different times of the month. Often, there are delays between the time the data is sent and the time it is uploaded by the credit bureau.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-3-5">
                                        <h5 class="mb-0">
                                            <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-3-5" aria-expanded="false" aria-controls="collapse-3-5">
                                                How do I get a statement?
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-3-5" class="collapse" data-parent="#accordion-3" aria-labelledby="heading-3-5">
                                        <div class="card-body">
                                            <p class="mb-0">
                                                Hyphen should be sending you a monthly statement. If you have not received your statement, please let us know and we can request for them to send you an updated one.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <!--Finish debt review faster-->
                        <div class="card">
                            <div class="card-header" id="heading-4">
                            <h5 class="mb-0">
                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                                    Finish debt review faster
                                </a>
                            </h5>
                            </div>
                            <div id="collapse-4" class="collapse subaccordian darkbg" data-parent="#accordion" aria-labelledby="heading-4">
                                <div id="accordion-4">
                                    <div class="py-4 darkbg pxt text-center">
                                        and more specifically...
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading-4-1">
                                            <h5 class="mb-0">
                                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-4-1" aria-expanded="false" aria-controls="collapse-4-1">
                                                    How do I settle my outstanding balances with a lump sum?
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse-4-1" class="collapse" data-parent="#accordion-4" aria-labelledby="heading-4-1">
                                            <div class="card-body">
                                                <p class="mb-0">
                                                    DebtBusters has a team of experts that can help you settle your debt. We negotiate discounts with your credit providers so you can get the best possible deal. Please contact us to find out more.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading-4-2">
                                            <h5 class="mb-0">
                                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-4-2" aria-expanded="false" aria-controls="collapse-4-2">
                                                    Can I increase my monthly payments?
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse-4-2" class="collapse" data-parent="#accordion-4" aria-labelledby="heading-4-2">
                                            <div class="card-body">
                                                <p class="mb-0">
                                                    Yes. This will allow you to finish your debt review process earlier. You also need to let us know if you are making any additional payments to your credit providers so we can adjust your payment plan.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading-4-3">
                                            <h5 class="mb-0">
                                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-4-3" aria-expanded="false" aria-controls="collapse-4-3">
                                                    Can I exit the debt review process?
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse-4-3" class="collapse" data-parent="#accordion-4" aria-labelledby="heading-4-3">
                                            <div class="card-body">
                                                <p class="mb-0">
                                                    Yes. once all your unsecured debt (including your vehicle) is settled. Should you have a bond, you may exit the process given you have settled the rest of your accounts.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <!--Terminated accounts-->
                        <div class="card">
                            <div class="card-header" id="heading-5">
                            <h5 class="mb-0">
                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                                    Terminated accounts
                                </a>
                            </h5>
                            </div>
                            <div id="collapse-5" class="collapse subaccordian darkbg" data-parent="#accordion" aria-labelledby="heading-5">
                                <div id="accordion-5">
                                    <div class="py-4 darkbg pxt text-center">
                                        and more specifically...
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading-5-1">
                                            <h5 class="mb-0">
                                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-5-1" aria-expanded="false" aria-controls="collapse-5-1">
                                                    If a credit provider terminates my Debt Counselling, is the entire process cancelled?
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse-5-1" class="collapse" data-parent="#accordion-5" aria-labelledby="heading-5-1">
                                            <div class="card-body">
                                                <p class="mb-0">
                                                    No. A credit provider can terminate (cancel) a Debt Counselling payment arrangement on a specific credit agreement (account) if you have defaulted on payment, but this does not mean that your Debt Counselling has been cancelled.
                                                    <br/><br/>
                                                    Missing a payment or short paying can result in any or all your credit providers terminating all the missed or short paid credit agreements under Debt Counselling. Therefore, it is of the utmost importance to keep up with your payments as per your Debt Counselling payment plan.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading-5-2">
                                            <h5 class="mb-0">
                                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-5-2" aria-expanded="false" aria-controls="collapse-5-2">
                                                    What should I do if I receive a termination letter?
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse-5-2" class="collapse" data-parent="#accordion-5" aria-labelledby="heading-5-2">
                                            <div class="card-body">
                                                <p class="mb-0">
                                                    A termination letter is a letter sent by a credit provider to say that they are cancelling the Debt Counselling payment arrangement on an account under Debt Counselling. This letter will refer to Section 86 (10) of the National Credit Act.
                                                    <br/><br/>
                                                    Credit providers must send a termination letter to you, your debt counsellor, and the National Credit Regulator by law. This means we, as your debt counsellor, will have received the same letter.
                                                    <br/><br/>
                                                    Once we receive this letter we will investigate the validity of the letter, contact your credit providers, and keep you abreast of the developments of that investigation.
                                                    <br/><br/>
                                                    If you have been making all your payments according to the payment plan, then resolution will be quick. If, however, you have missed payments or short paid at some stage then it could become necessary to make up for those payments. The Client Service Consultant dealing with your termination letter will let you know which course of action will resolve the matter.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading-5-3">
                                            <h5 class="mb-0">
                                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-5-3" aria-expanded="false" aria-controls="collapse-5-3">
                                                    What happens to a terminated account if it no longer forms part of the debt review process?
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse-5-3" class="collapse" data-parent="#accordion-5" aria-labelledby="heading-5-3">
                                            <div class="card-body">
                                                <p class="mb-0">
                                                    If the credit provider has a valid reason to terminate your account from debt review - for example if you have missed a payment/s - they have the right to revert to the original loan agreement.  
                                                    <br/><br/>
                                                    It is important for you to reach out to that credit provider and make an alternative arrangement to make payment towards that account.
                                                    <br/><br/>
                                                    Remember, that specific account still needs to be settled for you to obtain your clearance certificate as it formed part of the debt restructuring order.
                                
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Clearance certificates-->
                        <div class="card">
                            <div class="card-header" id="heading-6">
                            <h5 class="mb-0">
                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                                    Clearance certificates
                                </a>
                            </h5>
                            </div>
                            <div id="collapse-6" class="collapse subaccordian darkbg" data-parent="#accordion" aria-labelledby="heading-6">
                                <div id="accordion-6">
                                    <div class="py-4 darkbg pxt text-center">
                                        and more specifically...
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading-6-1">
                                            <h5 class="mb-0">
                                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-6-1" aria-expanded="false" aria-controls="collapse-6-1">
                                                    When would I be eligible for a clearance certificate?
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse-6-1" class="collapse" data-parent="#accordion-6" aria-labelledby="heading-6-1">
                                            <div class="card-body">
                                                <p class="mb-0">
                                                    To qualify for a clearance certificate, you need to have settled all your unsecured debt. Once you have made your last debt review payment, our team will start requesting paid up letters from all your credit providers. Once we have collected all these letters, we will process your clearance certificate.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading-6-2">
                                            <h5 class="mb-0">
                                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-6-2" aria-expanded="false" aria-controls="collapse-6-2">
                                                    How long does the clearance process take?
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse-6-2" class="collapse" data-parent="#accordion-6" aria-labelledby="heading-6-2">
                                            <div class="card-body">
                                                <p class="mb-0">
                                                    The clearance process generally takes up to 2 weeks. The reason for this is that certain credit providers have longer turn around times than others. There are also exceptions to this as there may be small balances left on some of your accounts, or there may be credit balances as well as various other circumstances.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="card">
                                        <div class="card-header" id="heading-6-4">
                                            <h5 class="mb-0">
                                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-6-4" aria-expanded="false" aria-controls="collapse-6-4">
                                                    I have been paying accounts on my own, how do I go about getting my clearance certificate?
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse-6-4" class="collapse" data-parent="#accordion-6" aria-labelledby="heading-6-4">
                                            <div class="card-body">
                                                <p class="mb-0">
                                                    You need to make sure that you have a list of all your accounts that were originally included under debt review. Once all these unsecured accounts have been settled, you need to send us all the paid-up letters for us to process your clearance certificate.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading-6-5">
                                            <h5 class="mb-0">
                                                <a class="collapsed" role="button" data-bs-toggle="collapse" href="#collapse-6-5" aria-expanded="false" aria-controls="collapse-6-5">
                                                    When would my name be cleared with the credit bureaus?
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapse-6-5" class="collapse" data-parent="#accordion-6" aria-labelledby="heading-6-5">
                                            <div class="card-body">
                                                <p class="mb-0">
                                                    We generally give credit bureaus a maximum of 21 business days to update their platforms. Each credit bureau has different dates on which they update their system.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
            </div>
            <div class="faqhelpline py-md-5 text-center py-5">
                <div class=" d-flex justify-content-center flex-wrap">
                    <div class="hlpblk px-md-5"><a href="tel:0861365914" class="helplink-phone me-4">086 1365 914 </a></div>
                    <div class="hlpblk px-md-5"><a href="mailto:csu@debtbusters.co.za" class="helplink-email">csu@debtbusters.co.za</a></div>
                </div>
            </div>
        </div>
    </div>
    
</section>
