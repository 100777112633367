import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";
import { RegExValidation } from "../helpers/constants/regex-validation.constants";
import { InputValidationService } from "../helpers/validators/input-validation.service";
import * as $ from 'jquery';
import { HttpErrorResponse } from "@angular/common/http";
import { UserService } from "../services/user.service";
import { CookieService } from "ngx-cookie-service";
import { RegistrationRoutingService } from "../services/registration-routing.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { OtpService } from "../services/otp.service";
import { NgxSpinnerService } from "ngx-spinner";
import { PlatformLocation } from "@angular/common";
import { SecurityQuestionService } from "../services/security-question.service";
import { SharedModule } from "../shared/shared.module";
import { Title } from "@angular/platform-browser";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppInsightsService } from "../services/appinsights.service";
import { RegistrationService } from "../services/registration.service";
import { Subscription, interval, timer } from 'rxjs';

@Component({
  template: ''
})

export class RegisterBaseComponent   implements OnInit {
  
  @ViewChild('otpModel', { static: false }) otpModel: ElementRef;
  @ViewChild('captureIncomeModel', { static: false }) captureIncomeModel: ElementRef;
  @ViewChild('securityQuestionsModel', { static: false }) securityQuestionsModel: ElementRef;


  modalOptions: NgbModalOptions;
  strokeUrl = 'url(' + window.location.href.substring(0, window.location.href.indexOf('#')) + '#Pattern)';
  registerForm; otpForm; captureIncomeForm; captureIncomeModelRef; autoRegisterForm;
  errorMsg = ''; securityQuestionErrorMsg = ''; signupSuccess = false;
  otpErrorMsg = '';
  autoRegErrorMsg = '';
  password = '';
  AutoRegisterPassword = '';
  captureIncomeErrorMsg = '';
  cellNumberErrorMsg = '';
  sendOtpText = 'Send OTP';
  sendEmailOtpText = 'Send Email OTP';
  otpPopupHeaderText = 'Get your One-Time Pin (OTP) via SMS';
  securityQuestionList = [];
  basicInfoVerifyConfirmed: boolean = null;
  securityQuestionAttempt = 1;
  successMsg = '';
  time;
  btnOTPIsDisabled = false;
  usedOtpType;
  isOtpSent = false; otp = '';
  isDbcYes = null;
  modelWindow = 'question';
  cancelQuestion = false;
  maskedEmail = '';
  maskedPhoneNumber = '';
  messageLinkTemplet = `<a id='messagelink' href='routeValue' onclick='return false;'>linkText</a>`;
  WebSource = '';
  creditReportYear; creditReportMonth;
  loginButtonDisable = false;

  startRegistration;
  otpStatus: boolean = null;
  stream2Method: string = null;
  startSecurityQuestions: any = null;
  securityQuestionsStatus: boolean = null;
  manualVerificationStatus: boolean = null; 
  dbcUserType: number = null;
  userId: string = null;
  partitionKey : string = null;
  rowKey : string = null;
  otpRequested: boolean = null;
  otpEntered : number;
  otpCount : number = 0;
  isAutoreg: boolean = null;
  source : string = null;
  appInsights: ApplicationInsights;
  idNumber: string = null;
  env = environment;
  canEnterOtp: boolean = false;
  passwordLen: any; passwordDig: any; passwordLow: any; passwordUp: any; passwordSpecial: any;
  autoRegCreatedDate = null;
  autoRegSource = null;
  otpStartDate = null;
  userCreatedDate = null;

  isOtpScreen = false;
  disableOTPSendButton: boolean = false;
  secondsLeft: number = 0;
  countDown: number = 30;
  hideResendOTPButton: boolean = false;
  optSessionStillValid: boolean = false;
  otpSentSuccesfully: number;
  numbersubs: Subscription;
  sessionTimeOut: Subscription;
  isNumberSubSubscribed: boolean = false;
  isSessionTimeOutSubscribed: boolean = false;
  counter : number;
  tick = 1000;
  successMsgWithPipe: boolean = false;
  otpSessionTimeOutInterval;
  otpSession10MinInterval;
  resendAvailable: boolean = null;
  isOtpSessionExpired: boolean = false;
  sessionTimeoutInSeconds : number = null;

  constructor(
    public userService: UserService,
    public otpService: OtpService,
    public router: Router,
    public formBuilder: FormBuilder,
    public modalService: NgbModal,
    public location: PlatformLocation,
    public spinner: NgxSpinnerService,
    public securityQuestionService: SecurityQuestionService,
    public cookieService: CookieService,
    public oauthService: OAuthService,
    public titleService: Title,
    public activatedRoute: ActivatedRoute,                 
    public registrationRouting: RegistrationRoutingService, 
    public sharedModule: SharedModule,
    private appInsightsService: AppInsightsService,
    public registrationService: RegistrationService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
    location.onPopState(() => this.modalService.dismissAll());
    this.titleService.setTitle('DebtBusters-Client');
    this.appInsights = appInsightsService.appInsightsObj();
    // if (this.env.appInsights.isEnable) {
    //   this.appInsights.loadAppInsights();
    // }

  }

  ngOnInit() {
    localStorage.removeItem('tempToken');
    localStorage.removeItem('OnLoadCheck');
    this.registerForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      Surname: ['', Validators.required],
      PhoneNumber: ['', [Validators.required, Validators.minLength(10), InputValidationService.isValidMobile]],
      Email: ['', [Validators.required, Validators.email, InputValidationService.isValidEmail]],
      IdNumber: ['', [Validators.required, Validators.minLength(13), InputValidationService.isValidIdNumber]],
      Password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(RegExValidation.password)]],
      ConfirmPassword: ['', Validators.required],
      AcceptTerms: [false, Validators.pattern('true')],
      DbcUserType: 2,
      WebSource: '',
      Params: ''
    }, {
      validator: [
        InputValidationService.passwordMustMatch('Password', 'ConfirmPassword'),
      ],
    });

    this.autoRegisterForm = this.formBuilder.group({
      PhoneNumber: ['', [Validators.required, Validators.minLength(10), InputValidationService.isValidMobile]],
      Password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(RegExValidation.password)]],
      ConfirmPassword: ['', Validators.required],
      AcceptTerms: [false, Validators.pattern('true')],
      DbcUserType: 2
    }, {
      validator: [
        InputValidationService.passwordMustMatch('Password', 'ConfirmPassword'),
      ],
    });

    this.otpForm = this.formBuilder.group({
      Otp: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.captureIncomeForm = this.formBuilder.group({
      GrossIncome: ['', [Validators.required, Validators.min(1)]],
    });

    this.bindNavBar();

    this.registerHeight();

    setTimeout(() => {
      if (this.oauthService.getIdentityClaims()) {
        this.oauthService.logOut();
      }
    }, 1000);



    var error = localStorage.getItem('errorMsg');
    if (error != '' || error != null) {
      this.errorMsg = error;
      setTimeout(() => {
        localStorage.removeItem('errorMsg');
      }, 1000);

    }

    this.creditReportYear = new Date().getFullYear();
    this.creditReportMonth = new Date().getMonth() + 1;

    console.log("hiding");
    $('.auto-register').hide();

    this.addBodyClass();
    
    var queryParams = this.registrationRouting.queryStringParams();
    if(queryParams){
      localStorage.setItem('UtmParams', queryParams);
    }

    var isSapiIsUserUserDataAvailable = localStorage.getItem('sapiIsUserUserDataAvailable');
    if(isSapiIsUserUserDataAvailable=='true'){
      this.errorMsg=localStorage.getItem('sapiErrorMessage');
    }
  }



  addBodyClass() {
    const bodyTag = document.body;
    bodyTag.classList.add('register-body');
  }

  hideLogin() {
    $('.login-button').hide();
  }

  showLogin() {
    $('.login-button').show();
  }

  registerHeight() {
    var headerh = $('header').height(),
      footerh = $('footer').height(),
      windowh = $(window).height(),
      desiredHeight = windowh - 110 - headerh;
    $('.min-h').css('min-height', desiredHeight + 'px');

  }
  onResize(event) {
    var headerh = $('header').height(),
      footerh = $('footer').height(),
      windowh = $(window).height(),
      desiredHeight = windowh - footerh - headerh;
    $('.min-h').css('min-height', desiredHeight + 'px');
  }

  bindNavBar() {
    const backDrop = '<div class="backdrop"></div>';
    $('.navbar-toggler').click(function () {
      $('body').addClass('push-menu-body');
      $('.navbar').addClass('push-menu-visible');
      $(backDrop).appendTo('body').on('click', function () {
        $('.navbar').removeClass('push-menu-visible');
        $(this).remove();
      });
    });
    $('.navbar-close').click(function () {
      $('body').removeClass('push-menu-body');
      $('.navbar').removeClass('push-menu-visible');
      $('.backdrop').remove();
    });
    $('.navbar-nav').click(function () {
      $('body').removeClass('push-menu-body');
      $('.navbar').removeClass('push-menu-visible');
      $('.backdrop').remove();
    });
  }
  get registerFormControls() { return this.registerForm.controls; }
  get otpFormControls() { return this.otpForm.controls; }
  get captureIncomeFormControls() { return this.captureIncomeForm.controls; }
  get autoRegisterFormControls() { return this.autoRegisterForm.controls; }

  startTimeOut() {
    window.onload = () => {
      this.resetTimer();
    };

    document.onmousemove = () => {
      this.resetTimer();
    };

    document.onkeypress = () => {
      this.resetTimer();
    };

  }

  resetTimer() {
    if (this.time !== 'stop') {
      console.log('no Idle');
      clearTimeout(this.time);
      // this.time = setTimeout(() => { window.location.reload(); }, 5 * 60 * 1000);
      this.time = setTimeout(() => {
        console.log('timeout');
        this.modalService.dismissAll();
        this.resetRegisterForm();
        if (this.modelWindow === 'otp') {
          this.errorMsg = 'Your session is over. Please click on {REGISTER-LINK}, to start process again.';
        } else {
          this.errorMsg = 'Your session has timed-out and your registration could not be completed. Please click {REGISTER-LINK} to start process again.';
        }
        this.createMessageLink();
        this.securityQuestionVerficationFailed();
        this.isDbcYes = false;
        this.showLogin();
      }, 5 * 60 * 1000);
    }
  }

  stopTimer() {
    clearTimeout(this.time);
    this.time = 'stop';
    console.log('Timer Stop');
    console.log(this.time);
  }

  dbcVerify() {

    sessionStorage.removeItem("AzureTableStorageData");
    this.hideLogin();
    this.errorMsg = '';
    if (this.registerForm.controls.IdNumber.value === '' || (this.registerFormControls.IdNumber.touched
      && this.registerFormControls.IdNumber.errors)) {
      this.showLogin();
      this.registerForm.controls['IdNumber'].markAsTouched();
      return;
    }

    const param = {
      IdNumber: this.registerForm.controls.IdNumber.value
    };

    this.idNumber = this.registerForm.controls.IdNumber.value;
    sessionStorage.setItem("IdMumber", this.idNumber);
    this.userService.getDbcUser(param).subscribe((data: any) => {
      console.log(data);
      this.loginButtonDisable = true;
      if (data != null) {
        
        if (data.is_Lead) {
          $('#divIdNumber').hide();
          $('#divIdNumber').addClass("d-none");
          $('#bgcontrol').removeClass("mobilebgreg");
          $('#bgcontrol').addClass("mobilebgautoreg");
          $(".topcap").addClass("topbanmobsecondstep");
          $(".register-hld").addClass("mobileviewsecondstepbg");
          $(".rightscorepnl").hide();

          $('.dbc-verify-no').hide();
          $('.dbc-verify-yes').hide();
          $('#btn-register').hide();
          $('#btn-partical-register').hide();
          $('.auto-register').show();
          $('#auto-register-button').show();
          this.autoRegCreatedDate = data.leadData ? data.leadData.autoRegCreatedDate : null;
          this.autoRegSource = data.leadData ? data.leadData.autoRegSource: null;
          this.userCreatedDate = data.leadData ? data.leadData.autoRegCreatedDate : null;
          var dbcUserType = 1;
          this.dbcUserType = 1;
          if (data.dbClient !== 'Yes') {
            dbcUserType = 2;
            this.dbcUserType = 2;
          }
          else {
            this.isDbcYes = true;
          }
          this.autoRegisterForm.patchValue({
            DbcUserType: dbcUserType
          });
          console.log("auto registering");
        }
        else if (data.dbClient === 'Yes') {
          var emailCheck = data.client.email;
          if(emailCheck.indexOf(' ') != -1)
          {
            emailCheck = data.client.email.split(' ')[0];
          }
          $('#divIdNumber').hide();
          $('#divIdNumber').addClass("d-none");
          $('#bgcontrol').removeClass("mobilebgreg");
          $('#bgcontrol').addClass("mobilebgautoreg");
          $(".topcap").addClass("topbanmobsecondstep");
          $(".register-hld").addClass("mobileviewsecondstepbg");
          $(".rightscorepnl").hide();

          $('.dbc-verify-yes').show();
          $('#btn-register').show();
          $('#btn-partical-register').hide();
          this.isDbcYes = true;
          this.dbcUserType = 1;
          this.registerForm.patchValue({
            FirstName: data.client.name,
            Surname: data.client.surname,
            PhoneNumber: data.client.phone,
            Email: emailCheck,
            DbcUserType: 1
          });
          if (data.client.name === '' || data.client.surname === '' || data.client.phone === '' || data.client.email === '') {
            this.registerForm.patchValue({
              FirstName: '',
              Surname: '',
              PhoneNumber: '',
              Email: '',
              DbcUserType: 1
            });
            this.dbcVerifyPart2(1);
          }

        } else {
          this.dbcVerifyPart2(2);
        }
      } else {
        this.dbcVerifyPart2(2);
      }
    },
      (err: HttpErrorResponse) => {
        this.showLogin();
        this.errorMsg = err.error;
        this.createMessageLink();
      });

  }

  dbcVerifyPart2(type) {
    $('#divIdNumber').hide();
    $('#divIdNumber').addClass("d-none");
    $('#bgcontrol').removeClass("mobilebgreg");
    $('#bgcontrol').addClass("mobilebgautoreg");
    $(".topcap").addClass("topbanmobsecondstep");
    $(".register-hld").addClass("mobileviewsecondstepbg");
    $(".rightscorepnl").hide();

    $('.dbc-verify-no').show();
    $('.dbc-verify-yes').show();
    $('#btn-register').show();
    $('#btn-partical-register').hide();
    this.isDbcYes = false;
    this.registerForm.patchValue({
      DbcUserType: type
    });
    console.log(type);
    this.dbcUserType = type;
  }


  securityQuestionVerficationFailed() {
    this.showLogin();
    $('#divIdNumber').show();
    $('#divIdNumber').removeClass("d-none");
    $('#bgcontrol').removeClass("mobilebgreg");
    $('#bgcontrol').removeClass("mobilebgautoreg");
    $(".register-hld").removeClass("mobileviewsecondstepbg");
    $(".topcap").removeClass("topbanmobsecondstep");
    $(".rightscorepnl").show();

    $('.dbc-verify-no').hide();
    $('.dbc-verify-yes').hide();
    $('#btn-register').hide();
    $('#btn-partical-register').show();
    $('#frmAutoRegister').hide();
  }

  resetOtpToDefault(){
    
    if(this.otpSession10MinInterval != undefined){
      clearTimeout(this.otpSession10MinInterval);
    }
    if(this.otpSessionTimeOutInterval != undefined){
      clearTimeout(this.otpSessionTimeOutInterval);
    }
    if(this.isSessionTimeOutSubscribed){
      this.sessionTimeOut.unsubscribe();
    } 

    this.successMsgWithPipe = false;
    this.optSessionStillValid = null;
  }

  autoRegister(source?) {
    this.autoRegErrorMsg = '';
    if (this.autoRegisterForm.invalid){
      if(this.autoRegisterFormControls.Password.errors || this.autoRegisterFormControls.ConfirmPassword.errors 
         || this.autoRegisterFormControls.AcceptTerms.errors){
        this.autoRegisterForm.markAllAsTouched();   
        return;
      }
      if(source == 'register'){
        if(this.dbcUserType != 1 && this.autoRegisterFormControls.PhoneNumber.errors){
          this.autoRegisterForm.markAllAsTouched();   
          return;
        }
      }
    }

    var payload = {
      phoneNumber:this.autoRegisterForm.controls.PhoneNumber.value,
      password: this.autoRegisterForm.controls.Password.value,
      confirmpassword: this.autoRegisterForm.controls.ConfirmPassword.value,
      IdNumber: this.registerForm.controls.IdNumber.value.replace(RegExValidation.numeric, ''),
      WebSource: this.WebSource,
      Params: localStorage.getItem('UtmParams'),
      DbcUserType: this.dbcUserType
    }
    console.log("auto register", payload);
    this.isAutoreg = true;
    this.source = (this.WebSource == '') ? null : this.source;

    this.userService.autoRegisterUser(payload).subscribe((data: any) => {
      if (data.status === 200) {
        localStorage.setItem('tempToken', data.token);
        this.resetOtpToDefault();
        if (this.isDbcYes === true) {
          this.basicInfoVerifyConfirmed = true;
          this.dbcUserBasicInfoVerify(()=>{
            this.registrationDetails(()=>{ this.sendOtp(1);});
          });
        } else {
          this.validateUserBasicInfoWithBureau(()=>{
            this.registrationDetails(()=>{ this.sendOtp(1);});
          });
        }

        console.log(data);
        this.maskedPhoneNumber = data.phoneNumber.replace(/^.{6}/g, '******');
        this.maskedEmail = data.email.replace(/^.{1,4}/, m => "*".repeat(m.length));
        console.log(this.maskedEmail);
        this.getCreditHistoryAndDBCEligibility();   
       
        this.startTimeOut();
        //setTimeout(() => localStorage.removeItem('UtmParams'), 300);
      }
      else {
        this.hideLogin();
        this.autoRegErrorMsg = data.message;
      }
    },
      (err: HttpErrorResponse) => {
        this.hideLogin();
        if (err.status === 409) {
          if(err.error[0] == "User already registered." && err.error[1]== "Cell number already exists"){
            this.autoRegErrorMsg  = "Cell number already exists";
          }
          else{
            this.autoRegErrorMsg = err.error.join('<br>');
          }

        } else if (err.status === 410) {
          this.cellNumberErrorMsg = err.error;
        } else {
          this.autoRegErrorMsg = err.error;
        }
      });
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  register() {

    this.startRegistration = Date.now;
    localStorage.removeItem('tempToken');
    this.errorMsg = '';
    this.otpErrorMsg = '';
    this.cellNumberErrorMsg = '';
    this.securityQuestionErrorMsg = '';
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      return;
    }

    this.registerForm.patchValue({
      FirstName: this.registerForm.controls.FirstName.value.replace(RegExValidation.textName, ''),
      Surname: this.registerForm.controls.Surname.value.replace(RegExValidation.textWithSpace, ''),
      PhoneNumber: this.registerForm.controls.PhoneNumber.value.replace(RegExValidation.numeric, ''),
      IdNumber: this.registerForm.controls.IdNumber.value.replace(RegExValidation.numeric, ''),
      WebSource: this.WebSource,
      Params: localStorage.getItem('UtmParams')
    });

    if(localStorage.getItem('UtmParams'))
    {
      this.source = localStorage.getItem('UtmParams').split('websource=')[1];
    }

    // console.log(this.registerForm.value);
    this.userService.register(this.registerForm.value).subscribe((data: any) => {
      if (data.status === 200) {
        localStorage.setItem('tempToken', data.token);
        this.resetOtpToDefault();
        if (this.isDbcYes === true) {
          this.basicInfoVerifyConfirmed = true;
          this.dbcUserBasicInfoVerify(()=>{
            this.registrationDetails(()=>{this.sendOtp(1);});
          });
        } else {
          this.validateUserBasicInfoWithBureau(()=>{
            this.registrationDetails(()=>{this.sendOtp(1);});
          });
        }
        this.maskedPhoneNumber = this.registerFormControls.PhoneNumber.value.replace(/^.{6}/g, '******');
        this.maskedEmail = this.registerFormControls.Email.value.replace(/^.{1,4}/, m => "*".repeat(m.length));
        console.log(this.maskedEmail);
        this.getCreditHistoryAndDBCEligibility();
        //need to change this
       // this.sendOtp(1,0);     
        this.startTimeOut();
        this.userCreatedDate = data.createdDate;
      } else {
        this.hideLogin();
        this.errorMsg = data.message;
      }
    },
      (err: HttpErrorResponse) => {
        this.hideLogin();
        if (err.status === 409) {
          this.errorMsg = err.error.join('<br>');
        } else if (err.status === 410) {
          this.cellNumberErrorMsg = err.error;
        } else {
          this.errorMsg = err.error;
        }
      });
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  
  registrationDetails(callback) {
   let pk = null;
   let rk = null;
   let startRegistrationDate = new Date();
   var azureTableStorageData = sessionStorage.getItem("AzureTableStorageData");

   if(azureTableStorageData){
     let value =  JSON.parse(azureTableStorageData);
     pk = value.partitionKey;
     rk = value.rowKey;
     startRegistrationDate = value.startRegistration;
   }
   
  
    let registrationDetail = { 

      StartRegistration: startRegistrationDate,
      BasicInfoStatus: this.basicInfoVerifyConfirmed,
      OTPStatus: this.otpStatus,
      Stream2Method: this.stream2Method,
      StartSecurityQuestions: this.startSecurityQuestions,
      SecurityQuestionsStatus: this.securityQuestionsStatus,
      ManualVerifStatus: this.manualVerificationStatus,
      DbcUserType: this.dbcUserType,
      UID: this.userId,
      PartitionKey: pk,
      RowKey: rk,
      OTPRequested: this.otpRequested,
      OTPStartDate: this.otpStartDate,
      OTPCount: this.otpCount,
      OTPEntered: this.otpEntered,
      Source: this.source,
      IsAutoreg: this.isAutoreg,
      SecuirityQuestionsSetCompleted: null,
      Params: localStorage.getItem('UtmParams'),
      AutoRegCreatedDate: this.autoRegCreatedDate,
      AutoRegSource: this.autoRegSource,
      userCreatedDate: this.userCreatedDate,
      SessionTimeoutInSeconds: this.sessionTimeoutInSeconds,
      IsOtpSessionExpired: this.isOtpSessionExpired }
  
    sessionStorage.setItem("AzureTableStorageData", JSON.stringify(registrationDetail));

    this.registrationService.registrationDetails(registrationDetail).subscribe((data: any) => {
       if(data){
        this.startRegistration = data.startRegistration,
        this.basicInfoVerifyConfirmed = data.basicInfoStatus,
        this.otpStatus = data.oTPStatus,
        this.stream2Method = data.stream2Method,
        this.startSecurityQuestions = data.StartSecurityQuestions,
        this.securityQuestionsStatus = data.securityQuestionsStatus,
        this.manualVerificationStatus = data.manualVerificStatus,
        this.dbcUserType = data.dbcUserType,
        this.userId = data.uid,
        this.partitionKey = data.partitionKey,
        this.rowKey = data.rowKey,
        this.otpRequested =this.otpRequested==true?this.otpRequested: data.oTPRequested,
        this.otpEntered = data.oTPEntered,
        this.WebSource = data.Source,
        this.isAutoreg = data.isAutoreg,
        this.otpStartDate = data.OtpStartDate,
        this.sessionTimeoutInSeconds = data.SessionTimeoutInSeconds,
        this.isOtpSessionExpired = data.IsOtpSessionExpired

        sessionStorage.setItem("AzureTableStorageData", JSON.stringify(data));
        setTimeout(() => localStorage.removeItem('UtmParams'), 300);
       }

       if(callback){
        callback();
      }
    },
      (err: HttpErrorResponse) => {
        
      });

      
  }

  getCreditHistoryAndDBCEligibility() {
    this.userService.getCreditHistoryAndDBCEligibility().subscribe((data: any) => {
      //console.log(data);
    },
      (err: HttpErrorResponse) => {
      });
  }

  dbcUserBasicInfoVerify(callback) {
    this.userService.dbcUserBasicInfoVerify().subscribe((data: any) => {
      this.basicInfoVerifyConfirmed = true;
      if(callback){
        callback();
      }
    },
      (err: HttpErrorResponse) => {
      });
  }

  resetRegisterForm() {
    this.registerForm.reset();
    this.registerForm.markAsUntouched();
  }

  validateUserBasicInfoWithBureau(callback) {
    this.otpErrorMsg = '';
    this.basicInfoVerifyConfirmed = false;
    this.userService.validateUserBasicInfoWithBureau().subscribe((data: any) => {
      if (data === true) {
        this.basicInfoVerifyConfirmed = true;
      } else {
        this.basicInfoVerifyConfirmed = false;
      }
      console.log(this.basicInfoVerifyConfirmed);
      if(callback){
        callback();
      }
      
    },
      (err: HttpErrorResponse) => {

      });
  }

  openOtpScreen() {
    this.sendOtpText = 'Send OTP';
    this.sendEmailOtpText = 'Send Email OTP';
    this.isOtpSent = true;
    this.successMsg = '';
    this.otpErrorMsg = '';
    this.otpForm.markAsUntouched();
    this.modelWindow = 'otp';
    this.isOtpScreen = true;
  }

  sendOtp(otpType) {
    this.otpErrorMsg = '';
    this.otpForm.reset();
    const param = {
      OtpType: otpType,
    };
      this.otpStartDate =  new Date();
    this.usedOtpType = otpType;
    this.otpService.sendOtps(param).subscribe((data: any) => {
      this.otpPopupHeaderText = 'Enter OTP';
      this.btnOTPIsDisabled = false;
      this.isOtpSent = true;
      this.canEnterOtp=true;
      var registrationData =  JSON.parse(sessionStorage.getItem("AzureTableStorageData"));
      let count = 0;
      if(registrationData != null){
        count = registrationData.otpCount;
        
      }
        this.otpRequested =  true;
        this.otpCount =  1 + count;
        if(this.otpCount === 1){
          this.otpStartDate = new Date();
        }

        this.registrationDetails(null);

        this.openOtpScreen();

      if (data.sendOtpStatusType.status === 200) {
        if (data.sendOtpStatusType.message === "SecondOTPSent") 
        {
           this.hideResendOTPButton = true;
           this.resendAvailable = false;
        } 
        if(data.sendOtpStatusType.message === "Sent")
        {
          this.hideResendOTPButton = false;
          this.resendAvailable = true;
          if(this.otpSession10MinInterval != undefined)
          {
            clearTimeout(this.otpSession10MinInterval);
          }
          if(this.isNumberSubSubscribed)
          {
            this.secondsLeft = 0;
            this.numbersubs.unsubscribe();
          }

          if(this.isNumberSubSubscribed){
            this.numbersubs.unsubscribe();
          }

          this.disableOTPSendButton = true;
          let numbers = interval(1000);
          this.numbersubs = numbers.subscribe(x => {
            this.isNumberSubSubscribed = true;
            this.secondsLeft = this.countDown - x;
            if (this.secondsLeft == 0) {
              this.numbersubs.unsubscribe();
              this.isNumberSubSubscribed = false;
              this.disableOTPSendButton = false;
            }
          });
        }
         this.otpSession10MinInterval = setTimeout(() => {
            this.successMsgWithPipe = false;
            
            if(this.isSessionTimeOutSubscribed){
              this.sessionTimeOut.unsubscribe();
            }
          
            setTimeout(() => {
              this.isOtpSessionExpired = true;
              this.registrationDetails(function() {
                window.location.reload();
              });
            }, 5000);   
            this.otpErrorMsg = 'OTP session has expired, please try again.'
          }, 600000);
        }
        if (data.sendOtpStatusType.message === "SecondOTPSent") {
          this.successMsg = 'New OTP sent successfully';
          this.disableOTPSendButton = true;
          this.hideResendOTPButton = true; 
          setTimeout(() => {
            this.successMsg = '';
          }, 3000);
        }
        this.sendOtpText = 'Resend OTP';
      if(data.sendOtpStatusType.status === 202)
      {
        this.otpErrorMsg = 'OTP already verified, please try later.';
      }
    },
        (err: HttpErrorResponse) => {
          this.openOtpScreen();
          if(err.error.sendOtpStatusType.status===421){
            let registrationSessionData =  JSON.parse(sessionStorage.getItem("AzureTableStorageData"));
            let OtpHitCount = 0;
            if(registrationSessionData != null){
              OtpHitCount = registrationSessionData.otpCount;
            }
            this.otpCount =  1 + OtpHitCount;
            if(err.error.sendOtpStatusType.sessionTimeOut > 0){
              this.sessionTimeoutInSeconds = err.error.sendOtpStatusType.sessionTimeOut;
              this.registrationDetails(null);
              this.sendOtpText = "Resend OTP";
              this.isOtpSent = true; 
              if(this.resendAvailable)
              {
                this.sendOtpText = 'Resend OTP';
                this.hideResendOTPButton = false;
              }
              else
              {
                this.hideResendOTPButton = true;
                this.optSessionStillValid = true;

                this.successMsg = "An OTP has been sent to your cellphone number."
              
                this.otpSessionTimeOutInterval =  setTimeout(() => {
                this.counter = err.error.sendOtpStatusType.sessionTimeOut;
                this.sessionTimeOut = timer(0, this.tick).
                 subscribe(() => {
                   --this.counter;
                   this.isSessionTimeOutSubscribed = true;
                   if(this.counter === 0){
                     this.sessionTimeOut.unsubscribe();
                     this.isSessionTimeOutSubscribed = false;  
                     this.isOtpSessionExpired = true;
                     this.registrationDetails(function() {
                        window.location.reload();
                     });              
                   }
                 });
                 this.successMsgWithPipe = true;
                 this.successMsg = '';
               }, 3000);
              }  
            }
            else{
              this.registrationDetails(null);
              this.otpErrorMsg = "OTP Already Sent. Please try again later";
             }
          } 
          else if (err.error.sendOtpStatusType.status === 429) {
            this.otpErrorMsg = 'OTP failed, please try again.';
          } 
          else{
            this.otpErrorMsg = 'Please contact admin';  
          }
        });
  
  }

  getOtpElement(index) {
    return <HTMLInputElement>document.getElementById('otp' + index);
  }
  onKeyUpEvent(event, index) {
    const eventCode = event.which || event.keyCode;
    if (event.target.value.length === 1) {
     if (index !== 6) {
      this.getOtpElement(index+ 1).focus();
     } else {
      this.getOtpElement(index).blur();
      // Final otp
      this.otp = this.getOtpElement(1).value + this.getOtpElement(2).value + this.getOtpElement(3).value + this.getOtpElement(4).value + this.getOtpElement(5).value + this.getOtpElement(6).value;
      // console.log(this.otp); 
      this.validateOtp();
     }
    } else if(event.target.value.length > 1) {
      this.getOtpElement(index).value = '';
    }
    if (eventCode === 8 && index !== 1) {
     this.getOtpElement(index - 1).focus();
    }
  }
  onFocusEvent(index) {
    for (let item = 1; item < index; item++) {
     const currentElement = this.getOtpElement(item);
     if (!currentElement.value) {
        currentElement.focus();
        break;
     }
    }
  }

  // onOtpChange(otp) {
  //   this.otp = otp;
  //   if (otp.length == 4) { 
  //     this.validateOtp(); 
  //   }
  // }

  validateOtp() {
    if (this.btnOTPIsDisabled) {
      return;
    }
    this.btnOTPIsDisabled = true;
    this.otpErrorMsg = '';
    this.successMsg='';
    this.canEnterOtp=true;
    // if (this.otpForm.invalid) {
    //   this.otpForm.markAllAsTouched();
    //   this.btnOTPIsDisabled = false;
    //   return;
    // }
    const param = {
      Otp: parseInt(this.otp),
      OtpType: this.usedOtpType,
      IsAskSecurityQuestion: environment.askSecurityQuestion
    };
 
    var data =  JSON.parse(sessionStorage.getItem("AzureTableStorageData"));
    let count = data.otpEntered;
    this.otpEntered = count + 1;

    this.otpService.validateOtps(param).subscribe((data: any) => {
      this.btnOTPIsDisabled = false;
      setTimeout(() => {
        this.otpStatus = true;
        this.resetOtpToDefault();

        // now check if verify ok and otp ok no need for open security question
        var data = JSON.parse(sessionStorage.getItem("AzureTableStorageData"));

        if (environment.askSecurityQuestion == true) { // old scenario ask SQ out side auth
          sessionStorage.setItem("AzureTableStorageData",JSON.stringify(data));
          if (this.basicInfoVerifyConfirmed === false) {

            this .registrationDetails(()=>{
              this.openSecurityQuestions();
            });
    
          } else {      
             // {
              //   this.sharedModule.sendAppInsights(true)
              //   {
              //     this.login();
              //   }
              // }
            data.otpStatus = true;

           
            sessionStorage.setItem("AzureTableStorageData",JSON.stringify(data));
             this .registrationDetails(()=>{
                  this.dataLayerCallback(true);
                  this.sharedModule.sendAppInsights(true);
                  this.login();           
             });
          }
        } else { //new scenario is there
            this .registrationDetails(()=>{
              this.dataLayerCallback(true);
              this.sharedModule.sendAppInsights(true);
              this.login();
            });
         }

      }, 500);
    },
      (err: HttpErrorResponse) => {
        this.otpStatus = false;
        this.registrationDetails(null);
        this.btnOTPIsDisabled = false;
        if (err.status === 422) {
          this.canEnterOtp=true;
          this.otpErrorMsg = 'Invalid OTP';
          this.otp = '';
          this.getOtpElement(1).value = '';
          this.getOtpElement(2).value  = '';
          this.getOtpElement(3).value = '';
          this.getOtpElement(4).value = '';
          this.getOtpElement(5).value = '';
          this.getOtpElement(6).value = '';
        }
        else if(err.status === 403){
          this.otpErrorMsg = "OTP session has expired, please try later."
          setTimeout(() => {
            this.isOtpSessionExpired = true;
            this.registrationDetails(function() {
              window.location.reload();
            });
          }, 3000);
        }
        else{
          this.canEnterOtp=true;
          this.otpErrorMsg = "Please contact admin."; 
          this.otp = '';
          this.getOtpElement(1).value = '';
          this.getOtpElement(2).value  = '';
          this.getOtpElement(3).value = '';
          this.getOtpElement(4).value = '';
          this.getOtpElement(5).value = '';
          this.getOtpElement(6).value = ''; 
        }
      });
  }

  dataLayerCallback(callback: boolean) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'New User Register Successfully'
    });

   try{
    this.appInsightsService.appInsights.trackEvent({
      name: 'GAEvent',
      properties: {
        EventName:  "New User Register Successfully",
        Type: "GA",
        IdNumber: this.idNumber,
        ValidatingAfter: "OTP",
        SubmissionId: this.cookieService.get('submissionId'),
      }
    });
   }
   catch(e){

   }

  }

  // sendAppInsights(callback: boolean) {
  //   this.appInsights.trackEvent({
  //     name: 'RegisterInfo',
  //     properties: {
  //       Type: 'RegisterLog',
  //       Name: 'IsAskSecurityQuestionWithSlotB',
  //       Value: this.env.askSecurityQuestion,
  //       Page: window.location.pathname,
  //       UserId: this.cookieService.get('userId'),
  //       SubmissionId: this.cookieService.get('submissionId'),
  //       IsAskSecurityQuestionWithSlotB: this.env.askSecurityQuestion
  //     }
  //   });
  // }

  closeOtpScreen() {
    this.modalService.dismissAll();
    this.otpForm.reset();
    this.sendOtpText = 'Send OTP';
    this.sendEmailOtpText = 'Send Email OTP';
    this.canEnterOtp=false;
    this.isOtpScreen = false;
  }


  openSecurityQuestionsPopup() {
    this.modalService.open(this.securityQuestionsModel, this.modalOptions);
    this.modelWindow = 'question';
    this.getSecurityQuestions();
  }

  getSecurityQuestions() {
    this.stream2Method = 'Security Questions';
    this.otpErrorMsg = '';
    this.startSecurityQuestions = new Date();
    this.stream2Method = "Security Questions";
    this.registrationDetails(null);
    this.securityQuestionService.getSecurityQuestionsPart1().subscribe((data: any) => {
      this.securityQuestionList = data;
    },
      (err: HttpErrorResponse) => {
        console.log(err);
        if (err.status === 501) {
          this.modalService.dismissAll();
          this.resetRegisterForm();
          this.errorMsg = err.error;
          this.securityQuestionsStatus = false;
          this.registrationDetails(null);
          // $('#btn-register').hide();
          // $('#btn-partical-register').show();
          this.securityQuestionVerficationFailed();
          // this.securityQuestionErrorMsg = err.error;
          // $('#question-submit').hide();
          // this.cancelQuestion = true;
        }
      });
  }

  validateQuestions() {
    this.securityQuestionErrorMsg = '';

    const attemptedQuestionsLength = this.securityQuestionList.filter(question => question.securityAnswerId != null).length;

    if (attemptedQuestionsLength != 5) {
      this.securityQuestionErrorMsg = 'Please attempt all the questions';
      return;
    }
   
    const param = { SecurityQuestion: this.securityQuestionList, Attempt: this.securityQuestionAttempt };
    this.securityQuestionService.validateSecurityQuestions(param).subscribe((data: any) => {
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        'event': 'New User Register Successfully'
      });
      this.securityQuestionsStatus = true;
      this.registrationDetails(() => {
        this.login();
      });
  
    },
      (err: HttpErrorResponse) => {
        this.securityQuestionsStatus = false;
        if (err.status === 422) {
          if (this.securityQuestionAttempt === 1) {
            this.securityQuestionAttempt = 2;
            this.getSecurityQuestionsPart2();
          } else {
            this.modalService.dismissAll();
            this.stopTimer();
            this.resetRegisterForm();
            this.securityQuestionVerficationFailed();
            this.errorMsg = err.error;
          }
        } else {
          this.securityQuestionErrorMsg = err.error;
        }
      });
  }

  getSecurityQuestionsPart2() {
    this.securityQuestionErrorMsg = 'Only a few more questions to go';
    const param = { SecurityQuestion: this.securityQuestionList };
    this.securityQuestionService.getSecurityQuestionsPart2(param).subscribe((data: any) => {
      this.securityQuestionList = data;
      const divScrollTop = document.getElementById('scrollTop');
      if (divScrollTop != null) {
        divScrollTop.scrollTop = 0;
      }
    },
      (err: HttpErrorResponse) => {
      });
  }

  login() {
    this.modalService.dismissAll();
    this.stopTimer();
    this.spinner.show();
    this.azureAdlogin('User Registered Successfully');
  }

  openGrossIncomePopup() {
    this.captureIncomeModelRef = this.modalService.open(this.captureIncomeModel, this.modalOptions);
    this.captureIncomeModelRef.result.then((result: any) => {
    }, (reason: any) => {
      if (reason === 'Cross click') {
        this.openDashboardPage();
      }
    });
  }


  updateIncome() {
    this.captureIncomeErrorMsg = '';
    if (this.captureIncomeForm.invalid) {
      this.captureIncomeForm.markAllAsTouched();
      return;
    }

    if (this.toNumber(this.captureIncomeForm.value.GrossIncome) >= 0) {
      this.captureIncomeForm.value.GrossIncome = parseFloat(this.toNumber(this.captureIncomeForm.value.GrossIncome));
    }

    this.userService.updateGrossIncome(this.captureIncomeForm.value).subscribe((data: any) => {
      this.openDashboardPage();
    },
      (err: HttpErrorResponse) => {
        this.captureIncomeErrorMsg = err.error;
      });
  }

  openDashboardPage() {
    this.modalService.dismissAll();
    this.router.navigate(['/portal/dashboard']);
  }

  toNumber(val: any) {
    val = val || '';
    const valArr = val.toString().split('');
    const valFiltered = valArr.filter((x: number) => !isNaN(x));
    const valProcessed = valFiltered.join('').trim();
    return valProcessed;
  }

  changeGrossIncome() {
    if (this.captureIncomeForm.value.GrossIncome.toString().trim() === 'R') {
      this.captureIncomeForm.patchValue({
        GrossIncome: ''
      });
    }
  }

  azureAdlogin(message) {
    localStorage.removeItem('tempToken');
    let submissionId = '';
    if (this.cookieService.get('submissionId')) {
      submissionId = this.cookieService.get('submissionId');
    }

    this.oauthService.initLoginFlow('', {
      submissionId, ccAppUrl: window.location.origin,
      instrumentationKey: environment.appInsights.instrumentationKey, message
    });
  }

  hideSecurityQuestion() {
    this.modalService.dismissAll();
    this.router.navigateByUrl('/register' + localStorage.getItem('UtmParams'));
  }
  createMessageLink() {
    if (this.errorMsg.includes('{LOGIN-LINK}')) {
      this.messageLinkTemplet = this.messageLinkTemplet.replace('linkText', 'LOGIN').replace('routeValue', '/login')
      this.errorMsg = this.errorMsg.replace('{LOGIN-LINK}', this.messageLinkTemplet);
    }
    else if (this.errorMsg.includes('{REGISTER-LINK}')) {
      this.messageLinkTemplet = this.messageLinkTemplet.replace('linkText', 'REGISTER').replace('routeValue', '/register' + localStorage.getItem('UtmParams'));

      this.errorMsg = this.errorMsg.replace('{REGISTER-LINK}', this.messageLinkTemplet);
    }
  }

  openSecurityQuestions() {
    this.modalService.dismissAll();
    this.router.navigateByUrl('/security-question');
  }


  classPwrd: boolean = false;
  pwrdTextType: boolean;
  pwrdToggle(event: any) {
    this.classPwrd = !this.classPwrd;
    this.pwrdTextType = !this.pwrdTextType;
  }
  classConfirmPwrd: boolean = false;
  conpwrdTextType: boolean;
  confirmpwrdToggle(event: any) {
    this.classConfirmPwrd = !this.classConfirmPwrd;
    this.conpwrdTextType = !this.conpwrdTextType;
  }

  popOverOpened(){
    if(this.password != null){
      const variations = {
        digits: /\d/.test(this.password),
        lower: /[a-z]/.test(this.password),
        upper: /[A-Z]/.test(this.password),
        nonWords: /[$@$!%*?& "%'()*+,-./:;<=>[\]^_`{|}~#\\]/.test(this.password),
        length: this.password.length >= 8,
      };

      var popover = document.querySelectorAll("#ngb-popover-0, #ngb-popover-1"); 
          if(popover.length != 0){
            if (variations.length) {
              var passwordLen = document.getElementById('password-length');
              var lengthSp = document.getElementById('password-length-sp');
              passwordLen.classList.add('password-requirement-matched');
              lengthSp.classList.add('tick-mark');
            }
            if (variations.digits) {
              var passwordDig = document.getElementById('password-digit');
              var DigitSp = document.getElementById('password-digit-sp');
              passwordDig.classList.add('password-requirement-matched');
              DigitSp.classList.add('tick-mark');
            }
            if (variations.lower) {
              var passwordLow = document.getElementById('password-lowercase');
              var LowerSp = document.getElementById('password-lowercase-sp');
              passwordLow.classList.add('password-requirement-matched');
              LowerSp.classList.add('tick-mark');
            }
            if (variations.upper) {
              var passwordUp = document.getElementById('password-uppercase');
              var UpperSp = document.getElementById('password-uppercase-sp');
              passwordUp.classList.add('password-requirement-matched');
              UpperSp.classList.add('tick-mark');
            }
            if (variations.nonWords) {
              var passwordSpecial = document.getElementById('password-specialchar');
              var SpecialSp = document.getElementById('password-specialchar-sp');
              passwordSpecial.classList.add('password-requirement-matched');
              SpecialSp.classList.add('tick-mark');
            }
          }
      }
  }

  PassVal(){
    var popover = document.querySelectorAll("#ngb-popover-0, #ngb-popover-1"); 
    if(popover.length != 0){
        if(this.password == ''){
          var passwordDig = document.getElementById('password-digit');
          var DigitSp = document.getElementById('password-digit-sp'); 
          var passwordLow = document.getElementById('password-lowercase');
          var LowerSp = document.getElementById('password-lowercase-sp');
          var passwordUp = document.getElementById('password-uppercase');
          var UpperSp = document.getElementById('password-uppercase-sp');    
          var passwordSpecial = document.getElementById('password-specialchar');
          var SpecialSp = document.getElementById('password-specialchar-sp');
          var passwordLen = document.getElementById('password-length');
          var lengthSp = document.getElementById('password-length-sp');

          passwordLen.classList.remove('password-requirement-matched');
          lengthSp.classList.remove('tick-mark');     
          passwordDig.classList.remove('password-requirement-matched');
          DigitSp.classList.remove('tick-mark');
          passwordLow.classList.remove('password-requirement-matched');
          LowerSp.classList.remove('tick-mark');
          passwordUp.classList.remove('password-requirement-matched');
          UpperSp.classList.remove('tick-mark');
          passwordSpecial.classList.remove('password-requirement-matched');
          SpecialSp.classList.remove('tick-mark');
      }
    }
  }

}
