import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class FaqService {

  env = environment;

  constructor(private http: HttpClient) { }

  getFaqs() {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this.env.webApiUrl + 'api/Faq/GetFaqs', { headers: reqHeader });
  }

}
