import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  previousPayments = null;
  currentPayment = null;
  nextPayment = null;
  missedPayments = null;
  isRedisCacheDown: boolean = false;

  constructor(private appService: AppService) {
    this.appService.redisCacheDown$.subscribe(res => {
      if (res) {
        this.isRedisCacheDown = true
      }
    });
  }

  ngOnInit() {
    this.getSimplicityDetails();
  }

  getSimplicityDetails() {
    var data = sessionStorage.getItem('loadSimplicityDetails');
    if (data != null) {
      this.BindSimplicityDetails(JSON.parse(data));
      return;
    }

    this.appService.fetchSimplicityData((data: any) => {
      this.BindSimplicityDetails(data);
    });
  }

  BindSimplicityDetails(data) {
    if (data) {
      console.log("Data ", data);
      this.previousPayments = data.previousPaymentDetails;
      this.currentPayment = data.currentPaymentDetails;
      this.nextPayment = data.nextPaymentDetails;
      this.missedPayments = data.missedPaymentDetail;
    }
  }


}




