<section class="callmeback-hld">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="callbackForm" (ngSubmit)="callback()">
          <h2>Call me back</h2>
          <div class="callmeback-inn d-flex flex-wrap">
           
              <div *ngIf="errorMsg!=''" class="alert alert-danger" role="alert">
                {{errorMsg}}
              </div>
              <div *ngIf="successMsg!=''" class="alert alert-success" role="alert">
                {{successMsg}}
              </div>
              <div class="col-md-6 px-md-2 px-0 position-relative">
                <input type="text" class="form-control" id="name" placeholder="Full Name" name="name"
                formControlName="FullName" name-field-hyphen-space>
                <div *ngIf="callbackFormControls.FullName.touched && callbackFormControls.FullName.errors"
                class="invalid-feedback">
                <div *ngIf="callbackFormControls.FullName.errors.required">Full name is required</div>
              </div>
            </div>
            <div class="col-md-6 px-md-2 px-0 position-relative">
              <input type="tel" class="form-control" id="number" placeholder="Cell phone number" name="number"
                formControlName="PhoneNumber" numeric-field maxlength="10">
                <div
                *ngIf="callbackFormControls.PhoneNumber.touched && callbackFormControls.PhoneNumber.errors"
                class="invalid-feedback">
                <div *ngIf="callbackFormControls.PhoneNumber.errors?.required">Cell phone number is required</div>
                <div *ngIf="callbackFormControls.PhoneNumber.errors?.minlength">Cell phone number must be 10 digits long
                </div>
                <div *ngIf="callbackFormControls.PhoneNumber.errors?.isValidMobile">Please enter your valid 10 digits cell
                  phone number</div>
              </div>
                
            </div>
            
          </div>
          <button type="submit" class="btn " id="btn-Submit-CallMeBack">Call Me</button>

        </form>
        <a id="anchor-CellNumber-CallMeBack" href="tel:0860726526" class="icon call-icon">0860 726 526</a>
      </div>

    </div>
  </div>
</section>
