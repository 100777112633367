<!-- Header HTML Start -->
<!--<app-header></app-header>-->
<!-- Header HTML End -->
<header>
    <!-- Fixed navbar -->
        <nav class="navbar navbar-expand-md nav-cutom bg-white b5containeradjust">
            <div class="container">
                <a id="anchor-RedirectToDebtbusters-RegisterA" class="navbar-brand p-0" href="https://www.debtbusters.co.za/" target="_blank" ><img src="https://stscsdbcoredev001.blob.core.windows.net/b2c-signin-signout-debtbusters-qa/images/logo@2x.jpg" alt=""></a>
                <a class="navbar-toggler p-0" id="anchor-NavbarToggle-RegisterA">
                
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 512 512" height="25px" viewBox="0 0 512 512" width="25px"><g><path d="m464.883 64.267h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#4C4C4C"/><path d="m464.883 208.867h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#4C4C4C"/><path d="m464.883 353.467h-417.766c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.012-21.137-47.149-47.117-47.149z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#4C4C4C"/></g> </svg>

                </a> 
                <div class=" navbar-collapse">
                    <span class="navbar-close d-block d-block d-md-none">

                        <img src="assets/images/cross.png" alt="" width="22px"/>

                    </span>
                    <div class="ml-auto d-flex  column-reverse">
                        <ul class="navbar-nav justify-content-end custom-nav">
                            <li class="nav-item">
                                <a id="anchor-NavLinkRegister-RegisterA" class="nav-link" href="/register">Register</a>
                            </li>
                            <li class="nav-item login-button">
                                <a id="anchor-NavLinkLogin-RegisterA" class="nav-link" href="javascript:void(0)" (click)="azureAdlogin('')">Login</a>
                            </li>
                        </ul>
                        <!--<div class="button-sp justify-md-content-end flex-wrap align-items-center d-flex">
                            <span class="pr-3 d-none d-md-block">Need Help?</span>
                            <a class="teleph d-none d-md-block" href="tel:086 999 0606">086 999 0606</a>
                        </div>-->
                    </div>
                </div>
            </div>
        </nav>
    </header>






<section (window:resize)="onResize($event)" class=" register-hld min-h register">
<div class="registerholder b5containeradjust">
    <div class="container">       
        <div class="row justify-content-center">
            <div class="col-lg-8 col-xl-6 col-md-12 mobilespce">
                <h1 class="page-caption text-white">Register for your DebtBusters profile</h1>
                <p class="page-text text-white">Your credit profile provides access to a unique view of your financial position. DebtBusters believes in giving South Africans a second chance to build a brighter financial future. We do this by helping clients take back control of their finances.</p>
                
                <div class="frmhold" *ngIf="!signupSuccess">
                    <form [formGroup]="autoRegisterForm" (ngSubmit)="autoRegister()" id="frmAutoRegister">
                        <div class="custom-row row mb-50 required auto-register" style="display: none;">
                            <div class="col">
                                <input id="AutoRegisterPassword" type="password" class="form-control frm-fld" placeholder="Password" formControlName="Password" [(ngModel)]="password" [closeDelay]="1000" [ngbPopover]="popContent" popoverTitle="Password Requirements" triggers="click:blur focus">
                                <label class="d-none"  for="Password">Password</label>
                                <app-password-strength-bar *ngIf="AutoRegisterPassword" [passwordToCheck]="AutoRegisterPassword"
                                    [barLabel]="'Password strength:'" class="psb"></app-password-strength-bar>
                                <div *ngIf="autoRegisterFormControls.Password.touched && autoRegisterFormControls.Password.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="autoRegisterFormControls.Password.errors.required">Password is required</div>
                                </div> 
                            </div>
                            <div class="col">
                                <input id="AutoRegisterConfirmPassword" type="password" class="form-control frm-fld" placeholder="Confirm Password" formControlName="ConfirmPassword">
                                <label class="d-none"  for="ConfirmPassword">Confirm Password</label>
                                <div *ngIf="autoRegisterFormControls.ConfirmPassword.touched && autoRegisterFormControls.ConfirmPassword.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="autoRegisterFormControls.ConfirmPassword.errors.required">Confirm password is
                                        required</div>
                                    <div *ngIf="autoRegisterFormControls.ConfirmPassword.errors.mustMatch">Password must match</div>
                                </div>
                            </div>
                        </div>
                        <div class="custom-row row mb-28 required auto-register" style="display: none;">
                            <div class="col-md-12 d-flex align-items-start mobile-spcing">
                                <!-- Rounded switch -->
                                <label class="switch">
                                    <input id="input-AcceptTerms-RegisterA" type="checkbox"  formControlName="AcceptTerms">
                                    <span class="slider round" data-off="No" data-on="Yes"></span>
                                </label>
                                <span class="text-white pl-2 cond-text"> I have read and agree to <a id="anchor-TermsAndConditions-RegisterA" class="text-white text-underline" href="/terms" target="_blank">Terms and
                                    Conditions</a> and <a id="anchor-RedirectToPolicy-RegisterA" class="text-white text-underline" href="/policy" target="_blank">Privacy Policy</a></span>
                            </div>
                            <div class="col-md-12 termerror">
                                <div *ngIf="autoRegisterFormControls.AcceptTerms.touched && autoRegisterFormControls.AcceptTerms.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="autoRegisterFormControls.AcceptTerms.errors.pattern"> Terms and Conditions is
                                        required</div>
                                </div>
                            </div>
                        </div>
                        <div class="custom-row row">
                                <div class="col mobile-spcing d-flex flex-wrap align-items-center justify-content-between">
                                    <button type="submit" class="btn btn-custom text-uppercase mb-md-0 mb-3" id="auto-register-button" style="display: none;" name="btnRegisterRegisterPage">Register</button>                          
                                </div>
                            </div>
                            <ng-template #popContent> 
                                Be at least 8 characters <br>
                                At least one number<br>
                                At least one lowercase letter <br>
                                At least one uppercase letter<br>
                                At least one special character 
                            </ng-template>
                    </form>
                    <form [formGroup]="registerForm" (ngSubmit)="register()">
                            <div *ngIf="errorMsg!='' && errorMsg!=null" class="alert alert-danger" role="alert" [innerHTML]="errorMsg"></div>
                            <div class="custom-row row mb-28 required dbc-verify-no" style="display: none;">
                                <div class="col">
                                    <input id="FirstName" type="text" class="form-control frm-fld" placeholder="First Name" formControlName="FirstName" name-field-hyphen-space>
                                    <label class="d-none" for="FirstName">First Name</label>
                                    <div *ngIf="registerFormControls.FirstName.touched && registerFormControls.FirstName.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.FirstName.errors.required">First name is required</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <input id="Surname" type="text" class="form-control frm-fld" formControlName="Surname" placeholder="Surname" name-field-with-space>
                                    <label class="d-none"  for="Surname">Surname</label>
                                    <div *ngIf="registerFormControls.Surname.touched && registerFormControls.Surname.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.Surname.errors.required">Surname is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-row row mb-28 required dbc-verify-no" style="display: none;">
                                <div class="col">
                                    <input id="PhoneNumber" type="tel" class="form-control frm-fld" placeholder="Cell Phone Number" formControlName="PhoneNumber" numeric-field maxlength="10" (keyup)="cellNumberErrorMsg=''">
                                    <label class="d-none"  for="PhoneNumber">Cell Phone Number</label>
                                    <div *ngIf="(registerFormControls.PhoneNumber.touched && registerFormControls.PhoneNumber.errors) || cellNumberErrorMsg"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.PhoneNumber.errors?.required">Cell phone number is required
                                        </div>
                                        <div *ngIf="registerFormControls.PhoneNumber.errors?.minlength">Cell phone number must be 10
                                            digits long</div>
                                        <div *ngIf="registerFormControls.PhoneNumber.errors?.isValidMobile">Please enter your valid
                                            10 digits cell
                                            phone number</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <input id="Email" type="text" class="form-control frm-fld" placeholder="Email Address" formControlName="Email">
                                    <label class="d-none"  for="Email">Email Address</label>
                                    <div *ngIf="registerFormControls.Email.touched && registerFormControls.Email.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.Email.errors.required">Email address is required</div>
                                        <div *ngIf="registerFormControls.Email.errors?.isValidEmail">Please enter a valid email
                                            address</div>
                                    </div>
                                </div>
                            </div>

                            <div class="custom-row row mb-28 required" id="divIdNumber">
                                <div class="col">
                                    <input id="IdNumber" type="tel" class="form-control frm-fld" placeholder="ID Number"  formControlName="IdNumber" numeric-field maxlength="13">
                                    <label class="d-none"  for="IdNumber">ID Number</label>
                                    <div *ngIf="registerFormControls.IdNumber.touched && registerFormControls.IdNumber.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.IdNumber.errors.required">ID Number is required</div>
                                        <div *ngIf="registerFormControls.IdNumber.errors.minlength">ID Number must be 13 digits long
                                        </div>
                                        <div *ngIf="registerFormControls.IdNumber.errors.isValidIdNumber">Please enter your valid 13
                                            digits South
                                            African ID Number</div>
                                    </div>
                                </div>
                            </div>

                            <div class="custom-row row mb-50 required dbc-verify-yes" style="display: none;">
                                <div class="col">
                                    <input id="Password" type="password" class="form-control frm-fld" placeholder="Password" formControlName="Password" [(ngModel)]="password" [closeDelay]="1000" [ngbPopover]="popContent" popoverTitle="Password Requirements" triggers="click:blur focus">
                                    <label class="d-none"  for="Password">Password</label>
                                    <app-password-strength-bar *ngIf="password" [passwordToCheck]="password"
                                        [barLabel]="'Password strength:'" class="psb"></app-password-strength-bar>
                                    <div *ngIf="registerFormControls.Password.touched && registerFormControls.Password.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.Password.errors.required">Password is required</div>
                                    </div> 
                                </div>
                                <div class="col">
                                    <input id="ConfirmPassword" type="password" class="form-control frm-fld" placeholder="Confirm Password" formControlName="ConfirmPassword">
                                    <label class="d-none"  for="ConfirmPassword">Confirm Password</label>
                                    <div *ngIf="registerFormControls.ConfirmPassword.touched && registerFormControls.ConfirmPassword.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.ConfirmPassword.errors.required">Confirm password is
                                            required</div>
                                        <div *ngIf="registerFormControls.ConfirmPassword.errors.mustMatch">Password must match</div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-row row mb-28 required dbc-verify-yes" style="display: none;">
                                <div class="col-md-12 d-flex align-items-start mobile-spcing">
                                    <!-- Rounded switch -->
                                    <label class="switch">
                                        <input type="checkbox" id="input-AcceptTerms1-RegisterA"  formControlName="AcceptTerms">
                                        <span class="slider round" data-off="No" data-on="Yes"></span>
                                    </label>
                                    <span class="text-white pl-2 cond-text"> I have read and agree to <a id="anchor-TermsAndConditions1-RegisterA" class="text-white text-underline" href="/terms" target="_blank">Terms and
                                        Conditions</a> and <a id="anchor-RedirectToPolicy1-RegisterA" class="text-white text-underline" href="/policy" target="_blank">Privacy Policy</a></span>
                                </div>
                                <div class="col-md-12 termerror">
                                    <div *ngIf="registerFormControls.AcceptTerms.touched && registerFormControls.AcceptTerms.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="registerFormControls.AcceptTerms.errors.pattern"> Terms and Conditions is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-row row">
                                <div class="col mobile-spcing d-flex flex-wrap align-items-center justify-content-between">
                                    <button type="submit" class="btn btn-custom text-uppercase mb-md-0 mb-3" id="btn-register" style="display: none;" name="btnRegisterRegisterPage">Register</button>
                                    <button type="button" (click)="dbcVerify()" class="btn btn-custom text-uppercase mb-md-0 mb-3" id="btn-partical-register" name="btnRegisterRegisterPage">Register</button>
                                    
                                    <div class="btn-responsive login-button">
                                        <span class="text-white text-md d-none d-md-inline-block pr-1 login-button">Have an account ?</span>
                                        <a id="anchor-Login-RegisterA" href="javascript:void(0)" (click)="azureAdlogin('')" class="btn btn-custom bg-transparent border-white text-uppercase login-button">Login </a> 
                                    </div>
                                    
                                </div>
                            </div>
                            <ng-template #popContent> 
                                Be at least 8 characters <br>
                                At least one number<br>
                                At least one lowercase letter <br>
                                At least one uppercase letter<br>
                                At least one special character 
                            </ng-template>
                            
                        
                    </form>
                </div>

                <h3 class="needhelptext mt-3 mt-md-3 mt-lg-0">Need Help?</h3>
                <a id="anchor=CellNumber-RegisterA" class="callus" href="tel:0869990606">Call us on 0869990606</a>
            </div>
        </div>
        
    </div>  


    <!-- Reg HTML Start -->



    <div class="login-hld" *ngIf="signupSuccess" style="height: 200px;">
        <h2>
            Registration Successful, <a id="anchor-Login1-RegisterA" href="javascript:void(0)" (click)="azureAdlogin('')">click here to login</a>
        </h2>
    </div>
    <!-- REG HTML Start -->

    <ng-template #otpModel let-modal>
        <div class="modal-header">
            <h4 class="modal-title ftsz" id="modal-basic-title">
                {{ otpPopupHeaderText }}
            </h4>
            <button name="btnCloseOTPModelRegisterPage" type="button" class="close" aria-label="Close" id="btn-CloseOTPModel-RegisterA"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form class="otp-form" [formGroup]="otpForm" (ngSubmit)="validateOtp()">
            <div class="modal-body">
                <div *ngIf="otpErrorMsg!=''" class="alert alert-danger" role="alert">
                    {{otpErrorMsg}}
                </div>
                <div *ngIf="successMsg!=''" class="alert alert-success" role="alert">
                    {{successMsg}}
                </div>

                <div class="form-group fullwidth">
                    <div class="form-field non-editable cellnumber">
                        <label for="otp">Your Cell Number</label>
                        <input id="input-MaskedPhoneNumber-RegisterA" type="text" class="form-control" value="{{maskedPhoneNumber}}"
                            readonly="readonly">
                        
                    </div>
                </div>
                <div class="form-group fullwidth">
                    <div class="form-field non-editable cellnumber">
                        <label for="otp">Your Email Address</label>
                        <input type="text" id="input-MaskedEmail-RegisterA" class="form-control" value="{{maskedEmail}}"
                            readonly="readonly">
                        
                    </div>
                </div>

                <div *ngIf="isOtpSent" class="form-group fullwidth">
                    <div class="form-field">
                        <label for="otp">Please Enter OTP</label>
                        <input type="text" id="input-Otp-RegisterA" class="form-control" formControlName="Otp" numeric-field maxlength="4">
                        
                        <div *ngIf="otpFormControls.Otp.touched && otpFormControls.Otp.errors" class="invalid-feedback">
                            <div *ngIf="otpFormControls.Otp.errors.required">OTP is required</div>
                            <div *ngIf="otpFormControls.Otp.errors.minlength">OTP must be 4 digits</div>
                        </div>
                    </div>
                    
                </div>

            </div>
            <div class="modal-footer flex-wrap">
                <div class="arrangebtn">
                <button [name]="'btn'+sendEmailOtpText+'OTPRegisterPage'" type="button" id="btn-SendEmailOtpText-RegisterA"
                    [ngClass]="(sendEmailOtpText=='Resend Email OTP')?'btn-border':'btnresend'"
                    (click)="sendOtp(2)">{{sendEmailOtpText}}</button>
                <button [name]="'btn'+sendOtpText+'OTPRegisterPage'" type="button" id="btn-SendOtpText-RegisterA"
                    [ngClass]="(sendOtpText=='Resend SMS OTP')?'btn-border':'btnresend'"
                    (click)="sendOtp(1)">{{sendOtpText}}</button>
                </div>
                <div class="submitbt">
                    <button name="btnSubmitOTPRegisterPage" id="btn-SubmitOtp-RegisterA" [disabled]="btnOTPIsDisabled" type="submit" class="btns"
                    *ngIf="isOtpSent">Submit</button>
                </div>

                <!--type -->
                <ng-container *ngIf="isDbcYes">
                    <p class="w-100 pt-3">
                        In the event that we need to update the contact details displayed here, please send us an email to  <a id="anchor-MailToContactDetails-RegisterA" href="mailto:contactdetails@debtbusters.co.za">contactdetails@debtbusters.co.za</a>  with your full name and your correct phone number, and we will call you back.
                    </p>
                </ng-container>
            </div>
        </form>
    </ng-template>

    <ng-template #captureIncomeModel let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Enter Your Take Home Salary</h4>
            <button name="btntCloseTakeHomeSalaryModelRegisterPage" type="button" class="close" aria-label="Close" id="btn-CloseTakeHomeSalaryModel-Register"
                (click)="openDashboardPage()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form class="capture-income-form" [formGroup]="captureIncomeForm" (ngSubmit)="updateIncome()">
            <div class="modal-body">
                <div *ngIf="captureIncomeErrorMsg!=''" class="alert alert-danger" role="alert">
                    {{captureIncomeErrorMsg}}
                </div>
                <div class="form-group fullwidth">
                    <div class="form-field  gi-hld">
                        <input class="form-control" type="text" appCurrencyMask placeholder="R 20,000"
                            formControlName="GrossIncome" id="currency-number" (blur)="changeGrossIncome()">
                        <label for="GrossIncome">Take Home Salary</label>
                        <div *ngIf="captureIncomeFormControls.GrossIncome.touched && captureIncomeFormControls.GrossIncome.errors"
                            class="invalid-feedback">
                            <div *ngIf="captureIncomeFormControls.GrossIncome.errors.required">Take Home Salary is
                                required</div>
                            <div *ngIf="captureIncomeFormControls.GrossIncome.errors.min">Invalid Take Home Salary</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button name="btntSkipTakeHomeSalaryRegisterPage" type="button" class="btn-border" id="btn-SkipTakeHomeSalary-Register"
                    (click)="openDashboardPage()">Skip</button>
                <button name="btntSubmitTakeHomeSalaryRegisterPage" type="submit" class=" btn" id="btn-SubmitTakeHomeSalary-Register">Submit</button>
            </div>
        </form>
    </ng-template>


    <ng-template #securityQuestionsModel let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"> Answer the following security questions. </h4>
        </div>
        <div class="modal-body">
            <div *ngIf="securityQuestionErrorMsg!=''" class="alert alert-danger" role="alert">
                {{securityQuestionErrorMsg}}
            </div>
            <div id="scrollTop" class="form-group fullwidth" style="height: 400px;overflow-y: auto;">
                <div class="form-row form-group" *ngFor="let question of securityQuestionList; let i=index">
                    <div class="col-md-12">
                        {{i+1}}: {{question.question}}
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6" *ngFor="let answer of question.securityAnswer; let ii=index">
                        <label><input name="option-{{i}}" [(ngModel)]="question.securityAnswerId" id="input-SecurityQuestionRadiobox-RegisterA"
                                [value]="answer.answerId" type="radio"> {{answer.answer}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button id="question-submit" name="btntSubmitSecurityQuestionRegisterPage" type="submit" class="btn"
                (click)="validateQuestions()">Submit</button>
            <button *ngIf="cancelQuestion" id="btn-CancelSecurityQuestion-RegisterA" type="button" class="btn-border" data-dismiss="modal" (click)="hideSecurityQuestion()">Close</button>
        </div>
    </ng-template>
</div>
</section>
<!-- Banner HTML End -->


<!-- Footer HTML Start -->
<app-footer></app-footer>
<!-- Footer HTML End -->