
<section *ngIf="securityQuestionList && securityQuestionList.length > 0">
    <div class="forgotfrm forgothight d-flex align-items-center b5containeradjust">
        <div class="container">
            <div class="d-flex px-3 align-items-center justify-content-center">
                <div class="col-md-1 px-0 col-1 d-flex justify-content-end">
                    <a *ngIf="questionIndex>0" href="javascript:void(0)" class="nextbtn"
                        id="anchor-PrevButton-SecurityQuestion1"
                        (click)="securityQuestionPrevNext('prev')">
                        <svg height="60px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
                            viewBox="0 0 512 512" width="60px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 " fill="#fff" />
                        </svg>

                    </a>
                </div>
                <div class="col-10 col-lg-8 col-md-8">
                    <h2 class="mb-2 topqn text-center text-white">Question {{questionIndex + 1}} of {{totalQuestion}}</h2>
                    <h4 class="btmqn text-center text-white">{{securityQuestionList[questionIndex].question}}</h4>
                </div>
                <div class="col-md-1 px-0 col-1 d-flex justify-content-start">
                    <a *ngIf="questionIndex<4 && securityQuestionList[questionIndex].securityAnswerId!=null"
                        id="anchor-NextButton-SecurityQuestion1"
                        href="javascript:void(0)" class="nextbtn" (click)="securityQuestionPrevNext('next')">

                        <svg height="60px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
                            viewBox="0 0 512 512" width="60px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <polygon points="160,115.4 180.7,96 352,256 180.7,416 160,396.7 310.5,256 " fill="#fff" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="b5containeradjust">
        <div class="container my-lg-4 my-1 my-md-3 pt-3">
            <div class="col-xl-8 col-md-10 mx-auto">

                <div *ngIf="securityQuestionErrorMsg!=''" class="alert alert-danger" role="alert">
                    {{securityQuestionErrorMsg}}
                </div>
                <div *ngIf="successMsg!=''" class="alert alert-success" role="alert">
                    {{successMsg}}
                </div>

            </div>
            <div class="col-xl-8 col-md-10 mx-md-auto px-0 d-flex flex-wrap align-items-stretch">

                <label class="check-container"
                    *ngFor="let answer of securityQuestionList[questionIndex].securityAnswer; let i=index">
                    <input name="option-{{i}}" type="radio" id="option-{{i}}" [value]="answer.answerId"
                        [(ngModel)]="securityQuestionList[questionIndex].securityAnswerId"
                        (change)="updateQuestionAttemt()" />
                    <span class="checkmark">{{answer.answer}}</span>
                </label>

            </div>
        </div>
    </div>
    <div class="button-sectionouter b5containeradjust">
        <div class="container">
            <div
                class="col-xl-8 button-section py-2 col-md-10 mx-auto d-flex justify-content-center align-items-center">

                <button *ngIf="isbuttonSubmit" type="submit" name="btn_SequrityQuestion_Submit" class="submit" id="btn-Submit-SecurityQuestion2"
                    (click)="validateQuestions()">Submit</button>
                
            </div>
        </div>
    </div>


</section>

<section *ngIf="IsBureauDown">
    <div class="button-sectionouter b5containeradjust">
        <div class="container">
            <div class="col-xl-8 button-section py-2 col-md-10 mx-auto d-flex justify-content-center align-items-center"> 
                <div class="alert alert-danger" role="alert">
                    
                     A technical error has occurred. Please contact us on: 0869990606, for assistance with completing your registration. 
                     
                </div>
     
            </div>
        </div>
    </div>
</section>

<!-- Modal creditHistoryUpdate -->
<ng-template #backButtonError let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Navigation Warning</h4>
    </div>
    <div class="modal-body">
        <div>You have not completed your registration verification. If you leave now, you will not be able to continue
            this journey. You will have to speak to a coach. Are you sure you want to Leave?</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn-border" (click)="stopNavigation()" id="btn-Navigation_No-SecurityQuestion2"
            name="btn_SequrityQuestion_Navigation_No">No</button>
        <button type="button" class="btn btn-primary" (click)="doNavigation()" id="btn-Navigation_Yes-SecurityQuestion2"
            name="btn_SequrityQuestion_Navigation_Yes">Yes</button>
    </div>
</ng-template>