<section class="notofication">
    <!-- <div class="notification-banner">
        <div class="container p-md-0">
            <div class="d-flex row flex-wrap align-items-center">
                <div class="col-md-9 col-8 no-space-right">
                    <h1 class="page-caption text-white m-0">Notification Settings</h1>
                </div>
                <div class="col-md-3 text-end col-4">
                    <i class="notification-banner-icon">
                        <svg viewBox="0 0 32 32" width="100px" height="100px">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: none;
                                        stroke: #aeda75;
                                        stroke-linejoin: round;
                                        stroke-width: 2px;
                                    }
                                </style>
                            </defs>
                            <g data-name="35-Notification" id="_35-Notification">
                                <path class="cls-1"
                                    d="M27,23a2,2,0,0,1,2,2,2,2,0,0,1-2,2H5a2,2,0,0,1-2-2,2,2,0,0,1,2-2Z" />
                                <path class="cls-1" d="M12,27a4,4,0,0,0,8,0" />
                                <path class="cls-1" d="M5,23c0-7,2-7,2-13a9,9,0,0,1,18,0c0,6,2,6,2,13" />
                                <path class="cls-1" d="M21,10a5,5,0,0,0-5-5" />
                                <line class="cls-1" x1="21" x2="21" y1="12" y2="14" />
                                <path class="cls-1" d="M24,1s5,2,5,9" />
                                <path class="cls-1" d="M8,1S3,3,3,10" />
                            </g>
                        </svg>
                    </i>
                </div>
            </div>

        </div>
    </div> -->
    <section class="seetings-hld pb-md-5 pb-0 b5containeradjust">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <h1 class="noti-head">Communication preferences</h1>
                    <!-- <div class="whitebg mt-md-5 mt-3">
                        <ul class="left-nav">
                            
                            <li class="active"><a>Communication Settings</a></li>
                        </ul>
                    </div> -->
                </div>
                <div class="col-md-12 col-12">
                    <form [formGroup]="updateSettingForm">
                        <div class="whitebg  mt-3 pt-4">
                            <!-- <h2 class="noti-head">Communication Preferences</h2> -->

                            <div class="form-group fullwidth">
                                <div *ngIf="successMsg!=''" class="alert alert-success " role="alert">
                                    {{successMsg}}
                                </div>
                            </div>
                            <div class="form-group fullwidth">
                                <div *ngIf="successMsgWarning!=''" class="alert alert-danger " role="alert">
                                    {{successMsgWarning}}
                                </div>
                            </div>
                            <ul class="notification-list">
                                <li class="flex-wrap">
                                    <hgroup>
                                        <h3>Allow communication notifications</h3>
                                    </hgroup>
                                    <div class="form-group">
                                        <label class="tc-hld">
                                            <label class="switch">
                                                <input type="checkbox" (change)="allowNotification()"
                                                    formControlName="allowNotifications" id="allowNotification">
                                                <span class="slider" data-off="Off" data-on="On"></span>
                                            </label>
                                        </label>
                                    </div>
                                    <div class="col-md-12 col-12 d-flex mb-3 mt-4 flex-wrap px-0 justify-content-between align-items-center">
                                        <div class="col-8 col-md-9 ps-0">
                                            <p class="m-0 ntxt">
                                                We will notify you when your new Report is ready
                                            </p>
                                        </div>
                                        <div class="col-4 col-md-3 pe-2">
                                            <div class="form-check">
                                                <input type="checkbox" (change)="changeSetting()"
                                                    formControlName="allowEmail" class="form-check-input"
                                                    id="allowEmail">
                                                <label class="form-check-label" for="allowEmail">Email</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-12 col-12 mb-3 d-flex flex-wrap px-0 justify-content-between align-items-center">
                                        <div class="col-8 col-md-9 ps-0">
                                            <p class="m-0 ntxt">
                                                You can request to speak to a coach
                                            </p>
                                        </div>
                                        <div class="col-4 col-md-3 pe-2">
                                            <div class="form-check">
                                                <input type="checkbox" (change)="changeSetting()"
                                                    formControlName="allowPhone" class="form-check-input"
                                                    id="allowPhone">
                                                <label class="form-check-label" for="allowPhone">Phone</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-12 col-12 mb-3 d-flex flex-wrap px-0 justify-content-between align-items-center">
                                        <div class="col-8 col-md-9 ps-0">
                                            <p class="m-0 ntxt">
                                                Used for OTP and Cellphone verification
                                            </p>
                                        </div>
                                        <div class="col-4 col-md-3 pe-2">
                                            <div class="form-check">
                                                <input type="checkbox" (change)="changeSetting()"
                                                    formControlName="allowSMS" class="form-check-input"
                                                    id="allowSms">
                                                <label class="form-check-label" for="allowSms">SMS</label>
                                            </div>
                                        </div>

                                    </div>

                                </li>
                                <ng-template *ngIf="false">
                                    <li>
                                        <hgroup>
                                            <h3>Changes on Your Report</h3>
                                        </hgroup>
                                        <div class="form-group">
                                            <label class="tc-hld">
                                                <label class="switch">
                                                    <input type="checkbox" (change)="changeSetting()" id="input-notificationForReport-Setting"
                                                        formControlName="notificationForReport">
                                                    <span class="slider" data-off="Off" data-on="On"></span>
                                                </label>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <hgroup>
                                            <h3>Solutions for You</h3>
                                        </hgroup>
                                        <div class="form-group">
                                            <label class="tc-hld">
                                                <label class="switch">
                                                    <input type="checkbox" (change)="changeSetting()" id="input-notificationForSolutions-Setting"
                                                        formControlName="notificationForSolutions">
                                                    <span class="slider" data-off="Off" data-on="On"></span>
                                                </label>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <hgroup>
                                            <h3>News and Features</h3>
                                        </hgroup>
                                        <div class="form-group">
                                            <label class="tc-hld">
                                                <label class="switch">
                                                    <input type="checkbox" (change)="changeSetting()" id="input-notificationForNewsAndFeatures-Setting"
                                                        formControlName="notificationForNewsAndFeatures">
                                                    <span class="slider" data-off="Off" data-on="On"></span>
                                                </label>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <hgroup>
                                            <h3>Coaching and Tips</h3>
                                        </hgroup>
                                        <div class="form-group">
                                            <label class="tc-hld">

                                                <label class="switch">
                                                    <input type="checkbox" (change)="changeSetting()" id="input-notificationForCoachingAndTips-Setting"
                                                        formControlName="notificationForCoachingAndTips">
                                                    <span class="slider" data-off="Off" data-on="On"></span>
                                                </label>
                                            </label>
                                        </div>
                                    </li>
                                </ng-template>

                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</section>



<!-- Contact HTML Start 
<app-contact></app-contact>-->
<!-- Contact HTML End -->