import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Enum } from '../helpers/enums/enum';
import { ProfileService } from '../services/profile.service';
import { NavigationExtras, Router } from '@angular/router';
import { InputValidationService } from '../helpers/validators/input-validation.service';
import { RegExValidation } from '../helpers/constants/regex-validation.constants';
import { Subscription, interval, timer } from 'rxjs';

@Component({
  selector: 'app-cell-number-update',
  templateUrl: './cell-number-update.component.html',
  styleUrls: ['./cell-number-update.component.css']
})
export class CellNumberUpdateComponent implements OnInit {

  otp = '';
  resendMsg: string = null;
  errorMsg: string = null;
  otpErrorMsg: string = null;
  sendOtpText = 'Send OTP';
  successMsg = '';
  phoneNumberForm;
  otpForm;  
  showCellPhoneScreenCapture = true;
  showOtpCaptureScreen = false;
  isPhoneNumberUpdateDisabled = false;
  oldPhoneNumber: string = null;
  lastfourdigit: '';
  secondsLeft;
  countDown: number = 30;
  hideResendOTPButton = false;
  disableOTPSendButton: boolean = false;
  otpSentSuccessfully: boolean = false;
  sendOtpTextButton: string = 'Send OTP';
  heading: string;
  subHeading: string;
  optSessionStillValid: boolean = false;
  otpSentSuccesfully: number;

  numbersubs: Subscription;
  sessionTimeOut: Subscription;
  isNumberSubSubscribed: boolean = false;
  isSessionTimeOutSubscribed: boolean = false;
  otpSessionTimeOutInterval;
  otpSession10MinInterval;

  counter = 10;
  tick = 1000;
  successMsgWithPipe: boolean = false;

  constructor(
    private profileService: ProfileService,
    private formBuilder: FormBuilder,
    private router: Router
    ) { }

  ngOnInit() {
    this.phoneNumberForm = this.formBuilder.group({
      phoneNumber: ['', [Validators.required,Validators.minLength(10), InputValidationService.isValidMobile]],
    });

    this.otpForm = this.formBuilder.group({
      Otp: ['', [Validators.required, Validators.minLength(6)]],
    });
    //this.getIsPhoneNumberUpdateDisabled(); --Disabled by client 
    this.fetchOldPhoneNumber();
  }

  getOtpElement(index) {
    return <HTMLInputElement>document.getElementById('otp' + index);
  }

  onKeyUpEvent(event, index) {
    const eventCode = event.which || event.keyCode;
    if (event.target.value.length === 1) {
      if (index !== 6) {
        this.getOtpElement(index+ 1).focus();
      } 
      else {
        this.getOtpElement(index).blur();
        // Final otp
        this.otp = this.getOtpElement(1).value + this.getOtpElement(2).value + this.getOtpElement(3).value + 
                  this.getOtpElement(4).value + this.getOtpElement(5).value + this.getOtpElement(6).value;
        this.validateOtpToUpdatePhoneNumber();
      }
    } 
    else if(event.target.value.length > 1) {
      this.getOtpElement(index).value = '';
    }
    if (eventCode === 8 && index !== 1) {
     this.getOtpElement(index - 1).focus();
    }
  }
  onFocusEvent(index) {
    for (let item = 1; item < index; item++) {
     const currentElement = this.getOtpElement(item);
     if (!currentElement.value) {
        currentElement.focus();
        break;
     }
    }

  }

  updatePhoneNumberRequest(resend: boolean) {
    console.log("req ", this.phoneNumberForm.value);
    this.successMsg = '';
    this.errorMsg = '';

    if(this.phoneNumberForm.invalid){
      this.phoneNumberForm.markAllAsTouched();
      return;
    }

    let phone = this.phoneNumberForm.controls['phoneNumber'].value.toString();
    this.lastfourdigit = phone.substr(phone.length - 4);

    if (!RegExValidation.cellNumber.test(phone)) {
      this.errorMsg = 'Please enter your valid cellphone number';
      return;
    }

    if(this.oldPhoneNumber ==  phone){
      this.errorMsg = 'New cellphone number cannot be same as old';
      return;
    }

    if(resend){
      this.resendMsg = 'New OTP sent sucessfully';
      this.otp = '';
      this.otpErrorMsg = null;
      const otp1 = document.getElementById('otp1');  
      const otp2 = document.getElementById('otp2'); 
      const otp3 = document.getElementById('otp3'); 
      const otp4 = document.getElementById('otp4');
      const otp5 = document.getElementById('otp5');
      const otp6 = document.getElementById('otp6');
      
      otp1.classList.remove('border-error');
      otp2.classList.remove('border-error');
      otp3.classList.remove('border-error');
      otp4.classList.remove('border-error');
      otp5.classList.remove('border-error');
      otp6.classList.remove('border-error');
      
      (<HTMLInputElement>otp1).value = '';
      (<HTMLInputElement>otp2).value = '';
      (<HTMLInputElement>otp3).value = '';
      (<HTMLInputElement>otp4).value = '';
      (<HTMLInputElement>otp5).value = '';
      (<HTMLInputElement>otp6).value = '';
    }
    this.otpForm.reset();
    this.profileService.updatePhoneNumberRequest(this.phoneNumberForm.value).subscribe((data: any) => {     
      this.showCellPhoneScreenCapture = false;
      this.showOtpCaptureScreen = true;

      if (data.sendOtpStatusType.status === 200) {

        if (data.sendOtpStatusType.message === "SecondOTPSent") 
        {
          this.hideResendOTPButton = true; 
          this.resendMsg = 'New OTP sent sucessfully';
        }
        if(this.sendOtpText === 'Send OTP')
        {
          setTimeout(() => {
            setTimeout(() => {
              window.location.reload();
            }, 8000);     
           
            this.otpErrorMsg = 'OTP session has expired, please try again.'
          }, 600000);

          if(this.otpSession10MinInterval != undefined)
          {
            clearTimeout(this.otpSession10MinInterval);
          }
          if(this.isNumberSubSubscribed)
          {
            this.secondsLeft = 0;
          }
          this.disableOTPSendButton = true;
  
          let numbers = interval(1000);
          this.numbersubs = numbers.subscribe(x => {
            this.isNumberSubSubscribed = true;
            this.secondsLeft = this.countDown - x;
            if (this.secondsLeft == 0) {
              this.isNumberSubSubscribed = false;
              this.disableOTPSendButton = false;
            }
          });
        }

        this.otpSentSuccessfully = true;
       
        if (this.sendOtpText === 'Resend OTP') {
          this.resendMsg = 'New OTP sent sucessfully';
          this.disableOTPSendButton = true;
          this.hideResendOTPButton = true;     
        }

        this.sendOtpText = 'Resend OTP';
      }

      if(data.sendOtpStatusType.status === 202)
      {
        this.otpErrorMsg = 'OTP already verified, please try later.';
      }
    },
      (err: HttpErrorResponse) => {
        
        if (err.status === 409){
          this.errorMsg = 'Phone number is already registered, Please try again with new number';
        }
        else if (err.status === 422 || err.status === 501) {
          this.otpErrorMsg = 'We can not find your contact details. Please call one of our coaches for assistance.';
        }

        if(err.error.sendOtpStatusType.status===421){     
          if(err.error.sendOtpStatusType.sessionTimeOut > 0){
            this.showCellPhoneScreenCapture = false;
            this.showOtpCaptureScreen = true;
            this.resendMsg = "An OTP has been sent to your cellphone number.";        
            this.otpSessionTimeOutInterval = setTimeout(() => {
            this.counter = err.error.sendOtpStatusType.sessionTimeOut;
            this.sessionTimeOut = timer(0, this.tick).
            subscribe(() => {
              --this.counter;
              console.log("C " + this.counter);
              if(this.counter === 0){                  
                this.router.navigate(['portal/profile']);
              }
            });
          
            this.successMsgWithPipe = true;
            }, 2000);

            this.hideResendOTPButton = true;
            this.sendOtpText = "Resend OTP" ;
            this.optSessionStillValid = true;
            this.otpSentSuccessfully = true; 
          }
          else{
            this.otpErrorMsg = "OTP Already Sent. Please try after later";
           }
        } 
        else if (err.error.sendOtpStatusType.status === 429) {
          this.otpErrorMsg = 'OTP failed, please try again.';
        }
        else{
          this.otpErrorMsg = 'Please contact admin';  
        }
      });
  }

  
  resetOtpToDefault(){
    if(this.otpSession10MinInterval != undefined){
      clearTimeout(this.otpSession10MinInterval);
    }
    if(this.otpSessionTimeOutInterval != undefined){
      clearTimeout(this.otpSessionTimeOutInterval);
    }
    if(this.isSessionTimeOutSubscribed){
    } 
    if(this.isNumberSubSubscribed)
    {
    }
    this.successMsgWithPipe = false;
    this.optSessionStillValid = null;
  }

  validateOtpToUpdatePhoneNumber() {
    console.log('Otp update start');
    this.errorMsg = '';
    this.otpErrorMsg = '';
    this.successMsg = '';
    if (this.otp == '' || this.otp.length < 6) {
      this.otpForm.markAllAsTouched();
      return;
    }
    let phoneNumber =  this.phoneNumberForm.controls['phoneNumber'].value;
    const param = {
      otp: parseInt(this.otp),
      otpType: Enum.OtpType.Sms,
      phoneNumber:  phoneNumber.toString()
    };

    console.log('Otp MODEL READY');

    this.profileService.validateOtpToUpdatePhoneNumber(param).subscribe((data: any) => {
      this.successMsg = 'Cellphone Number Updated Successfully';
      console.log("Daata ", data);
      const navigationExtras: NavigationExtras = {state: 
        {example: this.successMsg,
         type: 'success'}
       };
      this.router.navigate(['/portal/profile'], navigationExtras);
    },
      (err: HttpErrorResponse) => { 
        console.log("This ", err );
        if (err.status === 422) {
          this.otpErrorMsg = 'Invalid OTP';
          this.resendMsg = null;
          const otp1 = document.getElementById('otp1');  
          const otp2 = document.getElementById('otp2'); 
          const otp3 = document.getElementById('otp3'); 
          const otp4 = document.getElementById('otp4');
          const otp5 = document.getElementById('otp5');
          const otp6 = document.getElementById('otp6');
          otp1.classList.add('border-error');
          otp2.classList.add('border-error');
          otp3.classList.add('border-error');
          otp4.classList.add('border-error');
          otp5.classList.add('border-error');
          otp6.classList.add('border-error');
          (<HTMLInputElement>otp1).value = '';
          (<HTMLInputElement>otp2).value = '';
          (<HTMLInputElement>otp3).value = '';
          (<HTMLInputElement>otp4).value = '';
          (<HTMLInputElement>otp5).value = '';
          (<HTMLInputElement>otp6).value = '';
          this.otpForm.reset();
          this.otpForm.markAllAsTouched();
        }
        else if(err.status === 403){
          this.otpErrorMsg = "OTP session has expired, please try later."
         setTimeout(() => {
          this.router.navigate(['portal/profile']);
          }, 3000);   
        }else{
          this.otpErrorMsg = 'Please contact admin';
        }
      });
  }

  handleKeyDown(event: any) {
    if (event.keyCode === 13) {
       this.updatePhoneNumberRequest(false);
    }
  }

  fetchOldPhoneNumber() {
    this.profileService.userProfile().subscribe((data: any) => {
     this.oldPhoneNumber = data.phoneNumber.toString();
    });
  }

  onChange(data){
    this.errorMsg= '';
  }
  
}
