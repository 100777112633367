import { Component, OnInit } from '@angular/core';
import { RegisterBaseComponent } from '../../register/register-base.component';

@Component({
  selector: 'app-smartcents',
  templateUrl: './smartcents.component.html',
  styleUrls: ['./smartcents.component.css'],
  host: { 'class': 'd-flex flex-column h-100 register-bg' },
})
export class SmartcentsComponent extends RegisterBaseComponent 
{

}
