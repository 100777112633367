import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable()
export class MoneySaverService {

  env = environment;

  constructor(private http: HttpClient) { }
  
  GetMoneySaverUserStatus() {
    return this.http.post(this.env.webApiUrl + 'api/MoneySaver/GetMoneySaverUserStatus', null);
  }

  GetMoneySaverAuthKey() {
    return this.http.post(this.env.webApiUrl + 'api/MoneySaver/GetMoneySaverAuthKey', null);
  }

}