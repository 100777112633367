
<div class="forgot-newbanner b5containeradjust">
    <div class="container">
        <div class="d-flex row flex-wrap align-items-center">
            <div class="col-md-12 col-12 no-space-right ng-star-inserted">
                <p class="page-caption"> Update your password</p>
                <p class="forgot-subheading">To update your DebtBusters password, provide your new password below.</p>
            </div>
    
        </div>
    </div>
</div>

<section class=" d-flex justify-content-center align-items-center bgfoo pb-md-5">

    <!-- Forgot HTML Start -->
    <div class="col-md-5 col-11 pt-md-4 spcebtn pt-3 px-md-3 px-0">
        <div class="login-hld updatePw">
            
            <form class="otp-form" [formGroup]="updatePasswordForm" (ngSubmit)="updatePassword()">
                <div class="spaceholde">
                    <div>
                        <div *ngIf="errorMsg!=''" class="alert alert-danger space-holder" role="alert"  [innerHTML]="errorMsg"></div>
                        <div *ngIf="successMsg!=''" class="alert alert-success space-holder" role="alert">
                            {{successMsg}}
                        </div>
                    </div>

                    <div> 
                        <div class="form-group required">
                            <div class="form-field  position-relative px-0">
                                <label class="formlabel" for="Password">New password</label>
                                <input id="Password" type="password" class="form-control pwfld" placeholder=""
                                    formControlName="Password" [(ngModel)]="password" [closeDelay]="1000" [type]="pwrdTextType ? 'text' : 'password'" 
                                    [ngbPopover]="PasswordRequirementPopover" popoverTitle="Password Requirements" triggers="click:blur focus" (shown)="popOverOpened()" (ngModelChange)="PassVal()" >
                                <span [ngClass]="classPwrd === true ? 'showPw' : '' " (click)="pwrdToggle($event)" class="eyeIcon" ></span>
                                <app-password-strength-check *ngIf="password" [passwordToCheck]="password" class="psb"></app-password-strength-check>
                                <div *ngIf="updatePasswordFormControls.Password.touched && updatePasswordFormControls.Password.errors"
                                    class="invalid-feedback">
                                    <div class="invalid-feedback" *ngIf="updatePasswordFormControls.Password.errors.required">Password is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group  required">
                            <div class="form-field position-relative px-0 mt-3">
                                <label class="formlabel" for="ConfirmPassword">Confirm new password</label>
                                <input id="ConfirmPassword" type="password" class="form-control pwfld" placeholder="" [type]="conpwrdTextType ? 'text' : 'password'" 
                                    formControlName="ConfirmPassword">
                                    <span [ngClass]="classConfirmPwrd === true ? 'showPw' : '' " (click)="confirmpwrdToggle($event)" class="eyeIcon" ></span>
                                <div *ngIf="updatePasswordFormControls.ConfirmPassword.touched && updatePasswordFormControls.ConfirmPassword.errors"
                                    class="invalid-feedback">
                                    <div class="invalid-feedback" *ngIf="updatePasswordFormControls.ConfirmPassword.errors.required">Confirm password is
                                        required</div>
                                    <div class="invalid-feedback" *ngIf="updatePasswordFormControls.ConfirmPassword.errors.mustMatch">Password must match
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="d-flex flex-wrap justify-content-end pt-md-4 pt-4 px-0">
                            <button name="btnSubmitForgotPassword" type="submit" class="btn btn-submit" id="btn-Submit-UpdatePassword"
                            [disabled]="updatePasswordForm.disabled">Update password</button>
                    </div>
                </div>
                <ng-template #PasswordRequirementPopover> 
                    <div id="password-length"><span  class="password-hint">At least 8 characters </span><span id="password-length-sp"></span></div> 
                    <div id="password-digit"><span  class="password-hint">At least 1 number (1, 2, 3…)</span><span id="password-digit-sp"></span> </div>  
                    <div id="password-lowercase"><span  class="password-hint">At least 1 lowercase letter (a, b, c…)</span><span id="password-lowercase-sp"></span></div>  
                    <div id="password-uppercase"><span  class="password-hint">At least 1 uppercase letter (A, B, C…)</span><span id="password-uppercase-sp"></span></div>  
                    <div id="password-specialchar"><span  class="password-hint">At least 1 special character (!, #, $, %, @...) </span><span id="password-specialchar-sp"></span> </div>   
                </ng-template>
            </form>
        </div>
    </div>

</section>

