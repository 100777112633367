<div class="modal-header">
    <h4 class="modal-title pe-4">DebtBusters Refer & Earn Programme Terms and Conditions</h4>
    
  </div>
  <div class="modal-body popAd insuremcealinks" >
      
      <p>
        The DebtBusters Refer & Earn programme aims to help South Africans struggling with financial pressure and debt. Our debt counselling services provide substantial relief to consumers countrywide. <br/><br/>
        By participating in the DebtBusters Refer & Earn programme, you can earn cash for referring your friends and family to DebtBusters. If they’re found to be overindebted, they can sign up for our debt counselling solution. Once they’ve made their first debt repayment, you (the referrer) and the person you referred (the referee) will receive R1,500 each.<br/><br/>
        Debt counselling is a regulated process governed by the National Credit Regulator (NCR). Registered debt counsellors assist overindebted consumers by creating a manageable payment plan. As part of the process, consumers under debt counselling receive legal protection from asset repossession and protection from harassment from by credit providers. 
      </p>
      <hr/>
      <h4>1. Introduction </h4>
      <p>
        Welcome to the DebtBusters Refer & Earn referral programme (“Programme”). The Programme is owned and operated by DebtMatters (Pty) Ltd, a registered debt review company in South Africa, operating in adherence with the laws of South Africa in accordance with the National Credit Regulator. By accessing and using the Programme, you agree to be bound by the following Terms and Conditions (“Terms”). 
      </p>
      <hr/>
      <h4>2. Eligibility </h4>
      <p>
        To participate in the Programme as a referrer, you must:
      </p>
      <ul>
          <li>Be a legal resident of South Africa.</li>
          <li>Be at least 18 years of age.</li>
          <li>Have a registered bank account and provide proof of residence (FICA).</li>
          <li>Have a registered DebtBusters profile, and </li>
          <li>Be an active DebtBusters client (be up to date on all debt counselling repayments). </li>
      </ul>
      <h4>3. Programme overview</h4>
      <ul>
        <li>Referrers will have access to a referral feature on the DebtBusters platform (<a id="anchor-LinkToDebtbusters-ReferralTermsPopup" href="www.debtbusters-client.co.za/">www.debtbusters-client.co.za/</a>), allowing them to copy and share a unique referral link, or share the link via WhatsApp.</li>
        <li>The referrer will receive a reward of R1,500 when a referee signs up for DebtBusters’ debt counselling services and makes their first debt repayment.</li>
        <li>Referrers will be paid for a maximum of five (5) successful referrals in a calendar month.</li>
        <li>Rewards will be processed manually.</li>
      </ul>
      <p>
          
      </p>
      <h4>4. Successful referral definition</h4>
      <p>
        A successful referral is defined as a referred individual who:
      </p>
      <ul>
        <li>Registers on the DebtBusters platform via the unique referral link provided by the referrer.</li>
        <li>Signs up for debt counselling.</li>
        <li>Makes their first debt repayment.</li>
      </ul>
      <h4>5. Referral rewards</h4>
      <ul>
        <li>The referrer will receive R1,500 for a successful referral. </li>
        <li>The referrer can earn a maximum of R7,500 per calendar month for five (5) referrals.</li>
        <li>If a referrer exceeds the five (5) referrals per month, they will not receive the cash reward from the sixth, or any further, successful referrals.</li>
        <li>Rewards will be paid into the bank account provided when signing up with DebtBusters.</li>
        <li>Referrers married in Community of Property (CoP) will be rewarded individually for every unique, successful referral sent. A spouse of a referrer who is married in Community of Property (CoP) cannot be rewarded for a successful referral if he/she has not sent the referral him/herself. Therefore, spouses married in Community of Property (CoP) shall be treated as individuals for the purposes of the referral programme. </li>
      </ul>
      <h4>6. Referral tracking and qualifying actions</h4>
      <ul>
        <li>
            Each referrer will have a unique referral link/code.
        </li>
        <li>
            The referee must complete the registration and sign-up process via this link/code. Only registrations completed using the referral link will qualify for the earnings.
        </li>
        <li>
            Aggregated status updates will be provided to the referrer without disclosing the referee’s information.
        </li>
      </ul>
      <h4>7. Restrictions and conditions</h4>
      <ul>
        <li>
            Referees must be personal friends, family members, acquaintances, or colleagues of the referrer.
        </li>
        <li>
            The Programme may not be used for commercial purposes or combined with other DebtBusters promotions.
        </li>
        <li>
            Referrers may not use spam or other bothersome referral methods.
        </li>
        <li>
            Referral links may not be published on sites containing adult content, illegal activities, or any other inappropriate material.
        </li>
      </ul>
      <h4>8. Privacy and data protection</h4>
      <ul>
        <li>Personal information collected as part of the Programme will be used in accordance with DebtBusters’ Privacy Policy, available at <a id="anchor-LinkToDebtbustersPolicy-ReferralTermsPopup" href="www.debtbusters.co.za/policy">www.debtbusters.co.za/policy</a>. </li>
        <li>By participating, you allow DebtBusters to use your personal information to verify your identity, fulfil obligations, prevent fraud, and comply with regulatory requirements.</li>
        <li>Your information may be shared with third parties, including payment processing service providers and law enforcement and regulatory authorities, under applicable laws.</li>
      </ul>
      <h4>9. Revocation and amendments</h4>
      <ul>
        <li>
            DebtBusters reserves the right to revoke, suspend, or modify the Programme at any time without notice.
        </li>
        <li>
            These Terms may be updated periodically. Continued participation in the Programme constitutes acceptance of the revised Terms.
        </li>
      </ul>
      <h4>10. General terms</h4>
      <ul>
        <li>
            Participation in the Programme is voluntary.
        </li>
        <li>
            DebtBusters will not be held responsible for any technical issues, delays, or failures in the referral process.
        </li>
        <li>
            By participating in the Programme, you agree to indemnify and hold DebtBusters harmless from any claims or damages arising from your participation.
        </li>
      </ul>
      <h4>11. Contact information</h4>
      <p>
        For any questions or support regarding the Programme, please contact the DebtBusters support team by emailing <a id="anchor-MailToDebtbusters-ReferralTermsPopup" mailto="csu@debtbusters.co.za">csu@debtbusters.co.za</a>, or calling <a id="anchor-TelephoneNumber-ReferralTermsPopup" tel="0869990606">086 999 0606</a>.
      </p>

      
  </div>