import { Directive, ElementRef, HostListener, ɵConsole } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AppInsightsService } from 'src/app/services/appinsights.service';

@Directive({
  selector: 'input,button,textarea,a'
})
export class TrackingDirective {
  env = environment;
  appInsights: ApplicationInsights;
  userId;
  isPwa: string;

  constructor(private el: ElementRef, private cookieService: CookieService, private appInsightsService: AppInsightsService) {

    if (this.cookieService.get('userId') != null && this.cookieService.get('userId') !== '') {
      this.userId = this.cookieService.get('userId');
    }
    this.appInsights = appInsightsService.appInsightsObj();
    console.log('log from tracking directive');
  }

  @HostListener('blur', ['$event']) onBlur(event: any) {
    console.log('blur in tracking directive');
    const initalValue = this.el.nativeElement;
    const field = initalValue.getAttribute('formControlName');

    if (initalValue.localName === 'input') {
      if (field === 'Password' || field === 'ConfirmPassword') {
        console.log(field + ' ' + '*************');
        this.trackEvent(initalValue.localName, field, '*************', this.userId);
      } else {
        console.log(field + ' ' + initalValue.value);
        this.trackEvent(initalValue.localName, field, initalValue.value, this.userId);
      }
    } else if (initalValue.localName === 'textarea') {
      console.log(initalValue.localName + ' ' + initalValue.value + ' ' + field);
      this.trackEvent('input', field, initalValue.value, this.userId);
    }
  }

  @HostListener('click', ['$event']) onClick(event: any) {
    console.log('onclick in tracking directive');
    const initalValue = this.el.nativeElement;
    if (initalValue.localName === 'button') {
      console.log(initalValue.localName + ' ' + initalValue.name + ' ' + initalValue.value);
      if (initalValue.name.includes('AccountPage')) {
        this.trackEvent(initalValue.localName, initalValue.name, 'Accounts Data', this.userId);
      } else if (initalValue.value.length > 0) {
        this.trackEvent(initalValue.localName, initalValue.name, initalValue.value, this.userId);
      } else {
        this.trackEvent(initalValue.localName, initalValue.name, initalValue.innerText, this.userId);
      }
    }
    if (initalValue.localName === 'a') {
      if (initalValue.name === 'YumpuMagazine') {
        this.trackEvent(initalValue.localName, initalValue.name, initalValue.id, this.userId);
      }
      else {

        this.trackEvent(initalValue.localName, initalValue.innerText, initalValue.href, this.userId);
      }

      console.log(initalValue.localName + ' ' + initalValue.innerText);
    }
  }

  @HostListener('change', ['$event']) onChange(event: any) {
    console.log('onchange in tracking directive');
    const initalValue = this.el.nativeElement;
    if (initalValue.type === 'checkbox') {
      const field = initalValue.getAttribute('formControlName');
      const fieldValue = initalValue.checked;
      console.log(field);
      // console.log(fieldValue);
      this.trackEvent('input', initalValue.getAttribute('formControlName'),
        initalValue.checked, this.userId);
    }
  }

  trackEvent(elementType, elementName, elementValue, userId) {
    let isPwa = this.cookieService.get('isPwaActive');
    this.isPwa = isPwa;
    if (userId === "" || userId === undefined) {
      userId = this.cookieService.get('userId');
    }

    if (this.env.appInsights.isEnable) {
      // console.log(this.el);
      this.appInsights.trackEvent({
        name: elementType + 'Field',
        properties: {
          Type: elementType,
          Name: elementName,
          Value: elementValue,
          SubmissionId: this.cookieService.get('submissionId'),
          UserId: userId,
          isPwa: this.isPwa,
          IsAskSecurityQuestionWithSlotB: this.env.askSecurityQuestion
        }
      });


    }
  }

  // sendAppInsights(callback: boolean) {
  //   this.appInsights.trackEvent({
  //     name: 'RegisterInfo',
  //     properties: {
  //       Type: 'RegisterLog',
  //       Name: 'IsAskSecurityQuestionWithSlotB',
  //       Value: this.env.askSecurityQuestion,
  //       Page: window.location.pathname,
  //       UserId: this.cookieService.get('userId'),
  //       SubmissionId: this.cookieService.get('submissionId'),
  //       IsAskSecurityQuestionWithSlotB: this.env.askSecurityQuestion
  //     }
  //   });
  // }

}
