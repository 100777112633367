import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormBuilder } from '@angular/forms';
import { DataService } from '../services/data.service';
import { InputValidationService } from '../helpers/validators/input-validation.service';
import { RegExValidation } from '../helpers/constants/regex-validation.constants';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OtpService } from '../services/otp.service';
import { PlatformLocation } from '@angular/common';
import { SecurityQuestionService } from '../services/security-question.service';
import { CookieService } from 'ngx-cookie-service';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { RegistrationRoutingService } from '../services/registration-routing.service';


@Component({
  selector: 'app-register-a',
  templateUrl: './register-a.component.html',
  styleUrls: ['./register-a.component.css'],
  host: { 'class': 'd-flex flex-column h-100 register-bg' }
})
export class RegisterAComponent implements OnInit {
  @ViewChild('otpModel', { static: false }) otpModel: ElementRef;
  @ViewChild('captureIncomeModel', { static: false }) captureIncomeModel: ElementRef;
  @ViewChild('securityQuestionsModel', { static: false }) securityQuestionsModel: ElementRef;

  modalOptions: NgbModalOptions;
  registerForm; otpForm; captureIncomeForm; captureIncomeModelRef; autoRegisterForm;
  errorMsg = ''; securityQuestionErrorMsg = ''; signupSuccess = false;
  otpErrorMsg = '';
  password = '';
  AutoRegisterPassword = '';
  captureIncomeErrorMsg = '';
  cellNumberErrorMsg = '';
  sendOtpText = 'Send SMS OTP';
  sendEmailOtpText = 'Send Email OTP';
  otpPopupHeaderText = 'Get your One-Time Pin (OTP) via SMS or Email';
  securityQuestionList = [];
  basicInfoVerifyConfirmed: any;
  securityQuestionAttempt = 1;
  successMsg = '';
  time;
  btnOTPIsDisabled = false;
  usedOtpType;
  isOtpSent = false;
  isDbcYes = null;
  modelWindow = 'question';
  cancelQuestion = false;
  maskedEmail = '';
  maskedPhoneNumber = '';
  messageLinkTemplet = `<a id='messagelink' href='routeValue' onclick='return false;'>linkText</a>`;
  WebSource = '';
  constructor(
    private userService: UserService,
    private otpService: OtpService,
    private dataService: DataService,
    public router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private location: PlatformLocation,
    private spinner: NgxSpinnerService,
    private securityQuestionService: SecurityQuestionService,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private registrationRouting: RegistrationRoutingService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
    location.onPopState(() => this.modalService.dismissAll());
    this.titleService.setTitle('DebtBusters-Client');
  }

  
  ngOnInit() {
    localStorage.removeItem('tempToken');
    localStorage.removeItem('OnLoadCheck');
    this.registerForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      Surname: ['', Validators.required],
      PhoneNumber: ['', [Validators.required, Validators.minLength(10), InputValidationService.isValidMobile]],
      Email: ['', [Validators.required, Validators.email, InputValidationService.isValidEmail]],
      IdNumber: ['', [Validators.required, Validators.minLength(13), InputValidationService.isValidIdNumber]],
      Password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(RegExValidation.password)]],
      ConfirmPassword: ['', Validators.required],
      AcceptTerms: [false, Validators.pattern('true')],
      DbcUserType: 2,
      WebSource: '',
      Params: ''
    }, {
      validator: [
        InputValidationService.passwordMustMatch('Password', 'ConfirmPassword'),
      ],
    });

    this.autoRegisterForm = this.formBuilder.group({
      Password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(RegExValidation.password)]],
      ConfirmPassword: ['', Validators.required],
      AcceptTerms: [false, Validators.pattern('true')],
      DbcUserType: 2
    }, {
      validator: [
        InputValidationService.passwordMustMatch('Password', 'ConfirmPassword'),
      ],
    });

    this.otpForm = this.formBuilder.group({
      Otp: ['', [Validators.required, Validators.minLength(4)]],
    });

    this.captureIncomeForm = this.formBuilder.group({
      GrossIncome: ['', [Validators.required, Validators.min(1)]],
    });

    this.bindNavBar();

    this.registerHeight();

    setTimeout(() => {
      if (this.oauthService.getIdentityClaims()) {
        this.oauthService.logOut();
      }
    }, 1000);

    this.activatedRoute.queryParams.subscribe(params => {
      if (params && (params.websource || params.WebSource || params.WEBSOURCE || params.webSource)) {
        this.WebSource = params.websource || params.WebSource || params.WEBSOURCE || params.webSource; 
      }
    });

    var error = localStorage.getItem('errorMsg');
    if (error != '' || error != null) {
      this.errorMsg = error;
      setTimeout(() => {
        localStorage.removeItem('errorMsg');
      }, 1000);

    }

    console.log("hiding");
    $('.auto-register').hide();
  }

  hideLogin() {
    $('.login-button').hide();
  }

  showLogin() {
    $('.login-button').show();
  }

  registerHeight() {
    var headerh = $('header').height(),
      footerh = $('footer').height(),
      windowh = $(window).height(),
      desiredHeight = windowh - 110 - headerh;
    $('.min-h').css('min-height', desiredHeight + 'px');

  }
  onResize(event) {
    var headerh = $('header').height(),
      footerh = $('footer').height(),
      windowh = $(window).height(),
      desiredHeight = windowh - footerh - headerh;
    $('.min-h').css('min-height', desiredHeight + 'px');
  }

  bindNavBar() {
    const backDrop = '<div class="backdrop"></div>';
    $('.navbar-toggler').click(function () {
      $('body').addClass('push-menu-body');
      $('.navbar').addClass('push-menu-visible');
      $(backDrop).appendTo('body').on('click', function () {
        $('.navbar').removeClass('push-menu-visible');
        $(this).remove();
      });
    });
    $('.navbar-close').click(function () {
      $('body').removeClass('push-menu-body');
      $('.navbar').removeClass('push-menu-visible');
      $('.backdrop').remove();
    });
    $('.navbar-nav').click(function () {
      $('body').removeClass('push-menu-body');
      $('.navbar').removeClass('push-menu-visible');
      $('.backdrop').remove();
    });
  }
  get registerFormControls() { return this.registerForm.controls; }
  get otpFormControls() { return this.otpForm.controls; }
  get captureIncomeFormControls() { return this.captureIncomeForm.controls; }
  get autoRegisterFormControls() { return this.autoRegisterForm.controls; }

  startTimeOut() {
    window.onload = () => {
      this.resetTimer();
    };

    document.onmousemove = () => {
      this.resetTimer();
    };

    document.onkeypress = () => {
      this.resetTimer();
    };

  }

  resetTimer() {
    if (this.time !== 'stop') {
      console.log('no Idle');
      clearTimeout(this.time);
      // this.time = setTimeout(() => { window.location.reload(); }, 5 * 60 * 1000);
      this.time = setTimeout(() => {
        console.log('timeout');
        this.modalService.dismissAll();
        this.resetRegisterForm();
        if (this.modelWindow === 'otp') {
          this.errorMsg = 'Your session is over. Please click on {REGISTER-LINK}, to start process again.';
        } else {
          this.errorMsg = 'Your session has timed-out and your registration could not be completed. Please click {REGISTER-LINK} to start process again.';
        }
        this.createMessageLink();
        this.securityQuestionVerficationFailed();
        this.isDbcYes = false;
        this.showLogin();
      }, 5 * 60 * 1000);
    }
  }

  stopTimer() {
    clearTimeout(this.time);
    this.time = 'stop';
    console.log('Timer Stop');
    console.log(this.time);
  }

  dbcVerify() {
    this.hideLogin();
    this.errorMsg = '';
    if (this.registerForm.controls.IdNumber.value === '' || (this.registerFormControls.IdNumber.touched
      && this.registerFormControls.IdNumber.errors)) {
      this.showLogin();
      this.registerForm.controls['IdNumber'].markAsTouched();
      return;
    }

    const param = {
      IdNumber: this.registerForm.controls.IdNumber.value
    };
    this.userService.getDbcUser(param).subscribe((data: any) => {
      console.log(data);
      if (data != null) {
        if (data.is_Lead) {
          $('#divIdNumber').hide();
          $('.dbc-verify-no').hide();
          $('.dbc-verify-yes').hide();
          $('#btn-register').hide();
          $('#btn-partical-register').hide();
          $('.auto-register').show();
          $('#auto-register-button').show();
          var dbcUserType = 1;
          if (data.dbClient !== 'Yes') {
            dbcUserType = 2;
          }
          else {
            this.isDbcYes = true;
          }
          this.autoRegisterForm.patchValue({
            DbcUserType: dbcUserType
          });
          console.log("auto registering");
        }
        else if (data.dbClient === 'Yes') {
          $('#divIdNumber').hide();
          $('.dbc-verify-yes').show();
          $('#btn-register').show();
          $('#btn-partical-register').hide();
          this.isDbcYes = true;
          this.registerForm.patchValue({
            FirstName: data.client.name,
            Surname: data.client.surname,
            PhoneNumber: data.client.phone,
            Email: data.client.email,
            DbcUserType: 1
          });
          if (data.client.name === '' || data.client.surname === '' || data.client.phone === '' || data.client.email === '') {
            this.registerForm.patchValue({
              FirstName: '',
              Surname: '',
              PhoneNumber: '',
              Email: '',
              DbcUserType: 1
            });
            this.dbcVerifyPart2(1);
          }

        } else {
          this.dbcVerifyPart2(2);
        }
      } else {
        this.dbcVerifyPart2(2);
      }
    },
      (err: HttpErrorResponse) => {
        this.showLogin();
        this.errorMsg = err.error;
        this.createMessageLink();
      });

  }

  dbcVerifyPart2(type) {
    $('#divIdNumber').hide();
    $('.dbc-verify-no').show();
    $('.dbc-verify-yes').show();
    $('#btn-register').show();
    $('#btn-partical-register').hide();
    this.isDbcYes = false;
    this.registerForm.patchValue({
      DbcUserType: type
    });
    console.log(type);
  }


  securityQuestionVerficationFailed() {
    this.showLogin();
    $('#divIdNumber').show();
    $('.dbc-verify-no').hide();
    $('.dbc-verify-yes').hide();
    $('#btn-register').hide();
    $('#btn-partical-register').show();
    $('#frmAutoRegister').hide();
  }

  autoRegister() {
    if (this.autoRegisterForm.invalid) {
      this.autoRegisterForm.markAllAsTouched();
      return;
    }
    var payload = {
      password: this.autoRegisterForm.controls.Password.value,
      confirmpassword: this.autoRegisterForm.controls.ConfirmPassword.value,
      IdNumber: this.registerForm.controls.IdNumber.value.replace(RegExValidation.numeric, ''),
      WebSource: this.WebSource,
      Params: this.registrationRouting.queryStringParams()
    }
    console.log("auto register", payload);
    this.userService.autoRegisterUser(payload).subscribe((data: any) => {
      if (data.status === 200) {
        localStorage.setItem('tempToken', data.token);
        if (this.isDbcYes === true) {
          this.basicInfoVerifyConfirmed = true;
          this.dbcUserBasicInfoVerify();
        } else {
          this.validateUserBasicInfoWithBureau();
        }
        console.log(data);
        this.maskedPhoneNumber = data.phoneNumber.replace(/^.{6}/g, '******');
        this.maskedEmail = data.email.replace(/^.{1,4}/, m => "*".repeat(m.length));
        console.log(this.maskedEmail);
        this.openOtpPopup();
        this.startTimeOut();
      }
      else {
        this.hideLogin();
        this.errorMsg = data.message;
      }
    },
      (err: HttpErrorResponse) => {
        this.hideLogin();
        if (err.status === 409) {
          this.errorMsg = err.error.join('<br>');
        } else if (err.status === 410) {
          this.cellNumberErrorMsg = err.error;
        } else {
          this.errorMsg = err.error;
        }
      });
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  register() {
    localStorage.removeItem('tempToken');
    this.errorMsg = '';
    this.otpErrorMsg = '';
    this.cellNumberErrorMsg = '';
    this.securityQuestionErrorMsg = '';
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      return;
    }

    this.registerForm.patchValue({
      FirstName: this.registerForm.controls.FirstName.value.replace(RegExValidation.textName, ''),
      Surname: this.registerForm.controls.Surname.value.replace(RegExValidation.textWithSpace, ''),
      PhoneNumber: this.registerForm.controls.PhoneNumber.value.replace(RegExValidation.numeric, ''),
      IdNumber: this.registerForm.controls.IdNumber.value.replace(RegExValidation.numeric, ''),
      WebSource: this.WebSource,
      Params: this.registrationRouting.queryStringParams()
    });
    // console.log(this.registerForm.value);
    this.userService.register(this.registerForm.value).subscribe((data: any) => {
      if (data.status === 200) {
        localStorage.setItem('tempToken', data.token);
        if (this.isDbcYes === true) {
          this.basicInfoVerifyConfirmed = true;
          this.dbcUserBasicInfoVerify();
        } else {
          this.validateUserBasicInfoWithBureau();
        }
        this.maskedPhoneNumber = this.registerFormControls.PhoneNumber.value.replace(/^.{6}/g, '******');
        this.maskedEmail = this.registerFormControls.Email.value.replace(/^.{1,4}/, m => "*".repeat(m.length));
        console.log(this.maskedEmail);
        this.openOtpPopup();
        this.startTimeOut();
      } else {
        this.hideLogin();
        this.errorMsg = data.message;
      }
    },
      (err: HttpErrorResponse) => {
        this.hideLogin();
        if (err.status === 409) {
          this.errorMsg = err.error.join('<br>');
        } else if (err.status === 410) {
          this.cellNumberErrorMsg = err.error;
        } else {
          this.errorMsg = err.error;
        }
      });
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  dbcUserBasicInfoVerify() {
    this.userService.dbcUserBasicInfoVerify().subscribe((data: any) => {
      this.basicInfoVerifyConfirmed = true;
    },
      (err: HttpErrorResponse) => {
      });
  }

  resetRegisterForm() {
    this.registerForm.reset();
    this.registerForm.markAsUntouched();
  }

  validateUserBasicInfoWithBureau() {
    this.otpErrorMsg = '';
    this.basicInfoVerifyConfirmed = false;
    this.userService.validateUserBasicInfoWithBureau().subscribe((data: any) => {
      if (data === true) {
        this.basicInfoVerifyConfirmed = true;
      } else {
        this.basicInfoVerifyConfirmed = false;
      }
      console.log(this.basicInfoVerifyConfirmed);
    },
      (err: HttpErrorResponse) => {
      });
  }

  openOtpPopup() {
    this.sendOtpText = 'Send SMS OTP';
    this.sendEmailOtpText = 'Send Email OTP';
    this.isOtpSent = false;
    this.successMsg = '';
    this.otpErrorMsg = '';
    this.otpForm.markAsUntouched();
    this.modelWindow = 'otp';
    this.modalService.open(this.otpModel, this.modalOptions);
  }

  sendOtp(otpType) {
    this.otpErrorMsg = '';
    this.otpForm.reset();
    const param = {
      OtpType: otpType,
    };

    this.usedOtpType = otpType;
    this.otpService.sendOtp(param).subscribe((data: any) => {
      this.otpPopupHeaderText = 'Your OTP has been sent to you, please enter it below';
      this.isOtpSent = true;
      if (otpType === 1) {
        if (this.sendOtpText === 'Resend SMS OTP') {
          this.successMsg = 'Resend SMS OTP sent successfully';
        }
        this.sendOtpText = 'Resend SMS OTP';
      } else {
        if (this.sendEmailOtpText === 'Resend Email OTP') {
          this.successMsg = 'Resend Email OTP sent successfully';
        }
        this.sendEmailOtpText = 'Resend Email OTP';
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status === 429) {
          this.otpErrorMsg = 'OTP count exceeded, Try after some time';
        }
      });
  }

  validateOtp() {
    if (this.btnOTPIsDisabled) {
      return;
    }
    this.btnOTPIsDisabled = true;
    this.otpErrorMsg = '';
    if (this.otpForm.invalid) {
      this.otpForm.markAllAsTouched();
      this.btnOTPIsDisabled = false;
      return;
    }
    const param = {
      Otp: parseInt(this.otpForm.value.Otp),
      OtpType: this.usedOtpType
    };

    this.otpService.validateOtp(param).subscribe((data: any) => {
      this.btnOTPIsDisabled = false;
      setTimeout(() => {
        this.closeOtpPopup();
        // now check if verify ok and otp ok no need for open security question
        // if (this.basicInfoVerifyConfirmed === false) {
        //   this.openSecurityQuestions();
        // } else {
          // window['dataLayer'] = window['dataLayer'] || [];
          // window['dataLayer'].push({
          //   'event': 'New User Register Successfully'
          // });
          // this.login();
          //}
          this.dataLayerCallback(true)
          {this.login();}
        
      }, 500);
    },
      (err: HttpErrorResponse) => {
        this.btnOTPIsDisabled = false;
        if (err.status === 422) {
          this.otpErrorMsg = 'Invalid OTP';
        }
      });
  }

  dataLayerCallback(callback :boolean)
  {
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        'event': 'New User Register Successfully'
      });
  }

  closeOtpPopup() {
    this.modalService.dismissAll();
    this.otpForm.reset();
    this.sendOtpText = 'Send SMS OTP';
    this.sendEmailOtpText = 'Send Email OTP';
  }


  openSecurityQuestionsPopup() {
    this.modalService.open(this.securityQuestionsModel, this.modalOptions);
    this.modelWindow = 'question';
    this.getSecurityQuestions();
  }

  getSecurityQuestions() {
    this.otpErrorMsg = '';
    this.securityQuestionService.getSecurityQuestionsPart1().subscribe((data: any) => {
      this.securityQuestionList = data;
    },
      (err: HttpErrorResponse) => {
        console.log(err);
        if (err.status === 501) {
          this.modalService.dismissAll();
          this.resetRegisterForm();
          this.errorMsg = err.error;
          // $('#btn-register').hide();
          // $('#btn-partical-register').show();
          this.securityQuestionVerficationFailed();
          // this.securityQuestionErrorMsg = err.error;
          // $('#question-submit').hide();
          // this.cancelQuestion = true;
        }
      });
  }

  validateQuestions() {
    this.securityQuestionErrorMsg = '';

    const attemptedQuestionsLength = this.securityQuestionList.filter(question => question.securityAnswerId != null).length;

    if (attemptedQuestionsLength != 5) {
      this.securityQuestionErrorMsg = 'Please attempt all the questions';
      return;
    }


    const param = { SecurityQuestion: this.securityQuestionList, Attempt: this.securityQuestionAttempt };
    this.securityQuestionService.validateSecurityQuestions(param).subscribe((data: any) => {
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        'event': 'New User Register Successfully'
      });
      this.login();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 422) {
          if (this.securityQuestionAttempt === 1) {
            this.securityQuestionAttempt = 2;
            this.getSecurityQuestionsPart2();
          } else {
            this.modalService.dismissAll();
            this.stopTimer();
            this.resetRegisterForm();
            this.securityQuestionVerficationFailed();
            this.errorMsg = err.error;
          }
        } else {
          this.securityQuestionErrorMsg = err.error;
        }
      });
  }

  getSecurityQuestionsPart2() {
    this.securityQuestionErrorMsg = 'Only a few more questions to go';
    const param = { SecurityQuestion: this.securityQuestionList };
    this.securityQuestionService.getSecurityQuestionsPart2(param).subscribe((data: any) => {
      this.securityQuestionList = data;
      const divScrollTop = document.getElementById('scrollTop');
      if (divScrollTop != null) {
        divScrollTop.scrollTop = 0;
      }
    },
      (err: HttpErrorResponse) => {
      });
  }

  login() {
    this.modalService.dismissAll();
    this.stopTimer();
    this.spinner.show();
    this.azureAdlogin('User Registered Successfully');
  }

  openGrossIncomePopup() {
    this.captureIncomeModelRef = this.modalService.open(this.captureIncomeModel, this.modalOptions);
    this.captureIncomeModelRef.result.then((result: any) => {
    }, (reason: any) => {
      if (reason === 'Cross click') {
        this.openDashboardPage();
      }
    });
  }


  updateIncome() {
    this.captureIncomeErrorMsg = '';
    if (this.captureIncomeForm.invalid) {
      this.captureIncomeForm.markAllAsTouched();
      return;
    }

    if (this.toNumber(this.captureIncomeForm.value.GrossIncome) >= 0) {
      this.captureIncomeForm.value.GrossIncome = parseFloat(this.toNumber(this.captureIncomeForm.value.GrossIncome));
    }

    this.userService.updateGrossIncome(this.captureIncomeForm.value).subscribe((data: any) => {
      this.openDashboardPage();
    },
      (err: HttpErrorResponse) => {
        this.captureIncomeErrorMsg = err.error;
      });
  }

  openDashboardPage() {
    this.modalService.dismissAll();
    this.router.navigate(['/portal/dashboard']);
  }

  toNumber(val: any) {
    val = val || '';
    const valArr = val.toString().split('');
    const valFiltered = valArr.filter((x: number) => !isNaN(x));
    const valProcessed = valFiltered.join('').trim();
    return valProcessed;
  }

  changeGrossIncome() {
    if (this.captureIncomeForm.value.GrossIncome.toString().trim() === 'R') {
      this.captureIncomeForm.patchValue({
        GrossIncome: ''
      });
    }
  }

  azureAdlogin(message) {
    localStorage.removeItem('tempToken');
    let submissionId = '';
    if (this.cookieService.get('submissionId')) {
      submissionId = this.cookieService.get('submissionId');
    }

    this.oauthService.initLoginFlow('', {
      submissionId, ccAppUrl: window.location.origin,
      instrumentationKey: environment.appInsights.instrumentationKey, message
    });
  }

  hideSecurityQuestion() {
    this.modalService.dismissAll();
    this.router.navigateByUrl('/register' + this.registrationRouting.queryStringParams());
  }
  createMessageLink() {
    if (this.errorMsg.includes('{LOGIN-LINK}')) {
      this.messageLinkTemplet = this.messageLinkTemplet.replace('linkText', 'LOGIN').replace('routeValue', '/login')
      this.errorMsg = this.errorMsg.replace('{LOGIN-LINK}', this.messageLinkTemplet);
    }
    else if (this.errorMsg.includes('{REGISTER-LINK}')) {
      this.messageLinkTemplet = this.messageLinkTemplet.replace('linkText', 'REGISTER').replace('routeValue', '/register' + this.registrationRouting.queryStringParams());

      this.errorMsg = this.errorMsg.replace('{REGISTER-LINK}', this.messageLinkTemplet);
    }
  }

  openSecurityQuestions() {
    this.modalService.dismissAll();
    this.router.navigateByUrl('/security-question' + this.registrationRouting.queryStringParams());
  }

}
