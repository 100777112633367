import { Component, OnInit } from '@angular/core';
import { FaqService } from '../services/faq.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  faqsList;
  activeIds: string[] = [];
  closeOther = false;
  constructor(private faqService: FaqService) { }

  ngOnInit() {
    this.getFaqs();
    this.showHideAccordion(window.innerWidth);
  }

  private showHideAccordion(width) {
    if (width >= 767) {
      this.closeOther = false;
    } else {
      this.closeOther = true;
    }
  }
  getFaqs() {
    var loaddata = sessionStorage.getItem('loadFaqs');
    if (loaddata != null) {
      this.BindFaqs(JSON.parse(loaddata));
      return;
    }
    this.faqService.getFaqs().subscribe((data: any) => {
      this.BindFaqs(data);
    }, (err: HttpErrorResponse) => {

    });
  }

  BindFaqs(data) {

    this.faqsList = [];  
  sessionStorage.removeItem('loadFaqs');
  sessionStorage.setItem('loadFaqs', JSON.stringify(data));
      this.faqsList = data;
      for (let i = 0 ; i < this.faqsList.length; i++) {
        this.activeIds.push('cp-' + i);
      }
  
  }

}
