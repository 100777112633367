import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class SecurityQuestionService {

  env = environment;

  constructor(private http: HttpClient) { }

  getSecurityQuestionsPart1() {
    return this.http.post(this.env.webApiUrl + 'api/SecurityQuestion/GetSecurityQuestionsPart1', null);
  }

  validateSecurityQuestions(data) {
    return this.http.post(this.env.webApiUrl + 'api/SecurityQuestion/ValidateSecurityQuestions', data);
  }

  getSecurityQuestionsPart2(data) {
    return this.http.post(this.env.webApiUrl + 'api/SecurityQuestion/GetSecurityQuestionsPart2', data);
  }

  getSecurityQuestion() {
    return this.http.post(this.env.webApiUrl + 'api/SecurityQuestion/GetSecurityQuestion', null);
  }

  getSecurityQuestionsNew(data) {
    return this.http.post(this.env.webApiUrl + 'api/SecurityQuestion/GetSecurityQuestionsNew', data);
  }
}
