import { NgModule } from "@angular/core";
import { BureauDataProvidedComponent } from "./bureau-data-provided.component";

@NgModule({
    imports: [],
    declarations: [BureauDataProvidedComponent],
    exports: [BureauDataProvidedComponent]


})
export class BureauDataProvidedModule { }
