<section class="questionnaire">
    <div class="questionnaire-banner b5containeradjust">
        <div class="container">
            <div class="d-flex row flex-wrap align-items-center">
                <div class="col-md-9 col-8 no-space-right">
                    <h1 class="page-caption text-white m-0">Credit Behaviour Questionnaire </h1>
                </div>
                <div class="col-md-3 text-end col-4">
                    <i class="questionnaire-banner-icon">
                        <img src="/assets/images/puzzle.png" alt="">
                    </i>
                </div>
            </div>
        </div>
    </div>

    <div class="questionnaire-cont">
        <div class="container p-md-0" style="min-height: 603px !important">
            <nav aria-label="breadcrumb bg-transparent">
                <ol class="breadcrumb bg-transparent p-0 question-bdcrumb">
                    <li class="breadcrumb-item pick active" aria-current="page"><a id="dAnchor-questionnaire-step-Financial_wellness"
                            routerLink="/portal/financial-wellness">Financial wellness</a></li>
                </ol>
            </nav>
            <div id="questionAnswerBox" class="stepholder d-flex align-items-stretch" *ngIf="questionList.length>0">

                <ng-container *ngFor="let item of questionList">
                    <ng-container *ngIf="currDiv == item.id">
                        <div class="stepcontent w-100 d-flex align-items-stretch flex-wrap">
                            <header class=" w-100 step-top d-flex align-items-center">
                                <h2 class="step-caption">{{item.question}}</h2>
                                <div class="num"><span class="current-slide">{{item.id}}</span></div>
                            </header>
                            <main class=" w-100 steps-cont">
                                <div class="step-content-hold">
                                    <ul class="step-list d-flex flex-wrap {{ item.className }} listOf-{{item.questionsCount}}">

                                        <li *ngFor="let ans of item.surveyAnswers">

                                            <input type="{{item.type==1?'radio':'checkbox'}}"
                                                id="input-AnsSelected-QuestionnaireStep"
                                                [checked]="item.answered.indexOf(ans.answerId) == -1?false:true"
                                                [(ngModel)]="ans.selected" [attr.answer]="ans.answerId" name="answer"
                                                (change)="onItemChange(ans, item.id, $event.currentTarget.checked)" /> 
                                            <span class="dottext" *ngIf="ans.image == ''">
                                                {{ans.answer}}
                                            </span> 
                                            <span *ngIf="ans.image!=''" class="imageholder">
                                                <span class="spxt" *ngIf="item.id == 2">
                                                    {{ans.answer}}
                                                </span>
                                                <img src="assets/images/{{ans.image}}" alt="" />
                                            </span>

                                        </li>
                                    </ul>
                                </div>
                            </main>
                            <footer
                                class="mt-auto d-flex justify-content-between w-100 step-bottom align-items-center flex-wrap">
                                <a id="dAnchor-questionnaire-step-PREVIOUS" *ngIf="item.id>1" class="d-flex align-items-center"
                                    (click)="showDiv(item.id-1, item.id,item.isAttempt,'previous')" href="javascript:void(0)">
                                    <span id="dAnchor-questionnaire-step-PREVIOUS-Span1" class="prev pe-2" aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
                                            x="0px" y="0px" viewBox="0 0 492.004 492.004"
                                            style="enable-background:new 0 0 492.004 492.004;" xml:space="preserve"
                                            width="25px" height="25px">
                                            <g transform="matrix(-1 0 0 1 492.004 0)">
                                                <g>
                                                    <g>
                                                        <path
                                                            d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                                                            data-original="#000000" class="active-path"
                                                            data-old_color="#000000" fill="#A3D55F" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <span id="dAnchor-questionnaire-step-PREVIOUS-Span2" class="prevtext">previous </span>
                                </a>
                                <a></a>
                                <span class="step-count">Question {{item.id}} of {{questionList.length}}</span>
                                <a id="dAnchor-questionnaire-step-NEXT" *ngIf="item.id!=questionList.length; else elseBlock"
                                    class="d-flex align-items-center"
                                    (click)="showDiv(item.id+1, item.id,item.isAttempt, '')" href="javascript:void(0)">
                                    <span id="dAnchor-questionnaire-step-NEXT-Span1" class="nexttext">next</span>
                                    <span id="dAnchor-questionnaire-step-NEXT-Span2" class="next ps-2" aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
                                            x="0px" y="0px" viewBox="0 0 492.004 492.004"
                                            style="enable-background:new 0 0 492.004 492.004;" xml:space="preserve"
                                            width="25px" height="25px">
                                            <g>
                                                <g>
                                                    <g>
                                                        <path
                                                            d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                                                            data-original="#000000" class="active-path"
                                                            data-old_color="#000000" fill="#A3D55F" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                </a>
                                <ng-template #elseBlock>
                                    <a *ngIf="item.id==questionList.length;" class="d-flex align-items-center"
                                        id="anchor-NextText-QuestionnaireStep"
                                        (click)="showDiv(item.id, item.id,item.isAttempt,'finished')"
                                        href="javascript:void(0)">
                                        <span id="anchor-NextText-QuestionnaireStep-Span1" class="nexttext">Click To Finish</span>
                                    </a>
                                </ng-template>
                                <span class="step-count-mobile d-md-none d-block">Question {{item.id}} of
                                    {{questionList.length}}</span>
                            </footer>
                        </div>
                    </ng-container>
                </ng-container>
                 
            </div>
        </div>
    </div>
</section>