import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { SapiService } from "../services/sapi.service";
import { HttpErrorResponse } from "@angular/common/http";
import { interval } from 'rxjs';

@Component({
    selector: 'app-sapi-register',
    templateUrl: './sapi-register.component.html',
    styleUrls: ['./sapi-register.component.css'],
    host: { 'class': 'd-flex flex-column h-100' },
})
export class SapiRegisterComponent implements OnInit {
    visitId = '';
    secondsLeft: number = 0;
    countDown: number = 30;
    retryCount: number = 0;
    constructor(
        private activatedRoute: ActivatedRoute,
        private sapiService: SapiService,
        private router: Router,
    ) {

    }
    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            if (params && (params.vid || params.Vid || params.VID)) {
                this.visitId = params.vid;
                console.log(this.visitId);
                setTimeout(() => {
                    this.getSapiDetails(this.visitId);
                }, 30000);
            }
            else{
                window.location.href = "/register";
            }
        });
        this.sectionheight();

       
    }
    onResize(event) {
        this.sectionheight();
    }
    sectionheight() {
        var headerh = $('header').height(),
            footerh = $('footer').height(),
            windowh = $(window).height(),
            desiredHeight = windowh - footerh - headerh;
        $('.min-h').css('min-height', desiredHeight + 'px');
    }
    getSapiDetails(visitId) {
        if (this.retryCount < 5) {
            localStorage.removeItem('sapiErrorMessage');
            localStorage.removeItem('sapiIsUserUserDataAvailable');
      const param = {
                VisitId: visitId
            };
            this.sapiService.getNextSapiUrl(param).subscribe((data: any) => {
                if (data) {
                    if (data.isUserUserDataAvailable == true) {
                        if(data.errorMessage!=""){
                            localStorage.setItem('sapiErrorMessage',data.errorMessage);
                            localStorage.setItem('sapiIsUserUserDataAvailable',data.isUserUserDataAvailable);
                        }
                        window.location.href = data.redirectUrl;
                    }
                    else {
                        this.setInterVal();
                    }
                }

            },
                (err: HttpErrorResponse) => {

                });
        }
        else {
            window.location.href = "/register";
        }

    }

    setInterVal() {
        let numbers = interval(1000);
        let numbesubs = numbers.subscribe(x => {
            this.secondsLeft = this.countDown - x;
            if (this.secondsLeft == 0) {
                numbesubs.unsubscribe();
                this.retryCount = this.retryCount + 1;
                this.getSapiDetails(this.visitId);
            }
        });
    }


}