import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable()
export class SapiService {

  env = environment;

  constructor(private http: HttpClient) { }
  
  getNextSapiUrl(data: any) {
    return this.http.post(this.env.webApiUrl + 'api/Sapi/SapiRegistraionProcess', data);
  }

}