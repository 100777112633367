import { Routes } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { RegisterAComponent } from './register-a/register-a.component';
import { TermsComponent } from './terms/terms.component';
import {PolicyComponent}  from './policy/policy.component';
import { ProductsComponent } from './products/products.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { ForgotComponent } from './forgot/forgot.component';
import { AccountComponent } from './account/account.component';
import { SettingComponent } from './setting/setting.component';
import { FaqComponent } from './faq/faq.component';
import { LoginComponent } from './login/login.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { QuestionnaireStepComponent } from './questionnaire-step/questionnaire-step.component';
import { FinancialWellnessComponent } from './financial-wellness/financial-wellness.component';

import { SecurityQuestionComponent } from './security-question/security-question.component';
import { InsuranceComponent } from './insurance/insurance.component';

import { SecurityQuestion2Component } from './security-question2/security-question2.component'; 
import { CellNumberUpdateComponent } from './cell-number-update/cell-number-update.component'; 
import { PaymentComponent } from './payment/payment.component';
import { SmartcentsComponent } from './registration/smartcents/smartcents.component';
import { SapiRegisterComponent } from './sapi/sapi-register.component';
import {InsuranceRouteGuard} from './route_guard/insurance.route.guard';
import { ReferralProgrammeComponent } from './referral-programme/referral-programme.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
export const appRoutes: Routes = [
  {
    path: 'portal', component: LayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard', loadChildren:()=>import('./dashboard/dashboard.module').then(m=>m.DashboardModule)
      },
      {
        path: 'profile', component: ProfileComponent, canActivate: [InsuranceRouteGuard]
      },
      {
        path: 'setting', component: SettingComponent, canActivate: [InsuranceRouteGuard]
      },
      {
        path: 'offers', component: ProductsComponent, canActivate: [InsuranceRouteGuard]
      },
      {
        path: 'account', component: AccountComponent, canActivate: [InsuranceRouteGuard]
      },
      {
        path: 'faq', component: FaqComponent, canActivate: [InsuranceRouteGuard]
      },
      {
        path: 'questionnaire', component: QuestionnaireComponent, canActivate: [InsuranceRouteGuard]
      },
      {
        path: 'questionnaire-step', component: QuestionnaireStepComponent, canActivate: [InsuranceRouteGuard]
      },
      {
        path: 'financial-wellness', component: FinancialWellnessComponent, canActivate: [InsuranceRouteGuard]
      },
      {
        path: 'insurance', component: InsuranceComponent
      },
      {
        path: 'security-question2', component: SecurityQuestion2Component, canActivate: [InsuranceRouteGuard]
      },
      {
        path: 'updatenumber', component: CellNumberUpdateComponent, canActivate: [InsuranceRouteGuard]
      },
      {
        path: 'payments', component: PaymentComponent, canActivate: [InsuranceRouteGuard]
      },
	  {
        path: 'referrals', component: ReferralProgrammeComponent, canActivate: [InsuranceRouteGuard]
      },
      {
        path: 'security', component: UpdatePasswordComponent, canActivate: [InsuranceRouteGuard]
      },
    ]
  },
  // {
  //   path: 'home', component: HomeComponent
  // },
  {
    path: 'register', loadChildren:()=>import('./register/register.module').then(m=>m.RegisterModule)
  },
  {
    path: 'register-a', component: RegisterAComponent
  },
  {
    path: 'terms', component: TermsComponent
  },
  {
    path: 'forgot', loadChildren:()=>import('./forgot/forgot.module').then(m=> m.ForgotModule)
  },
  {
    path: 'policy', component: PolicyComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'security-question', component: SecurityQuestionComponent
  },
  { 
    path: '', redirectTo: '/register', pathMatch: 'full' 
  },
  {
    path: 'smartcents', component: SmartcentsComponent
  },
  {
    path: 'sapi-register', component: SapiRegisterComponent
  },
];
