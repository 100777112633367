<section *ngIf="hasReferralFeature && userType==1" class="px-md-5 py-5 font-color-black">
    <h1 class="text-center text-green pb-3 caption  px-md-0 px-3">Refer a friend to DebtBusters and <span class="fw-bold">earn R1,500 each</span></h1>
    <div class="d-flex flex-wrap align-items-stretch justify-content-center">
        <div class="col-xl-7 col-md-12 col-12 d-flex align-items-stretch">
            <div class="col-md-4 d-md-block top-img">
                
            </div>
            <div class="col-md-4 col-12 text-center d-flex flex-wrap px-md-3">
                <div class="w-100 px-3 px-md-0">
                    <h3 class="text-green fw-bold subcaption">Here’s how it works:</h3>
                    <p class="pfont"> <span class="fw-bold">You</span>  get <span class="fw-bold"> R1,500 cash </span> when your friend signs up with DebtBusters and makes their first payment.</p>
                </div>
                <div class="d-md-none w-100">
                    <img src="../../assets/images/referban-mob.png" class="img-fluid" />
                </div>
                <div class="w-100 mt-md-auto px-3 px-md-0">
                    <h3 class="text-green fw-bold subcaption">Plus </h3>
                    <p class="mb-0 pfont">they’ll receive <span class="fw-bold">R1,500 cash</span> too!</p>
                </div>
            </div>
            <div class="col-md-4 d-md-block bot-img text-end">
                
            </div>
        </div>
        <div class="col-xl-5 col-lg-7 col-md-9 ps-xl-5 d-flex flex-wrap align-items-stretch mt-md-5 mt-xl-0 mt-0 ps-4 pe-4 ps-md-0 pe-md-0">
            <div class="text-center shadowbox d-flex flex-wrap align-items-stretch py-3 w-100 rounded">
                <div class="w-100 ">
                    <div class="px-md-5 px-3">
                        <h3 class="text-green fw-bold mb-md-4 mb-3 subcaption">Ready to get started? </h3>
                        <p class="pfont mb-md-3 mb-1">When your friend uses the link to sign up with DebtBusters and makes their first payment, 
                            <span class="text-green d-md-block fw-bold">you earn R1,500 each. </span>
                        </p>
                    </div>
                    <div class="px-md-5 px-3">
                        <div id="readmore" style="display: none;">
                            <div class="p-3">
                                <h6 class="m-0">
                                    Referral link saved to your clipboard
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8-1.41-1.42z" id="tick"/></defs><use fill="#61a544" fill-rule="nonzero" xlink:href="#tick"/></svg>
                                </h6>
                            </div>
                        </div>
                            
 
                        <div class="w-100 pt-3 px-md-5 "><button type="button" (click)="copyInputMessage()" id="btn-copyLink-referral" name="btn-copyLink-referral"  class="btn btn-lg btn-orange text-white rounded-pill fw-bold">Copy link</button></div>
                        <div class="w-100 py-md-3 py-2 fw-bold pfont">OR</div>
                        <div class="w-100 pb-3 px-md-5"><button type="button" (click)="sendReferralMessageViaWhatsApp()" id="btn-whatsappShare-referral" name="btn-whatsappShare-referral" class="btn btn-lg btn-orange text-white rounded-pill fw-bold">Share with WhatsApp</button></div>
                    </div>
                </div>
                
                <div class="mt-auto w-100 pb-md-3 pb-0 px-3">
                    <p class="pfont mb-0 pt-md-2 pt-0">The more friends you refer, the more you can earn.* 
                    <span class="sm-font d-block">Terms and conditions apply.**</span> </p>
                </div>
            </div>
        </div>
    </div>
    
</section>
<section *ngIf="hasReferralFeature && userType==1" class="pb-4 font-color-black">
    <div class="w-100 bg-green text-center px-md-0 px-3">
        <h2 class="text-white py-3 fw-bolder foofont">Start referring friends to boost your income.</h2>
    </div>
    <div class="text-center px-md-0 px-3">
        <p class="sm-font">*You can earn up to R7,500 a month.   **By engaging on our platform, you accept our <a href="javascript:void(0)" class="termsLink" id="btn-termsPopup-referral" name="btn-termsPopup-referral" (click)="referralTermsPopup()">Refer & Earn Programme Terms and Conditions</a> .</p>
    </div>
</section>



<!---terms popup-->
<ng-template #referralTermPop let-modal>
    <button type="button" class="popclose" id="btn-topPopupcross-referralTerms" name="btn-topPopupcross-referralTerms" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span id="btn-topPopupcross-referralTerms-Span1" aria-hidden="true">&times;</span>
    </button>
    <app-referral-terms-popup></app-referral-terms-popup>
    <div class="modal-footer justify-content-center">
        <button type="button" name="OK" class="readDisclosures" id="btn-bottomPopupclose-referralTerms" name="btn-bottomPopupclose-referralTerms" aria-label="Close" (click)="modal.dismiss('Cross click')">
           Close
        </button>
    </div>
</ng-template>
