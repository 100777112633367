import { Component, HostBinding, OnInit, ViewChild, ElementRef } from '@angular/core';
import {  Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationService } from '../services/communication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { MessagesService } from '../services/messages.service';
import { ReferralDataService } from '../services/referral-data-service.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  env = environment;
  @ViewChild('callbackConfirmationModel', { static: false }) callbackConfirmationModel: ElementRef;
  @ViewChild('LoginBackError', { static: false }) LoginBackError: ElementRef;
  @HostBinding('style.width') public readonly WIDTH = '100%';
 
  userData = null;
  title = 'webapp';
  callbackErrorMsg = '';
  callbackSuccessMsg = '';
  modalOptions: NgbModalOptions;
  userType = 2;
  accountsPage = 'My Accounts';
  isEmptyState = false;
  ifDbcType1: boolean = false;
  messages: any[] = [];
  subscription: Subscription;
  hasReferralFeature = false;
  referralId = null;
   
  constructor(
    private messageService: MessagesService,
    public router: Router,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private modalService: NgbModal,
    private communicationService: CommunicationService,
    private spinner:NgxSpinnerService,
    private referralDataService: ReferralDataService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message) {
        this.messages.push(message);
        this.checkUserType(message);
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
  }

  ngOnInit(): void {
    this.checkForReferral();
    this.userData = this.oauthService.getIdentityClaims();
    this.bindNavBar();
    let type = this.cookieService.get('uType');
    

    let setDotClass = this.cookieService.get('setDotClass');
    if (type === '3' || type === '2') {
      this.accountsPage = 'My Accounts';
      $('#liFinancialWellness').show();
      if (setDotClass === 'orngemenutext') {
        $('#liFinancialWellness').removeClass('orngemenu');
        $('#liFinancialWellness').addClass('orngemenutext');
        $('#navbarToggler').addClass('navbar-toggler');
        $('#navbarToggler').addClass('p-0');
        $('#navbarToggler').addClass('navbar-togglertext p-0');
      } else {
        $('#liFinancialWellness').removeClass('orngemenutext');
        $('#liFinancialWellness').addClass('orngemenu');
        $('#navbarToggler').removeClass('navbar-togglertext p-0');
        $('#navbarToggler').removeClass('navbar-toggler');
        $('#navbarToggler').removeClass('p-0');

      }
    }
    else if(type === '1') {
      this.userType = 1;
      $('#liFinancialWellness').hide();
      $('#navbarToggler').removeClass('navbar-togglertext p-0');
      $('#navbarToggler').removeClass('navbar-toggler');
      $('#navbarToggler').removeClass('p-0'); 
      this.accountsPage = 'Accounts';   
    }
    if (type === '3') {
      //$('#liFinancialWellness').show();
      console.log("HeaderComponent");
      var date = new Date();
      var hour = date.getUTCHours() + 2;
    } else {
      //$('#liFinancialWellness').hide();
    }
 
    this.showHide();
 
  }

  checkUserType(type){
    // let type = this.cookieService.get('uType');
    if(type === '1')
    {
      this.ngOnInit();
    }

    
  }

  showHide() {
    const fn = () => {
      var isEmptyState = localStorage.getItem('isEmptyState');
      if (isEmptyState != '' && isEmptyState == 'true') {
        this.isEmptyState = true;
      } else {
        this.isEmptyState = false;
      }
    };

    setInterval(() => {
      fn();
    }, 1000);

    fn();

  }

    
  bindNavBar() {
    //const backDrop = '<div class="backdrop"></div>';
    $('.navbar-toggler').click(function () {
      $('body').addClass('push-menu-body');
      $('.navbar').addClass('push-menu-visible');
      // $(backDrop).appendTo('body').on('click', function () {
      //   $('.navbar').removeClass('push-menu-visible');
      //   $(this).remove();
      // });
    });
    $('.navbar-close').click(function () {
      $('body').removeClass('push-menu-body');
      $('.navbar').removeClass('push-menu-visible');
      //$('.backdrop').remove();
    });
    $('.spmenu').click(function () {
      $('body').removeClass('push-menu-body');
      $('.navbar').removeClass('push-menu-visible');
      //$('.backdrop').remove();
    });
  }


  logout() {
    this.spinner.show();
    setTimeout(() => {
    (window as any).fcWidget.destroy();
    this.cookieService.delete('submissionId', '/');
    this.cookieService.delete('uType', '/');
    this.cookieService.delete('userId', '/');
    localStorage.removeItem('isLoginSuccess');
    this.cookieService.delete('setDotClass', '/'); 
    this.cookieService.delete('hasInsurance', '/');    
    this.cookieService.delete('CreditHistoryId', '/');

    sessionStorage.removeItem('simplicityData');
    sessionStorage.clear();
    this.oauthService.logOut();
    this.spinner.hide();
  }, 3000);
  }

  azureAdlogin() {
    let submissionId = '';
    if (this.cookieService.get('submissionId')) {
      submissionId = this.cookieService.get('submissionId');
    }
    this.oauthService.initLoginFlow('', {
      submissionId, ccAppUrl: window.location.origin,
      instrumentationKey: environment.appInsights.instrumentationKey
    });
  }

  callbackPopup() {
    this.modalService.open(this.callbackConfirmationModel, this.modalOptions);
  }

  callback() {
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.communicationService.userCallback().subscribe((data: any) => {
      this.callbackSuccessMsg = 'Our coach will contact you shortly';
    },
      (err: HttpErrorResponse) => {
        this.callbackErrorMsg = 'Error: Please try after some time';
      });
  }

  checkLoginFired() {
    if (this.router.url == '/security-question') {
      localStorage.setItem('loginFired', 'true');
      this.modalService.open(this.LoginBackError, this.modalOptions);
    }
    else
      this.azureAdlogin();
  }

  redirectLogin() {
    this.modalService.dismissAll();
    this.azureAdlogin();
  }

  stopNavigation() { this.modalService.dismissAll(); }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
  
  checkForReferral()
  {
    this.referralDataService.getReferralData().subscribe(([referralId, hasReferralFeature]) => {
      this.referralId = referralId;
      this.hasReferralFeature = hasReferralFeature;
    });
  }


}

