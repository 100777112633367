  <!-- Header HTML Start -->
<!-- <app-header></app-header> -->
  <!-- Header HTML End -->
  <header>
    <!-- Fixed navbar -->
    <nav class="navbar navbar-expand-md nav-cutom bg-white">
        <div class="d-flex w-100 align-items-center justify-content-between px-md-5 p-md-0">
            <a class="navbar-brand p-0" id="anchor-LinkToDebtbusters-SapiRegister" href="https://www.debtbusters.co.za/" target="_blank"><img
                    src="../../assets/images/db-logo.png" alt=""></a>
                    <div class="signblock ms-auto">
                        <img src="../../assets/images/green_overlays.png" alt="" class="overlay">
                        
                    </div>
        </div>
        
    </nav>
</header>

<section (window:resize)="onResize($event)" class=" register-hld min-h register d-flex align-items-center graybg b5containeradjust">
  <div class="container text-center">
    <svg version="1.1" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-25 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
      <circle fill="#388C1B" stroke="none" cx="6" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"></animate>    
      </circle>
      <circle fill="#388C1B" stroke="none" cx="26" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"></animate>       
      </circle>
      <circle fill="#388C1B" stroke="none" cx="46" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"></animate>     
      </circle>
    </svg>
  </div>
</section>

  <!-- Footer HTML Start -->
  <app-footer></app-footer>
  <!-- Footer HTML End -->