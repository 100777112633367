import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn : 'root'
})

export class InsuranceService{

    env = environment;
    constructor(private http:HttpClient) {}

    getPolicyHolderDetails(){
        return this.http.post(this.env.webApiUrl + 'api/Insurance/GetPolicyHolderDetails', null);
    }

    getInsuranceDetails(){
        return this.http.post(this.env.webApiUrl + 'api/Insurance/GetInsuranceDetails', null);
    }

    saveCreditLifeCover(data: any){
        return this.http.post(this.env.webApiUrl + 'api/Insurance/SaveCreditLifeCover', data);
    }

    saveLifeCover(data: any){
        return this.http.post(this.env.webApiUrl + 'api/Insurance/SaveLifeCover', data);
    }

    saveLegalCover(data: any){
        return this.http.post(this.env.webApiUrl + 'api/Insurance/SaveLegalCover',data);

    }
    
    getFuneralCoverDetails(){
        return this.http.post(this.env.webApiUrl + 'api/Insurance/GetFuneralCoverDetails', null);
    }

    saveFuneralCover(data: any){
        return this.http.post(this.env.webApiUrl + 'api/Insurance/SaveFuneralCover', data);
    }

    getInsurancePolicyDetails(data: any){
        return this.http.post(this.env.webApiUrl + 'api/Insurance/GetInsurancePolicyDetails', data);
    }

    getUserBeneficiaryDetails(data: any){
        return this.http.post(this.env.webApiUrl + 'api/Insurance/GetUserBeneficiaryDetails', data);
    }
}