<section class="min-spctbtn">
    <div class="gry-bg d-flex justify-content-center align-items-center bghight bgfoo py-md-5 ">
        <div class="col-lg-4 col-md-7 col-11 bg-white c-shdow pt-md-4 spcebtn pt-3">
            <div class="text-center mb-3">
                <i>
                    <img src="../../assets/images/otp-icon.png" width="100px" alt="" />
                </i>
            </div>
            
        
            <div class="otp-send-screen" *ngIf="showCellPhoneScreenCapture">
                <div class="text-holder">
                    <h3 class="page-caption text-center mb-md-3">What’s Your New Number?</h3>
                    <p class="text-small text-center mb-md-3">Enter your new cellphone number below.</p>
                    <p class="text-small text-center mb-md-3">
                        Please make sure your number is entered correctly as we will send you an OTP for verification to this number.  
                    </p>
                </div>
               
                <form class="otp-form" [formGroup]="phoneNumberForm" (ngSubmit)="updatePhoneNumberRequest(false)">
                    <div class="px-md-5">
                       <div class="form-group spacingbothside mb-2" *ngIf="showCellPhoneScreenCapture">
                           <label class="text-msmall" for="cellnumber" >New Cellphone Number</label>
                           <input type="text" class="form-control" id="input-Cellnumber-CellNumberUpdate" formControlName="phoneNumber"  numeric-field maxlength="10" (input)="onChange($event.target.value)">
                           <div class="invalid-feedback">
                               <div *ngIf="phoneNumberForm.get('phoneNumber')?.errors?.required 
                                     && phoneNumberForm.get('phoneNumber')?.touched" >Cellphone number is required</div>
                               <div *ngIf="phoneNumberForm.get('phoneNumber')?.errors?.minlength">Cellphone number 10 digits long</div>
                               <div *ngIf="oldPhoneNumber == this.phoneNumberForm.controls['phoneNumber'].value">The provided cellphone number is the same as the current cellphone number in use.</div>
                               <div *ngIf="phoneNumberForm.get('phoneNumber')?.errors?.isValidMobile">Please enter your valid cellphone number</div>
                           </div>
                           <div class="invalid-feedback" *ngIf="errorMsg != null && 
                                !phoneNumberForm.get('phoneNumber')?.errors?.required && 
                                !phoneNumberForm.get('phoneNumber')?.errors?.minlength  &&
                                !phoneNumberForm.get('phoneNumber')?.errors?.isValidMobile &&
                                !(oldPhoneNumber == this.phoneNumberForm.controls['phoneNumber'].value)">{{errorMsg}}</div>      

                       </div>
                       <div class="form-group justify-content-between d-flex spacingbothside  mb-0">
                           <a name="btnCancelUpdatePhoneNumberPage" id="anchor-CancelUpdate-CellNumberUpdate" routerLink="/portal/profile" class="bnlink">Cancel</a>
                           <button name="btnSendOtpUpdatePhoneNumberPage" id="btn-SendOtp-CellNumberUpdate" class="sendOTP" >Send OTP</button>
                       </div>
                   </div>
                </form>
                
            </div>
            
        
            <div class="otp-submit-screen" *ngIf="showOtpCaptureScreen">
                <div class="text-holder">
                    <h3 class="page-caption text-center mb-md-3">Enter OTP</h3>
                    <p class="text-small text-center mb-md-3">A 6-digit code has been sent to XXX XXX {{lastfourdigit}}. It might take a few moments to arrive. </p>
                    <div *ngIf="successMsg!=''" class="alert alert-success" role="alert">
                        {{successMsg}}
                    </div>
                    <div  *ngIf="successMsgWithPipe == true" class="alert alert-success" role="alert">
                        Your session will expire in {{counter | formatTime}}.
                    </div>
                </div>
                <form class="otp-form" [formGroup]="otpForm" (ngSubmit)="validateOtpToUpdatePhoneNumber()">
                    <div class="mb-3">
                        <div class="passcode-wrapper">
                            <input id="otp1" numeric-field maxlength="1" (keyup)="onKeyUpEvent($event, 1)" (focus)="onFocusEvent(1)">
                            <input id="otp2" numeric-field maxlength="1" (keyup)="onKeyUpEvent($event, 2)" (focus)="onFocusEvent(2)">
                            <input id="otp3" numeric-field maxlength="1" (keyup)="onKeyUpEvent($event, 3)" (focus)="onFocusEvent(3)">
                            <input id="otp4" numeric-field maxlength="1" (keyup)="onKeyUpEvent($event, 4)" (focus)="onFocusEvent(4)">
                            <input id="otp5" numeric-field maxlength="1" (keyup)="onKeyUpEvent($event, 5)" (focus)="onFocusEvent(5)">
                            <input id="otp6" numeric-field maxlength="1" (keyup)="onKeyUpEvent($event, 6)" (focus)="onFocusEvent(6)">
                        </div>
                        <div class="invalid-feedback text-center" *ngIf="otpErrorMsg != null">{{otpErrorMsg}}</div>
                        <div class="success-msg text-center mt-2" *ngIf="resendMsg != null" >{{resendMsg}}</div>
                    </div>
                    <p *ngIf="!hideResendOTPButton" class="text-msmall text-center mb-md-0">Didn’t receive the verification OTP? </p>
                    <div class="form-group text-center d-flex spacingbothside  mb-0">
                        <a *ngIf="!hideResendOTPButton" href="javascript:void(0)"  name="btnResendOtpUpdatePhoneNumberPage" id="anchor-ResendOtp-CellNumberUpdate" (click)="disableOTPSendButton ? $event.preventDefault() : updatePhoneNumberRequest(true)" class="bnlink mx-auto text-underline text-bold" 
                            [ngClass]="{ 'disabled-anchor': disableOTPSendButton }">  Resend OTP <span *ngIf="secondsLeft > 0">in {{secondsLeft}}s</span>
                     </a>
                    </div>
                </form>
            </div>
        </div>

    </div>
</section>
