<section class="profile">
    <!-- <div class="profile-banner">
        <div class="container p-md-0">
            <div class="d-flex row flex-wrap align-items-center">
                <div class="col-md-9 col-8 no-space-right">
                    <h1 class="page-caption text-white m-0">Your Profile Details</h1>
                </div>
                <div class="col-md-3 text-end col-4">
                    <i class="profile-banner-icon">
                        <svg enable-background="new 0 0 128 128" height="120px" id="Layer_1" version="1.1"
                            viewBox="0 0 128 128" width="120px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path
                                d="M64.281,84.291c-15.54,0-28.184-12.643-28.184-28.184V42.196c0-15.542,12.644-28.186,28.184-28.186  c15.542,0,28.186,12.644,28.186,28.186v13.911C92.467,71.648,79.823,84.291,64.281,84.291z M64.281,18.01  c-13.335,0-24.184,10.85-24.184,24.186v13.911c0,13.334,10.849,24.184,24.184,24.184c13.336,0,24.186-10.85,24.186-24.184V42.196  C88.467,28.86,77.617,18.01,64.281,18.01z"
                                fill="#a3d55f" />
                            <path
                                d="M102.332,114.01h-76.51c-6.518,0-11.819-5.303-11.819-11.82v-8.734c0-3.857,1.953-9.027,11.26-11.738l11.443-3.168  c1.067-0.291,2.167,0.33,2.461,1.395s-0.33,2.166-1.395,2.461l-11.417,3.16c-8.353,2.434-8.353,6.541-8.353,7.891v8.734  c0,4.313,3.508,7.82,7.819,7.82h76.51c4.312,0,7.819-3.508,7.819-7.82v-8.734c0-3.643-2.816-6.299-8.372-7.896l-10.892-3.045  c-1.064-0.297-1.686-1.4-1.388-2.465c0.298-1.063,1.398-1.689,2.464-1.387l10.906,3.049c9.326,2.682,11.281,7.867,11.281,11.744  v8.734C114.151,108.707,108.85,114.01,102.332,114.01z"
                                fill="#a3d55f" />
                        </svg>
                    </i>
                </div>
            </div>

        </div>
    </div> -->

    <div class="profile-content b5containeradjust">
        <div class="container">
            <div class="profile-holder">
                <form class="w-100" [formGroup]="updateProfileForm" (ngSubmit)="updateProfile()">
                    <div class="w-100">
                        <div *ngIf="successMsg!=''" class="alert alert-success " role="alert">
                            {{successMsg}}
                        </div>
                    </div>
                    <div class="w-100">
                        <div *ngIf="successMsgWarning!=''" class="alert alert-danger " role="alert">
                            {{successMsgWarning}}
                        </div>
                    </div>
                    <h2 class="profile-frm-caption">Profile</h2>
                    <div class="form-group d-flex flex-wrap profile-frm">
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="FirstName">Name</label>
                            <input id="FirstName" type="text" readonly class="form-control" placeholder="First Name"
                                formControlName="firstName" name-field>

                        </div>
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="Surname">Surname</label>
                            <input id="Surname" type="text" readonly class="form-control" formControlName="surname"
                                placeholder="Surname" name-field>

                        </div>
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28 canupdate">
                            <label for="PhoneNumber">Cell Phone Number 
                            <a id="dAnchor-profile-Update" name="btnUpdatePhoneNumberProfilePage" href="javascript:void(0)" routerLink="/portal/updatenumber" class="updateLink">Update</a></label>
                            <input id="PhoneNumber" type="tel" readonly class="form-control"
                                placeholder="Cell Phone Number" formControlName="phoneNumber" numeric-field
                                maxlength="10">
                            
                        </div>


                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="grossIncome">Take Home Salary</label>
                            <input class="form-control" type="text" appCurrencyMask formControlName="grossIncome"
                                id="currency-number-prof" (blur)="changeGrossIncome()" [value]="this.updateProfileForm.value.GrossIncome">
                            <div *ngIf="profileFormControls.grossIncome.touched && profileFormControls.grossIncome.errors"
                                class="invalid-feedback">
                                <div *ngIf="profileFormControls.grossIncome.errors.required">Take-home salary is
                                    required</div>
                                <div *ngIf="profileFormControls.grossIncome.errors.min">Invalid Take-home salary
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="Email">Email address</label>
                            <input id="Email" type="email" class="form-control" placeholder="Email address"
                                formControlName="email">
                            <div *ngIf="profileFormControls.email.touched && profileFormControls.email.errors"
                                class="invalid-feedback">
                                <div *ngIf="profileFormControls.email.errors.required">Email address is required</div>
                                <div *ngIf="profileFormControls.email.errors.email">Please enter a valid email address
                                </div>
                            </div>
                        </div>
                    </div>
 
                    <h2 class="profile-frm-caption sm-caption mt-3">Employer last updated on: {{ profileFormControls.employerDate.value | date }}</h2>
                    <div class="form-group d-flex flex-wrap profile-frm">
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="EmployerName">Employer Name</label>
                            <input id="EmployerName" type="text" class="form-control" placeholder="Employer Name"
                                formControlName="employerName">
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="Occupation">Occupation</label>
                            <input id="Occupation" type="text" class="form-control" placeholder="Occupation"
                                formControlName="occupation">
                        </div>
                    </div>


                    <h2 class="profile-frm-caption sm-caption mt-3">Address last updated on: {{ profileFormControls.addressDate.value | date }}</h2>
                    <div class="form-group d-flex flex-wrap profile-frm">
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="AddressType">Address Type</label>
                            <input id="AddressType" type="text" readonly class="form-control" placeholder="Address Type"
                                formControlName="addressType">

                        </div>
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="AddressLine1">Street Address</label>
                            <textarea id="AddressLine1" type="text" rows="3" class="form-control"
                                placeholder="Street Address" formControlName="addressLine1"></textarea>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="AddressLine2">Apartment / Suite number</label>
                            <textarea id="AddressLine2" type="text" rows="3" class="form-control"
                                placeholder="Apartment / Suite number" formControlName="addressLine2"></textarea>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="Suburb">Suburb</label>
                            <input id="Suburb" type="text" class="form-control" formControlName="suburb"
                                placeholder="Suburb">
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="Town">Town</label>
                            <input id="Town" type="text" class="form-control" formControlName="town"
                                placeholder="City / Town">
                        </div>
                    </div>


                    <h2 class="profile-frm-caption sm-caption mt-3" *ngIf="this.isShowSpouseInfo"> User Spouse Information </h2>
                    <div class="form-group d-flex flex-wrap profile-frm" *ngIf="this.isShowSpouseInfo">
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="spouseCurrentBalance">Spouse Current Balance </label>
                            <input class="form-control" type="text" (blur)="changeUserSpouseInfo()" appCurrencyMask formControlName="spouseCurrentBalance" id="spouseCurrentBalance">
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="spouseDebtRepayment">Spouse Debt Repayment </label>
                            <input class="form-control" type="text" (blur)="changeUserSpouseInfo()" appCurrencyMask formControlName="spouseDebtRepayment" id="spouseDebtRepayment">
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 px-3 mb-28">
                            <label for="spouseTakeHomeSalary">Spouse Take Home Salary </label>
                            <input class="form-control" type="text" (blur)="changeUserSpouseInfo()" appCurrencyMask formControlName="spouseTakeHomeSalary" id="spouseTakeHomeSalary">
                        </div>
                    </div>

                    <div class="buttonholder mt-3">
                        <button id="dButton-profile-Update_profil" name="btnUpdateProfilePage" type="submit" [disabled]="changeDisable"
                            class="btn btn-custom mb-md-0 mb-3"> Update profile </button>
                    </div>



                </form>

            </div>
        </div>
    </div>



</section>