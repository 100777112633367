import { Component , HostBinding, OnInit } from '@angular/core'; 
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  constructor(
    private oauthService: OAuthService,
    private router: Router
  ) { }

  ngOnInit() {
    if (!this.oauthService.getIdentityClaims()) {
      this.router.navigate(['/register']);
    }
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
}


