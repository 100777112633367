import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SettingService } from '../services/setting.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  updateSettingForm: FormGroup;
  getUserSettingData: any = {};

  successMsg = '';
  successMsgWarning = '';

  constructor(
    private formBuilder: FormBuilder,
    private settingService: SettingService,
  ) { }


  ngOnInit() {
    this.updateSettingForm = this.formBuilder.group({
      allowNotifications: [true],
      notificationForReport: [true],
      notificationForSolutions: [true],
      notificationForNewsAndFeatures: [true],
      notificationForCoachingAndTips: [true],
      allowEmail: [true],
      allowSMS: [true],
      allowPhone: [true],
    });

    this.userSetting();
    if ((window as any).fcWidget) {
      (window as any).fcWidget.destroy();
    }
  }

  get settingFormControls() { return this.updateSettingForm.controls; }

  userSetting() {
    this.settingService.getUserSetting().subscribe((data: any) => {
      if (data != null) {
        this.updateSettingForm.patchValue(data);
      } else {
        this.settingService.updateUserSetting(this.updateSettingForm.value).subscribe((updatedData: any) => {
          console.log('Inserted');
        }, (err: HttpErrorResponse) => { });
      }
    }, (err: HttpErrorResponse) => {

    });
  }

  allowNotification() {

    if (this.updateSettingForm.controls.allowNotifications.value === true) {
      this.updateSettingForm.patchValue({
        notificationForReport: true,
        notificationForSolutions: true,
        notificationForNewsAndFeatures: true,
        notificationForCoachingAndTips: true,
        allowEmail: true,
        allowSMS: true,
        allowPhone: true
      });
    } else {
      this.updateSettingForm.patchValue({
        notificationForReport: false,
        notificationForSolutions: false,
        notificationForNewsAndFeatures: false,
        notificationForCoachingAndTips: false,
        allowEmail: false,
        allowSMS: false,
        allowPhone: false
      });
    }

    // console.log(this.updateSettingForm.value);

    this.updateAll();
  }

  changeSetting() {
    if (this.updateSettingForm.controls.allowEmail.value === true
      || this.updateSettingForm.controls.allowSMS.value === true
      || this.updateSettingForm.controls.allowPhone.value === true) {
      this.updateSettingForm.controls.allowNotifications.setValue(true); 
    } else {
      this.updateSettingForm.controls.allowNotifications.setValue(false);
    }

    this.updateAll();
  }

  updateAll() {
    console.log(this.updateSettingForm.value);

    this.settingService.updateUserSetting(this.updateSettingForm.value).subscribe((data: any) => {
       console.log('submited');
    }, (err: HttpErrorResponse) => {

    });
  }
}
