import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DashboardService } from './dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralDataService {
  private hasReferralFeature = new BehaviorSubject<boolean>(false);
  private referralId = new BehaviorSubject<string | null>(null);

  constructor(
    private dashboardService: DashboardService,
    private http: HttpClient
  ) {}

  getReferralData(isReqFromHeader?): Observable<[string, boolean]> {
    const referralId = sessionStorage.getItem('referralId');

    if (referralId != null && referralId !== '') {
      if(referralId == '0'){
        this.hasReferralFeature.next(false);
        this.referralId.next(null);
      }
      else
      {
        this.hasReferralFeature.next(true);
        this.referralId.next(referralId); 
      }
      return this.getDataObservable(); // Return observable with current values
    }
    else {
      return this.fetchReferralData().pipe(
        switchMap((data: any) => {
          if (data != null && data.status === 200 && data.referralId != null && data.referralId !== '') {
            this.hasReferralFeature.next(true);
            this.referralId.next(data.referralId);
            sessionStorage.setItem('referralId', data.referralId);
          }
          else{
            this.hasReferralFeature.next(false);
            this.referralId.next(null);
            sessionStorage.setItem('referralId', '0' );
          }
          return this.getDataObservable(); // Return observable with current values
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Error fetching referral data:', error);
          if (error) {
            this.hasReferralFeature.next(false);
            this.referralId.next(null);
            sessionStorage.setItem('referralId', '0');
          }
          return this.getDataObservable(); // Return observable with current values
        })
      );
    }
  }

  private fetchReferralData(): Observable<any> {
    return this.dashboardService.fetchReferralData();
  }

  private getDataObservable(): Observable<[string, boolean]> {
    return of([this.referralId.value, this.hasReferralFeature.value]);
  }
}
