import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ProfileService } from '../services/profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from '../services/user.service';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import { FinancialWellnessService } from '../services/financial-wellness.service';
import { Router } from '@angular/router';
import { DashboardService } from '../services/dashboard.service';
import { CommunicationService } from '../services/communication.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  env = environment
  updateProfileForm: FormGroup;
  getUserProfileData: any = {};
  phoneNumberUpdateDisabledTillDate;
  IsPhoneNumberUpdateDisabled: boolean = false;

  successMsg = '';
  successMsgWarning = '';
  changeDisable = true;
  calculateCOPForm;
  isShowSpouseInfo = false;
  isSolution = false;
  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private oauthService: OAuthService,
    private userService: UserService,
    private cookieService: CookieService,
    private financialWellnessService: FinancialWellnessService,
    private dashboardService: DashboardService,
    private router: Router,
    private communicationService: CommunicationService
  ) { 
    if (this.router.getCurrentNavigation() != null) {
      let state = this.router.getCurrentNavigation()
                .extras.state as 
      {example: string, type: string};
      if(state != undefined)
      {
        if(state.type === 'error'){
          this.successMsgWarning = state.example;
        }else{
          this.successMsg = state.example;
        }
      }
    }
  }


  ngOnInit() {
    this.updateProfileForm = this.formBuilder.group({
      firstName: [''],
      surname: [''],
      phoneNumber: [''],
      email: ['', [Validators.required, Validators.email]],
      grossIncome: ['', [Validators.required, Validators.min(1)]],
      occupation: [''],
      employerName: [''],
      employerDate: [''],
      addressDate: [''],
      addressLine1: [''],
      addressLine2: [''],
      suburb: [''],
      town: [''],
      addressType: [''],
      spouseCurrentBalance: ['', Validators.min(0)],
      spouseDebtRepayment: ['', Validators.min(0)],
      spouseTakeHomeSalary: ['', Validators.min(0)]
    });

    this.userProfile();
    let type = this.cookieService.get('uType');
    console.log("profile init", type, parseInt(type) == 2);
    if(parseInt(type) == 3){
      console.log("window service__1");
      //$('#liFinancialWellness').show();
      var date = new Date();
      var hour = date.getUTCHours() + 2;
      var day = date.getDay();
      var minutes = date.getUTCMinutes();
      console.log("this is hour", hour); 
      if(day == 1 || day == 2 || day == 3 || day == 4)
      {
        if(hour == 7 && minutes >= 30){
          this.createChat();
        }
        if(hour >=8 && hour <= 18){
          this.createChat();
        }
      }
      if( day == 5){
        if(hour == 7 && minutes >= 30){
          this.createChat();
        }
        if(hour >=8 && hour <= 17){
          this.createChat();
        }
      }
    }

    this.getUserProfileSpouseInfo();
    setTimeout(() => {
      let type = parseInt(this.cookieService.get('uType'));
      if(type == 3 || type == 2){
      this.activeSurveyDot(type);
      this.changeDisable = true;
      }
    }, 200);
  }

  onChanges(): void {
    this.updateProfileForm.valueChanges.subscribe(val => {
      this.changeDisable = false;
    });
  }


  get profileFormControls() { return this.updateProfileForm.controls; }

  userProfile() {
    this.profileService.userProfile().subscribe((data: any) => {
      //var currentDate =  new Date();
      // var phoneNumberUpdateDisabledTill =  new Date(data.phoneNumberUpdateDisabledTill);    

      // if(currentDate < phoneNumberUpdateDisabledTill){
      //   this.IsPhoneNumberUpdateDisabled = true;
      //   console.log("Phone number update disable" );
      // }
      this.updateProfileForm.patchValue(data);
      setTimeout(() => {
        this.updateProfileForm.patchValue({
            addressDate: this.toDateString(data.addressDate),
            employerDate: this.toDateString(data.employerDate),
        });
        this.onChanges();
        this.changeDisable = true;
      }, 200);

    }, (err: HttpErrorResponse) => {

    });
  }

  getUserProfileSpouseInfo() {
    this.profileService.getUserProfileSpouseInfo().subscribe((data: any) => {
      if (data != null) {
        console.log(data);
        if (data.isAvailable === true) {  
          setTimeout(() => {
            this.updateProfileForm.patchValue({ spouseCurrentBalance: data.spouseCurrentBalance }); 
            this.updateProfileForm.patchValue({ spouseDebtRepayment: data.spouseDebtRepayment }); 
            this.updateProfileForm.patchValue({ spouseTakeHomeSalary: data.spouseTakeHomeSalary }); 
            this.onChanges();
            this.changeDisable = true;
          }, 300);
          
          this.isShowSpouseInfo = true; 
        } 
      } 
    },
    (err: HttpErrorResponse) => { });
  }

  updateProfile() {
    if (this.updateProfileForm.invalid) {
      this.updateProfileForm.markAllAsTouched();
      console.log('invalid form');
      this.scrollToTop();
      return;
    }

    const grossIncomeName = 'grossIncome';
    let grossIncome: any = '';
    if (this.toNumber(this.updateProfileForm.controls.grossIncome.value) >= 0) {
      grossIncome = parseFloat(this.toNumber(this.updateProfileForm.controls.grossIncome.value));
    }

    this.updateProfileForm.controls[grossIncomeName].setValue((!isNaN(grossIncome)) ? grossIncome : 0 );

    console.log(this.updateProfileForm.value);

    let prevUserType = parseInt(this.cookieService.get('uType'));

    this.profileService.updateProfile(this.updateProfileForm.value).subscribe((data: any) => {
      this.successMsg = 'Profile Updated Successfully.';
      console.log(data, "profile data"); 

    if(data != null) {

      this.cookieService.set('uType', data.dbcUserType, null, '/', undefined, true);
      if(data.dbcUserType == 3){
        this.IDMLeadautoInsert();
      }  
      if(data.dbcUserType == 3 || data.dbcUserType == 2){
        $('#liFinancialWellness').show();
        if(this.isShowSpouseInfo == false)
        { 
          this.activeSurveyDot(data.dbcUserType);
        }
      }
      else{
        $('#liFinancialWellness').hide();
      } 
      if(data.dbcUserType == 2){
        console.log("inside type");
        try{
          (window as any).fcWidget.destroy();
        } catch(error){
          console.error('Error occurred while destroying the widget:', error);
        }
      }else if(data.dbcUserType === 3){
        var date = new Date();
        var hour = date.getUTCHours() + 2;
        var day = date.getDay();
        var minutes = date.getUTCMinutes();
        console.log("this is hour", hour); 
        if(day == 1 || day == 2 || day == 3 || day == 4)
        {
          if(hour == 7 && minutes >= 30){
            this.createChat();
          }
          if(hour >=8 && hour <= 18){
            this.createChat();
          }
        }
        if( day == 5){
          if(hour == 7 && minutes >= 30){
            this.createChat();
          }
          if(hour >=8 && hour <= 17){
            this.createChat();
          }
        }
      }
      this.userTypeChangeGAEvent(prevUserType,data.dbcUserType);
      this.updateLoad();
    }

      if(this.isShowSpouseInfo == true) {
        this.updateUserSpouseInfo();
      } 
      this.scrollToTop(); 
     }, (err: HttpErrorResponse) => {
      if(err.status === 409){
        this.successMsgWarning = "Email already exists"
      }else
      {
        this.successMsgWarning = err.message;
      }
      console.log(err, "this is console error");
      
    });
    this.changeDisable = true;
  }

  updateLoad() {
    var campaignJourney=sessionStorage.getItem('CampaignJourney');
    var bannerData=JSON.parse(sessionStorage.getItem('BannerData'));
    var bannerShowTime=JSON.parse(sessionStorage.getItem('BannerShowTime'));

    sessionStorage.clear();

    if(campaignJourney!=null &&  campaignJourney!="null"){
      sessionStorage.setItem('CampaignJourney',campaignJourney);
    }
    if(bannerData!=null){
      sessionStorage.setItem('BannerData',JSON.stringify(bannerData));
    }
    if(bannerShowTime!=null){
      sessionStorage.setItem('BannerShowTime',JSON.stringify(bannerShowTime));
    }

    this.dashboardService.Load().subscribe((data: any) => { 
     sessionStorage.setItem('load',JSON.stringify(data));   
    },(err: HttpErrorResponse) => { });
  }

  private scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  toNumber(val: any) {
    val = val || '';
    const valArr = val.toString().split('');
    const valFiltered = valArr.filter((x: number) => !isNaN(x));
    const valProcessed = valFiltered.join('').trim();
    return valProcessed;
  }

  toDateString(dt) {
    const date = new Date(dt);
    const month = date.getMonth() + 1;
    return date.getFullYear() + '-' + (month.toString().length === 1 ? '0'
    + month.toString() : month) + '-' + (date.getDate().toString().length === 1 ? '0' + date.getDate().toString() : date.getDate());
  }

  changeGrossIncome() {
    if (this.updateProfileForm.value.grossIncome.toString().trim() === 'R') {
      this.updateProfileForm.patchValue({
        grossIncome: ''
      });
    }
    this.changeDisable = false;
  }

  createChat() {
    if ((window as any).fcWidget){
      var userData = this.oauthService.getIdentityClaims();
      console.log("window service__2");
      (window as any).fcWidget.init({
        token: this.env.freshChatToken,
        host: this.env.freshChatHost,
        tags: ["DBclient"],
        externalId: userData['name'],     // user’s id unique to your system
        firstName: userData['given_name'],              // user’s first name
        lastName: userData['family_name']
      });
    }
  }

  changeUserSpouseInfo() {
    if (this.toNumber(this.updateProfileForm.value.spouseCurrentBalance) == '') {
      this.updateProfileForm.patchValue({ spouseCurrentBalance: '' });
    }
    if (this.toNumber(this.updateProfileForm.value.spouseDebtRepayment) == '') {
      this.updateProfileForm.patchValue({ spouseDebtRepayment: '' });
    }
    if (this.toNumber(this.updateProfileForm.value.spouseTakeHomeSalary) == '') {
      this.updateProfileForm.patchValue({ spouseTakeHomeSalary: '' });
    }
    this.changeDisable = false;
  }

  updateUserSpouseInfo() { 

    if (this.toNumber(this.updateProfileForm.value.spouseCurrentBalance) == '') {
      this.updateProfileForm.patchValue({ spouseCurrentBalance: 'R 0' });
    }
    if (this.toNumber(this.updateProfileForm.value.spouseDebtRepayment) == '') {
      this.updateProfileForm.patchValue({ spouseDebtRepayment: 'R 0' });
    }
    if (this.toNumber(this.updateProfileForm.value.spouseTakeHomeSalary) == '') {
      this.updateProfileForm.patchValue({ spouseTakeHomeSalary: 'R 0' });
    }

    if (this.toNumber(this.updateProfileForm.value.spouseCurrentBalance) >= 0) {
      this.updateProfileForm.value.spouseCurrentBalance = parseFloat(this.toNumber(this.updateProfileForm.value.spouseCurrentBalance));
    }
    if (this.toNumber(this.updateProfileForm.value.SpouseDebtRepayment) >= 0) {
      this.updateProfileForm.value.spouseDebtRepayment = parseFloat(this.toNumber(this.updateProfileForm.value.spouseDebtRepayment));
    }
    if (this.toNumber(this.updateProfileForm.value.SpouseTakeHomeSalary) >= 0) {
      this.updateProfileForm.value.spouseTakeHomeSalary = parseFloat(this.toNumber(this.updateProfileForm.value.spouseTakeHomeSalary));
    }

    const param = {
      SpouseCurrentBalance: this.updateProfileForm.value.spouseCurrentBalance,
      SpouseDebtRepayment: this.updateProfileForm.value.spouseDebtRepayment,
      SpouseTakeHomeSalary: this.updateProfileForm.value.spouseTakeHomeSalary
    };
    
    let prevUserType = parseInt(this.cookieService.get('uType'));
    this.profileService.updateUserSpouseInfo(param).subscribe((data: any) => {
      if (data != null) {
        console.log(data);
        if (data.statusCode === 200) {
          this.cookieService.set('uType', data.dbcType, null, '/', undefined, true);
          let type = parseInt(this.cookieService.get('uType'));
          if(type == 3 || type == 2){
          this.activeSurveyDot(type);
          }
          this.userTypeChangeGAEvent(prevUserType,type);
        } 
      } 
    },
    (err: HttpErrorResponse) => { });

  }
  activeSurveyDot(type) {
    var data = sessionStorage.getItem('loadDebtConsolidation');
    if (data != null) {
      this.BindActiveSurveyDot(type,JSON.parse(data));
      return;
    }
    this.userService.GetDbcUserSolutionInfo({ IsSolution: 'yes' }).subscribe((data: any) => {
      this.BindActiveSurveyDot(type,data);
    });
  }

  BindActiveSurveyDot(type,data:any) {
    sessionStorage.removeItem('loadDebtConsolidation');
    sessionStorage.setItem('loadDebtConsolidation', JSON.stringify(data));
      $('#liFinancialWellness').show();
      if (data != null) {
        if (!data.isSolution) {
          this.isSolution = false;
        } else {
          this.isSolution = true;
        }
        this.getSurveyAnswered(type) 
      }
    
    }
      getSurveyAnswered(type) {

        var loaddata = sessionStorage.getItem('loadSurveyAnswered');
        if (loaddata != null) {
          this.BindSurveyAnswered(type,JSON.parse(loaddata));
          return;
        }
        this.financialWellnessService.getSurveyAnswered().subscribe((data: any) => {
          this.BindSurveyAnswered(type,data);
    
        }, (err: HttpErrorResponse) => {
        });
      }

      BindSurveyAnswered(type,dataSurvey) {
        sessionStorage.removeItem('loadSurveyAnswered');
        sessionStorage.setItem('loadSurveyAnswered', JSON.stringify(dataSurvey));
       
          if (type === 3) {
            if (dataSurvey.answered === dataSurvey.totalQuestions && this.isSolution) {
              $('#liFinancialWellness').removeClass('orngemenu');
              $('#liFinancialWellness').addClass('orngemenutext');
              this.cookieService.set('setDotClass', 'orngemenutext', null, '/', undefined, true);
            } else {
              $('#liFinancialWellness').removeClass('orngemenutext');
              $('#liFinancialWellness').addClass('orngemenu');
              this.cookieService.set('setDotClass', 'orngemenu', null, '/', undefined, true);
            }
          }

        if (type === 2) {
          $('#liFinancialWellness').removeClass('orngemenutext');
          $('#liFinancialWellness').removeClass('orngemenu');
          $('#navbarToggler').removeClass('navbar-togglertext p-0');
          $('#navbarToggler').removeClass('navbar-toggler');
          $('#navbarToggler').removeClass('p-0');
        }  
  }

  userTypeChangeGAEvent(prevUserType,newUserType)
  {
    if (prevUserType!==newUserType)
    {
    let userId=this.cookieService.get('userId');
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'userId': userId,
      'event': 'UserTypeChange'
    });
    console.log('event : UserTypeChange - ' + newUserType);
    }
  }

  IDMLeadautoInsert() {
    this.communicationService.IDMLeadautoInsert().subscribe((data: any) => { });
  }

}
