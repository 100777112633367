
<!-- Header HTML -->
<header>
    <!-- Fixed navbar -->
    <nav class="navbar navbar-expand-md nav-cutom bg-white">
        <div class="d-flex w-100 align-items-center justify-content-between px-md-5 p-md-0">
            <a id="anchor-RedirectToDebtbusters-Smartcents" class="navbar-brand p-0" href="https://www.debtbusters.co.za/" target="_blank">
                <img src="../../assets/images/db-logo.png" alt="">
            </a>
            <div class="signblock ms-auto">
                <img src="../../assets/images/green_overlays.png" alt="" class="overlay">
                <a id="anchor-Login-Smartcents" class="loglink" href="javascript:void(0)" (click)="azureAdlogin('')" *ngIf="!loginButtonDisable">Log in</a>
            </div>
        </div>
        
    </nav>
</header>





<!--"mobileviewsecondstepbg"  class only for 2nd and autoreg-->
<section (window:resize)="onResize($event)" class=" register-hld min-h register" [ngClass]="isOtpScreen === true ? 'otpscreen' : '' ">
    <div class="registerholder px-md-5">
        <div class="p-md-0">
            <div class="d-flex needflex flex-wrap justify-content-between">
                <div class="col-lg-8 col-xl-7 col-md-12 px-0" [ngClass]="isOtpScreen === true ? 'onlyotp' : '' ">
                    <div class="lgtbg" [ngClass]="isOtpScreen === true ? 'd-none' : '' ">
                        <div class="mobilebgreg px-md-0 px-3" id="bgcontrol">
                            <div class="topcap">
                                <h1 class="page-caption">Log in to our new platform!</h1>
                            </div>
                            <div class="frmhold" *ngIf="!signupSuccess">
                                 <form [formGroup]="autoRegisterForm" (ngSubmit)="autoRegister()" id="frmAutoRegister">
                                    <div class="custom-row row mb-50 required auto-register" style="display: none;">
                                        <div class="col">
                                            <input id="AutoRegisterPassword" type="password" class="form-control frm-fld pwfld"
                                                placeholder="Password" formControlName="Password" [(ngModel)]="password"
                                                [closeDelay]="1000" [ngbPopover]="popContent" [type]="pwrdTextType ? 'text' : 'password'" 
                                                popoverTitle="Password Requirements" triggers="click:blur focus">
                                            <span [ngClass]="classPwrd === true ? 'showPw' : '' " (click)="pwrdToggle($event)" class="eyeIcon" ></span>
                                            <label class="d-none" for="Password">Password</label>
                                            <app-password-strength-bar *ngIf="password"
                                                [passwordToCheck]="password" [barLabel]="'Password strength:'"
                                                class="psb"></app-password-strength-bar>
                                            <div *ngIf="autoRegisterFormControls.Password.touched && autoRegisterFormControls.Password.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="autoRegisterFormControls.Password.errors.required">Password is
                                                    required</div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <input id="AutoRegisterConfirmPassword" type="password"
                                                class="form-control frm-fld pwfld" placeholder="Confirm Password" [type]="conpwrdTextType ? 'text' : 'password'" 
                                                formControlName="ConfirmPassword">
                                                <span [ngClass]="classConfirmPwrd === true ? 'showPw' : '' " (click)="confirmpwrdToggle($event)" class="eyeIcon" ></span>    
                                      
                                            <label class="d-none" for="ConfirmPassword">Confirm Password</label>
                                            <div *ngIf="autoRegisterFormControls.ConfirmPassword.touched && autoRegisterFormControls.ConfirmPassword.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="autoRegisterFormControls.ConfirmPassword.errors.required">
                                                    Confirm password is
                                                    required</div>
                                                <div *ngIf="autoRegisterFormControls.ConfirmPassword.errors.mustMatch">
                                                    Password must match</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-row row mb-28 required auto-register" style="display: none;">
                                        <div class="col-md-12 d-flex align-items-center mobile-spcing">
                                            
                                            <label class="switch">
                                                <input type="checkbox" id="input-AcceptTerms-Smartcents" formControlName="AcceptTerms">
                                                <span class="slider round" data-off="No" data-on="Yes"></span>
                                            </label>
                                            <span class="ps-2 cond-text"> I have read and agree to the <a class="text-underline"
                                                    href="/terms" id="anchor-Terms-Smartcents" target="_blank">Terms and
                                                    Conditions</a> and <a class="text-underline" href="/policy" id="anchor-PrivacyPolicy-Smartcents"
                                                    target="_blank">Privacy Policy</a></span>
                                        </div>
                                        <div class="col-md-12 termerror">
                                            <div *ngIf="autoRegisterFormControls.AcceptTerms.touched && autoRegisterFormControls.AcceptTerms.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="autoRegisterFormControls.AcceptTerms.errors.pattern"> Terms and
                                                    Conditions is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-row row">
                                        <div class="col d-flex flex-wrap align-items-center justify-content-between">
                                            <button type="submit" class="btn btn-custom mb-md-0 mb-"
                                                id="auto-register-button" style="display: none;"
                                                name="btnRegisterRegisterPage">Sign Up</button>
                                        </div>
                                    </div>
                                    <ng-template #popContent>
                                        Be at least 8 characters <br>
                                        At least one number<br>
                                        At least one lowercase letter <br>
                                        At least one uppercase letter<br>
                                        At least one special character
                                    </ng-template>
                                </form> 
                                <form [formGroup]="registerForm" id="genregister" (ngSubmit)="register()">
                                    <div *ngIf="errorMsg!='' && errorMsg!=null" class="alert alert-danger" role="alert"
                                        [innerHTML]="errorMsg"></div>
                                    <div class="custom-row row mb-28 required dbc-verify-no" style="display: none;">
                                        <div class="col">
                                            <input id="FirstName" type="text" class="form-control frm-fld"
                                                placeholder="First Name" formControlName="FirstName"
                                                name-field-hyphen-space>
                                            <label class="d-none" for="FirstName">First Name</label>
                                            <div *ngIf="registerFormControls.FirstName.touched && registerFormControls.FirstName.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="registerFormControls.FirstName.errors.required">First name is
                                                    required</div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <input id="Surname" type="text" class="form-control frm-fld"
                                                formControlName="Surname" placeholder="Surname" name-field-with-space>
                                            <label class="d-none" for="Surname">Surname</label>
                                            <div *ngIf="registerFormControls.Surname.touched && registerFormControls.Surname.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="registerFormControls.Surname.errors.required">Surname is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-row row mb-28 required dbc-verify-no" style="display: none;">
                                        <div class="col">
                                            <input id="PhoneNumber" type="tel" class="form-control frm-fld"
                                                placeholder="Cell Phone Number" formControlName="PhoneNumber" numeric-field
                                                maxlength="10" (keyup)="cellNumberErrorMsg=''">
                                            <label class="d-none" for="PhoneNumber">Cell Phone Number</label>
                                            <div *ngIf="(registerFormControls.PhoneNumber.touched && registerFormControls.PhoneNumber.errors) || cellNumberErrorMsg"
                                                class="invalid-feedback">
                                                <div *ngIf="registerFormControls.PhoneNumber.errors?.required">Cell phone
                                                    number is required
                                                </div>
                                                <div *ngIf="registerFormControls.PhoneNumber.errors?.minlength">Cell phone
                                                    number must be 10
                                                    digits long</div>
                                                <div *ngIf="registerFormControls.PhoneNumber.errors?.isValidMobile">Please
                                                    enter your valid
                                                    10 digits cell
                                                    phone number</div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <input id="Email" type="text" class="form-control frm-fld"
                                                placeholder="Email Address" formControlName="Email">
                                            <label class="d-none" for="Email">Email Address</label>
                                            <div *ngIf="registerFormControls.Email.touched && registerFormControls.Email.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="registerFormControls.Email.errors.required">Email address is
                                                    required</div>
                                                <div *ngIf="registerFormControls.Email.errors?.isValidEmail">Please enter a
                                                    valid email
                                                    address</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="custom-row idrow d-flex mb-28 required" id="divIdNumber">
                                        <div class="col">
                                            <input id="IdNumber" type="tel" class="form-control frm-fld"
                                                placeholder="ID Number" formControlName="IdNumber" numeric-field
                                                maxlength="13">
                                            <label class="d-none" for="IdNumber">ID Number</label>
                                             <div *ngIf="registerFormControls.IdNumber.touched && registerFormControls.IdNumber.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="registerFormControls.IdNumber.errors.required">ID Number is
                                                    required</div>
                                                <div *ngIf="registerFormControls.IdNumber.errors.minlength">ID Number must
                                                    be 13 digits long
                                                </div>
                                                <div *ngIf="registerFormControls.IdNumber.errors.isValidIdNumber">Please
                                                    enter your valid 13
                                                    digits South
                                                    African ID Number</div>
                                            </div> 
                                        </div>
                                        <div class="col-">
                                            <button type="button" (click)="dbcVerify()"
                                                class="btn btn-custom mb-md-0 spacetop"
                                                id="btn-partical-register" name="btnRegisterRegisterPage">Sign Up</button>

                                        </div>
                                    </div>

                                     <div class="custom-row row mb-50 required dbc-verify-yes" style="display: none;">
                                        <div class="col position-relative">
                                            <input id="Password" type="password" class="form-control frm-fld pwfld"
                                                placeholder="Password" formControlName="Password" [(ngModel)]="password"
                                                [closeDelay]="1000" [ngbPopover]="popContent"  [type]="pwrdTextType ? 'text' : 'password'" 
                                                popoverTitle="Password Requirements" triggers="click:blur focus">
                                            <span [ngClass]="classPwrd === true ? 'showPw' : '' " (click)="pwrdToggle($event)" class="eyeIcon" ></span>
                                            <label class="d-none" for="Password">Password</label>
                                            <app-password-strength-bar *ngIf="password" [passwordToCheck]="password"
                                                [barLabel]="'Password strength:'" class="psb"></app-password-strength-bar>
                                            <div *ngIf="registerFormControls.Password.touched && registerFormControls.Password.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="registerFormControls.Password.errors.required">Password is
                                                    required</div>
                                            </div>
                                        </div>
                                        <div class="col position-relative">
                                            <input id="ConfirmPassword" type="password" class="form-control frm-fld pwfld" [type]="conpwrdTextType ? 'text' : 'password'" 
                                                placeholder="Confirm Password" formControlName="ConfirmPassword">
                                            <span [ngClass]="classConfirmPwrd === true ? 'showPw' : '' " (click)="confirmpwrdToggle($event)" class="eyeIcon" ></span>    
                                            <label class="d-none" for="ConfirmPassword">Confirm Password</label>
                                            
                                            <div *ngIf="registerFormControls.ConfirmPassword.touched && registerFormControls.ConfirmPassword.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="registerFormControls.ConfirmPassword.errors.required">Confirm
                                                    password is
                                                    required</div>
                                                <div *ngIf="registerFormControls.ConfirmPassword.errors.mustMatch">Password
                                                    must match</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-row row mb-28 required dbc-verify-yes" style="display: none;">
                                        <div class="col-md-12 d-flex align-items-center mobile-spcing">
                                            <label class="switch">
                                                <input type="checkbox" id="input-AcceptTerms1-Smartcents" formControlName="AcceptTerms">
                                                <span class="slider round" data-off="No" data-on="Yes"></span>
                                            </label>
                                            <span class="ps-2 cond-text"> I have read and agree to the <a class="text-underline"
                                                    href="/terms" id="anchor-TermsAndConditions-Smartcents" target="_blank">Terms and
                                                    Conditions</a> and <a class="text-underline" href="/policy" id="anchor-PrivacyPolicy1-Smartcents"
                                                    target="_blank">Privacy Policy</a></span>
                                        </div>
                                        <div class="col-md-12 termerror">
                                            <div *ngIf="registerFormControls.AcceptTerms.touched && registerFormControls.AcceptTerms.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="registerFormControls.AcceptTerms.errors.pattern"> Terms and
                                                    Conditions is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="custom-row row">
                                        <div class="col d-flex flex-wrap align-items-center justify-content-between">
                                            <button type="submit" class="btn btn-custom mb-md-0 mb-3"
                                                id="btn-register" style="display: none;" name="btnRegisterRegisterPage">Sign
                                                Up</button>

                                            <div class="btn-responsive login-button">
                                                <span class="text-md text-dark d-md-inline-block pe-1 login-button">Already have an account?</span>
                                                <a href="javascript:void(0)" id="anchor-Login1-Smartcents" (click)="azureAdlogin('')"
                                                    class="login-link">Log in </a>
                                            </div>

                                        </div>
                                    </div> 
                                    <ng-template #popContent>
                                        Be at least 8 characters <br>
                                        At least one number<br>
                                        At least one lowercase letter <br>
                                        At least one uppercase letter<br>
                                        At least one special character
                                    </ng-template> 


                                </form>
                            </div>
                        
                            
                        </div>

                        <div class="d-flex flex-wrap w-100 justify-content-between ">
                            <div class="col-lg-12 col-md-12 px-md-0 getfeatures mt-md-3 px-3 mb-3">
                                You’ll be able to do everything you did <br class="d-md-block"/> on the Smartcents portal and 
                                <strong>more.</strong>
                            </div>
                            
                        </div>
                    </div>

                    <!---otp screen-->
                    <div class="col-md-6 py-5 mx-auto" [ngClass]="isOtpScreen === false ? 'd-none' : '' ">
                        <a class="otpScen" href="javascript:void(0)" id="anchor-CloseOtpScreen-Smartcents" (click)="closeOtpScreen()">
                            <svg class="otpar" width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="back" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z"/></defs><use fill="#60a845" fill-rule="nonzero" xlink:href="#back"/></svg> Back </a>

                        <div class="pt-4 px-3 px-md-0">
                            <div *ngIf="otpErrorMsg!=''" class="alert alert-danger" role="alert">
                                {{otpErrorMsg}}
                            </div>
                            <div *ngIf="successMsg!=''" class="alert alert-success" role="alert">
                                {{successMsg}}
                            </div>
                            <div *ngIf="successMsgWithPipe == true" class="alert alert-success" role="alert">
                                Your session will expire in {{counter | formatTime }}.
                            </div>
                        </div>

                        <div class="form-group fullwidth" >
                            <div class="text-center">
                                <i>
                                    <img src="../../assets/images/otp-icon.png" width="100px" alt="" />
                                </i>
                                <h4 class="modal-title ftsz" id="modal-basic-title">
                                    Enter OTP
                                </h4>
                                <p class="text-charcl">A 6-digit code has been sent to {{maskedPhoneNumber}}.</p>
                            </div>
                        </div>
                        <div class="form-group fullwidth">
                            <div class="form-field text-center">
                                    <div class="passcode-wrapper">
                                        <input id="otp1" type="number" maxlength="1" oninput="this.value = this.valueAsNumber" (keyup)="onKeyUpEvent($event, 1)" (focus)="onFocusEvent(1)">
                                        <input id="otp2" type="number" maxlength="1" oninput="this.value = this.valueAsNumber" (keyup)="onKeyUpEvent($event, 2)" (focus)="onFocusEvent(2)">
                                        <input id="otp3" type="number" maxlength="1" oninput="this.value = this.valueAsNumber" (keyup)="onKeyUpEvent($event, 3)" (focus)="onFocusEvent(3)">
                                        <input id="otp4" type="number" maxlength="1" oninput="this.value = this.valueAsNumber" (keyup)="onKeyUpEvent($event, 4)" (focus)="onFocusEvent(4)">
                                        <input id="otp5" type="number" maxlength="1" oninput="this.value = this.valueAsNumber" (keyup)="onKeyUpEvent($event, 5)" (focus)="onFocusEvent(5)">
                                        <input id="otp6" type="number" maxlength="1" oninput="this.value = this.valueAsNumber" (keyup)="onKeyUpEvent($event, 6)" (focus)="onFocusEvent(6)">
                                    </div>
                                <div *ngIf="otpFormControls.Otp.touched && otpFormControls.Otp.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="otpFormControls.Otp.errors.required">OTP is required</div>
                                    <div *ngIf="otpFormControls.Otp.errors.minlength">OTP must be 6 digits</div>
                                </div>
                            </div>
    
                        </div>

                        <div class="modal-footer flex-wrap justify-content-center px-3 px-md-0 pb-5 border-0 pt-0" >
                            <div class="d-flex justify-content-center flex-wrap" *ngIf="hideResendOTPButton === false">
                                <p class="text-center mt-2 w-100 text-charcl">Didn't receive the OTP?  
                                    <button id="btn-ResendOtp-Smartcents" type="button" class="resendotpbtn" [disabled]="disableOTPSendButton == true" (click)="sendOtp(1)">Resend OTP</button>
                                    <span *ngIf="secondsLeft > 0"> in {{secondsLeft}}s.</span>
                                </p>   
                            </div>
                            <!--type -->
                            <ng-container *ngIf="isDbcYes">
                                <p class="w-100 pt-3">
                                    In the event that we need to update the contact details displayed here, please send us an
                                    email to <a id="anchor-MailToContactDetails-Smartcents" class="nounderline" href="mailto:contactdetails@debtbusters.co.za">contactdetails@debtbusters.co.za</a> with
                                    your full name and your correct phone number, and we will call you back.
                                </p>
                            </ng-container>
                        </div>

                    </div>

                </div>
                
            </div>

        </div>


        <!-- Reg HTML Start -->



        <div class="login-hld" *ngIf="signupSuccess" style="height: 200px;">
            <h2>
                Registration Successful, <a id="anchor-Login2-Smartcents" href="javascript:void(0)" (click)="azureAdlogin('')">click here to login</a>
            </h2>
        </div>
        <!-- REG HTML Start -->

        <ng-template #captureIncomeModel let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Enter Your Take Home Salary</h4>
                <button name="btntCloseTakeHomeSalaryModelRegisterPage" type="button" class="close" aria-label="Close" id="btn-CloseTakeHomeSalaryModel-Smartcents"
                    (click)="openDashboardPage()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form class="capture-income-form" [formGroup]="captureIncomeForm" (ngSubmit)="updateIncome()">
                <div class="modal-body">
                    <div *ngIf="captureIncomeErrorMsg!=''" class="alert alert-danger" role="alert">
                        {{captureIncomeErrorMsg}}
                    </div>
                    <div class="form-group fullwidth">
                        <div class="form-field  gi-hld">
                            <input class="form-control" type="text" appCurrencyMask placeholder="R 20,000"
                                formControlName="GrossIncome" id="currency-number" (blur)="changeGrossIncome()">
                            <label for="GrossIncome">Take Home Salary</label>
                            <div *ngIf="captureIncomeFormControls.GrossIncome.touched && captureIncomeFormControls.GrossIncome.errors"
                                class="invalid-feedback">
                                <div *ngIf="captureIncomeFormControls.GrossIncome.errors.required">Take Home Salary is
                                    required</div>
                                <div *ngIf="captureIncomeFormControls.GrossIncome.errors.min">Invalid Take Home Salary
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button name="btntSkipTakeHomeSalaryRegisterPage" type="button" class="btn-border" id="btn-SkipTakeHomeSalary-Smartcents"
                        (click)="openDashboardPage()">Skip</button>
                    <button name="btntSubmitTakeHomeSalaryRegisterPage" id="btn-SubmitTakeHomeSalary-Smartcents" type="submit" class=" btn">Submit</button>
                </div>
            </form>
        </ng-template>


        <ng-template #securityQuestionsModel let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title"> Answer the following security questions. </h4>
            </div>
            <div class="modal-body">
                <div *ngIf="securityQuestionErrorMsg!=''" class="alert alert-danger" role="alert">
                    {{securityQuestionErrorMsg}}
                </div>
                <div id="scrollTop" class="form-group fullwidth" style="height: 400px;overflow-y: auto;">
                    <div class="form-row form-group" *ngFor="let question of securityQuestionList; let i=index">
                        <div class="col-md-12">
                            {{i+1}}: {{question.question}}
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-md-6" *ngFor="let answer of question.securityAnswer; let ii=index">
                            <label><input name="option-{{i}}" [(ngModel)]="question.securityAnswerId" id="input-SecurityQuestionRadioBox-Smartcents"
                                    [value]="answer.answerId" type="radio"> {{answer.answer}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button id="question-submit" name="btntSubmitSecurityQuestionRegisterPage" type="submit" class="btn" id="btn-SubmitSecurityQuestion-Smartcents"
                    (click)="validateQuestions()">Submit</button>
                <button *ngIf="cancelQuestion" type="button" class="btn-border" data-dismiss="modal" id="btn-CancelSecurityQuestion-Smartcents"
                    (click)="hideSecurityQuestion()">Close</button>
            </div>
        </ng-template>
    </div>
</section>
<!-- Banner HTML End -->


<!-- Footer HTML Start -->
<footer class="footr b5containeradjust">
    <div class="container">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
            
            <div class="col- pe-md-2 pe-lg-0">
                <h5 class="cont-caption">Contact Us</h5>
                <ul class="cont-list">
                    <li>
                        <span class="line-break"> 4th Floor, Mutual Park,</span> Jan Smuts Drive, Pinelands, <br /> Cape Town, 7405 <br />
                        <a id="anchor-MailToDebtbusters-Smartcents" class="blackLink" href="mailto:info@debtbusters.co.za">info@debtbusters.co.za</a>
                    </li>
                </ul>
            </div>
            <div class="col- pe-md-2 pe-lg-0">
                <h5 class="cont-caption">Operating Hours:</h5>
                <ul class="cont-list">
                    <li>
                        Mon-Thu: 07:00 - 21:00
                    </li>
                    <li>
                        Fri: 07:00 - 18:00
                    </li>
                    <li>
                        Sat: 09:00 - 12:30
                    </li>
                    <li class="toggle-devices d-none">a</li>
                </ul>
            </div>
            <div class="col- d-flex py-md-2 border-left-black ps-md-3 align-items-center">
                <i class="phicon">
                    <svg id="Layer_1_1_" style="enable-background:new 0 0 16 16;" version="1.1" viewBox="0 0 16 16"
                        xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path
                            d="M5,16h6c1.105,0,2-0.895,2-2V2c0-1.105-0.895-2-2-2H5C3.895,0,3,0.895,3,2v12C3,15.105,3.895,16,5,16z M4,2h8v12H4V2z" />
                    </svg>
                </i>
                <div>
                    <h5 class="cont-caption mb-0 lightw">Call our experts now</h5>
                    <ul class="cont-list m-0">
                        <li>
                            <a id="anchor-CellNumber-Smartcents" class="calllg" href="tel:0861365910">0861 365 910</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col- text-center">
                <img src="../../assets/images/r.png" alt="" class="ricon" />
                
            </div>
            <div class="col-">
                <img src="../../assets/images/ndca.png" class="ndcaimg" alt="" />
            </div>
        </div>
    </div>
</footer>

<!-- Footer HTML End -->
