<section class="questionnaire">
    <div class="questionnaire-banner b5containeradjust">
        <div class="container">
            <div class="d-flex row flex-wrap align-items-center">
                <div class="col-md-9 col-8 no-space-right">
                    <h1 class="page-caption text-white m-0">Credit Behaviour Questionnaire </h1>
                </div>
                <div class="col-md-3 text-end col-4">
                    <i class="questionnaire-banner-icon">
                        <img src="/assets/images/puzzle.png" alt="">
                    </i>
                </div>
            </div>
        </div>
    </div>

    <div class="questionnaire-cont b5containeradjust">
        <div class="container">
            <nav aria-label="breadcrumb bg-transparent">
                <ol class="breadcrumb bg-transparent p-0 question-bdcrumb">
                  <li class="breadcrumb-item pick active" ><a id="anchor-MyGoals-Questionnaire" routerLink="/portal/questionnaire">my goals…</a> </li>
                </ol>
              </nav>
            <div class="d-flex row flex-wrap align-items-stretch">
                <div class="col-lg-4 col-md-4 col-6 d-flex flex-wrap align-items-stretch">
                    <a id="anchor-EmergencyFund-Questionnaire" href="javascript:void(0)" routerLink="/portal/questionnaire-step" class="goalset">Emergency fund</a>
                </div>
                <div class="col-lg-4 col-md-4 col-6 d-flex flex-wrap align-items-stretch">
                    <a id="anchor-BuyHouse-Questionnaire" href="javascript:void(0)" class="goalset">Buy a house</a>
                </div>
                <div class="col-lg-4 col-md-4 col-6 d-flex flex-wrap align-items-stretch">
                    <a id="anchor-RetireComfortably-Questionnaire" href="javascript:void(0)" class="goalset">Retire comfortably</a>
                </div>

                <div class="col-lg-4 col-md-4 col-6 d-flex flex-wrap align-items-stretch">
                    <a id="anchor-Holidays-Questionnaire" href="javascript:void(0)" class="goalset">Holidays</a>
                </div>
                <div class="col-lg-4 col-md-4 col-6 d-flex flex-wrap align-items-stretch">
                    <a id="anchor-Education-Questionnaire" href="javascript:void(0)" class="goalset">Education</a>
                </div>
                <div class="col-lg-4 col-md-4 col-6 d-flex flex-wrap align-items-stretch">
                    <a id="anchor-StartBusiness-Questionnaire" href="javascript:void(0)" class="goalset">Start a business</a>
                </div>

                <div class="col-lg-4 col-md-4 col-6 d-flex flex-wrap align-items-stretch">
                    <a id="anchor-BuyCar-Questionnaire" href="javascript:void(0)" class="goalset">Buy a car</a>
                </div>
                <div class="col-lg-4 col-md-4 col-6 d-flex flex-wrap align-items-stretch">
                    <a id="anchor-PayoffDebt-Questionnaire" href="javascript:void(0)" class="goalset">Payoff debt</a>
                </div>
               
            </div>
        </div>
    </div>
</section>
