import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css'],
  host: {'class': 'd-flex flex-column h-100'}
})
export class QuestionnaireComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
