import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http'; 
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SecurityQuestionService } from '../services/security-question.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { LocationStrategy, PlatformLocation } from '@angular/common'; 
import { NavigationStart, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
 
import { RegistrationRoutingService } from '../services/registration-routing.service'; 
import { SharedModule } from '../shared/shared.module';
import { UserService } from '../services/user.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsService } from '../services/appinsights.service';
import { RegistrationService } from '../services/registration.service';
@Component({
  selector: 'app-security-question',
  templateUrl: './security-question.component.html',
  styleUrls: ['./security-question.component.css'],
})
export class SecurityQuestionComponent implements OnInit {
  @ViewChild('backButtonError', { static: false }) backButtonError: ElementRef;
  modalOptions: NgbModalOptions;

  securityQuestionList = [];
  errorMsg = ''; securityQuestionErrorMsg = '';
  securityQuestionAttempt = 1;
  successMsg = '';
  time;
  securityQuestion: any;
  questionIndex = 0;
  totalQuestion = 5;
  correctAnswarId = 0;
  isActivePrevNext = false;
  isbuttonSubmit = false;
  isDefalutMessage = true;
  WebSource = '';
  IsBureauDown = false;
  allowForAnotherPage = false;
  env = environment;
  azureTableStorageData: any;
  startSecurityQuestions: any = null;
  securityQuestionsStatus: boolean = null;
  stream2Method: string = null;
  securityQuestionsSetCompleted: number  =0;


  

  appInsights: ApplicationInsights;
  
  constructor(
    private spinner: NgxSpinnerService,
    private securityQuestionService: SecurityQuestionService,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private location: LocationStrategy,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private registrationRouting: RegistrationRoutingService,
    private sharedModule: SharedModule,
    private userService: UserService,
    private appInsightsService: AppInsightsService,
    private registrationService: RegistrationService
  ) {
    this.appInsights = appInsightsService.appInsightsObj();
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      this.isDefalutMessage = false;
      if (this.allowForAnotherPage == false) {
        history.pushState(null, null, window.location.href);
        this.modalService.open(this.backButtonError, this.modalOptions);
      }
    });

  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event: any) {
    if (this.allowForAnotherPage == false) {
      event.preventDefault();
      return false;
    }

  }

  ngOnInit() {
    
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && (params.websource || params.WebSource || params.WEBSOURCE || params.webSource)) {
        this.WebSource = params.websource || params.WebSource || params.WEBSOURCE || params.webSource;
      }
    });

    var onLoadCheck = localStorage.getItem('OnLoadCheck');
    var tempToken = localStorage.getItem('tempToken');

    if (onLoadCheck == '' || onLoadCheck == null && (tempToken != null && tempToken != '')) {
      localStorage.setItem('OnLoadCheck', '1');
      this.openSecurityQuestions(1, () => {
        this.startTimeOut();
      });

    } else {
      this.removeLocalStorage();
      this.doNavigation();
      return;
    }

    // this.openSecurityQuestions(1, () => {
    //   this.startTimeOut();
    // });\
    this.azureTableStorageData =  JSON.parse(sessionStorage.getItem("AzureTableStorageData"));
    
  }

  retrySecurityQuestion() {
    if (this.securityQuestionAttempt === 1) {
      this.openSecurityQuestions(1, null);
    } else if (this.securityQuestionAttempt === 2) {
      this.openSecurityQuestions(2, null);
    }
  }

  stopNavigation() {
    this.modalService.dismissAll();
    this.isDefalutMessage = true;
  }

  doNavigation() {
    this.allowForAnotherPage = true;
    this.modalService.dismissAll();
    this.removeLocalStorage();
    this.router.navigateByUrl('/register' + this.registrationRouting.queryStringParams());
  }

  securityQuestionVerficationFailed() {
    this.hideSecurityQuestion();
  }

  openSecurityQuestions(part, callback) {
    this.correctAnswarId = 0;
    this.questionIndex = 0;
    this.isbuttonSubmit = false;
    if (part === 1)
      this.getSecurityQuestions();
    else if (part === 2)
      this.getSecurityQuestionsPart2();
    this.securityQuestionPrevNext('');

    if (callback) {
      callback();
    }
  }

  securityQuestionPrevNext(nextprev) {
    if (this.securityQuestionList.length > 0) {
      this.correctAnswarId = this.questionIndex >= 0 ? this.securityQuestionList[this.questionIndex].securityAnswerId : 0;
      if (nextprev == 'next') {
        if (this.correctAnswarId === 0 || this.correctAnswarId == null || this.questionIndex >= this.totalQuestion - 1) return;
        //this.securityQuestionList[this.questionIndex].securityAnswerId=this.correctAnswarId;
        this.correctAnswarId = 0;
        this.questionIndex = this.questionIndex + 1;
      }
      else if (nextprev == 'prev') {
        if (this.questionIndex <= 0) return;
        this.questionIndex = this.questionIndex - 1;
      }
    }
  }

  getSequrityList() {
    console.log(this.securityQuestionList);
  }

  updateQuestionAttemt() {

    const totalQuestionAttemt = this.securityQuestionList.filter(
      question => question.securityAnswerId !== 0 && question.securityAnswerId != null);
    console.log(this.securityQuestionList);
    this.securityQuestionErrorMsg = '';
    if (totalQuestionAttemt && totalQuestionAttemt.length >= this.totalQuestion)
      this.isbuttonSubmit = true;
    this.securityQuestionPrevNext('next');
  }

  getSecurityQuestions() {
    const param = { Attempt: 1, IsAskSecurityQuestion: environment.askSecurityQuestion };
    console.log("IsAskSecurityQuestion : ", environment.askSecurityQuestion);
    this.startSecurityQuestions = new Date();
    this.stream2Method = "Security Questions";
    this.registrationDetails(null);
    this.securityQuestionService.getSecurityQuestionsNew(param).subscribe((data: any) => {
      console.log(data);
      this.securityQuestionList = data;
      this.totalQuestion = this.securityQuestionList.length;

      if (data.length === 0) {
        this.IsBureauDown = true;
      } else {
        this.IsBureauDown = false;
      }

    },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.IsBureauDown = true;
        if (err.status === 501) {

          this.securityQuestionErrorMsg = err.error;

          this.securityQuestionVerficationFailed();

        }
      });
  }

  getSecurityQuestionsPart2() {
    this.isDefalutMessage = true;
    this.securityQuestionErrorMsg = 'We were unable to verify you based on your previous answers. Please try again.';
    this.startSecurityQuestions = new Date();
    this.stream2Method = "Security Questions";
    this.registrationDetails(null);
    const param = { SecurityQuestion: this.securityQuestionList, Attempt: 2, IsAskSecurityQuestion: environment.askSecurityQuestion };
    this.securityQuestionService.getSecurityQuestionsNew(param).subscribe((data: any) => {
      console.log(data);
      this.securityQuestionList = data;
      this.totalQuestion = this.securityQuestionList.length;

      if (data.length === 0) {
        this.IsBureauDown = true;
      } else {
        this.IsBureauDown = false;
      }

    },
      (err: HttpErrorResponse) => {
        this.IsBureauDown = true;
        this.securityQuestionErrorMsg = err.error;
        localStorage.setItem('errorMsg', err.error);
      });
  }

  dataLayerCallback(callback: boolean) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'New User Register Successfully'
    });

    try{
      var id = sessionStorage.getItem("IdMumber");
      this.appInsightsService.appInsights.trackEvent({
        name: 'GAEvent',
        properties: {
          EventName:  "New User Register Successfully",
          Type: "GA",
          IdNumber: id,
          ValidatingAfter: "SecurityQuestions",
          SubmissionId: this.cookieService.get('submissionId'),
        }
      });

      sessionStorage.removeItem("IdMumber");
    }catch(e){

    }
  }

  validateQuestions() {
    this.isDefalutMessage = false;
    this.securityQuestionErrorMsg = '';
    

    const notMarkedQuestions = this.securityQuestionList.filter(
      question => question.securityAnswerId === 0 || question.securityAnswerId == null);

    if (notMarkedQuestions && notMarkedQuestions.length > 0) {
      this.securityQuestionErrorMsg = 'Please attempt all the questions';
      return;
    }

    const param = { SecurityQuestion: this.securityQuestionList, Attempt: this.securityQuestionAttempt, IsAskSecurityQuestion: environment.askSecurityQuestion };
    this.securityQuestionService.validateSecurityQuestions(param).subscribe((data: any) => {
    this.securityQuestionsStatus = true;
    this.securityQuestionsSetCompleted = this.securityQuestionsSetCompleted + 1;
    this.registrationDetails(()=>{
      this.dataLayerCallback(true);
      this.sharedModule.sendAppInsights(true);
      this.login();
    });
     
    },
      (err: HttpErrorResponse) => {
        this.securityQuestionsSetCompleted = this.securityQuestionsSetCompleted + 1;
        this.securityQuestionsStatus = false;
        this.registrationDetails(() => {
          if (err.status === 422) {
            if (this.securityQuestionAttempt === 1) {
              this.securityQuestionAttempt = 2;
              this.openSecurityQuestions(2, null);
            } else {
              this.securityQuestionsStatus = false;
              this.registrationDetails(()=>{
                this.securityQuestionVerficationFailed();
                this.securityQuestionErrorMsg = err.error;
                localStorage.setItem('errorMsg', err.error);
              });
            }
          } else {
  
            this.securityQuestionErrorMsg = err.error;
            localStorage.setItem('errorMsg', err.error);
          }
        });

      });
  }

  login() {
    this.allowForAnotherPage = true;
    this.isDefalutMessage = false;
    localStorage.removeItem('loginFired');
    this.spinner.show();
    this.azureAdlogin('User Registered Successfully');
  }

  azureAdlogin(message) {
    this.removeLocalStorage();
    let submissionId = '';
    if (this.cookieService.get('submissionId')) {
      submissionId = this.cookieService.get('submissionId');
    }

    this.oauthService.initLoginFlow('', {
      submissionId, ccAppUrl: window.location.origin,
      instrumentationKey: environment.appInsights.instrumentationKey, message
    });
  }

  hideSecurityQuestion() {
    this.isDefalutMessage = false;
    localStorage.removeItem('loginFired');
    this.doNavigation();
  }

  startTimeOut() {
    window.onload = () => {
      this.resetTimer();
    };

    document.onmousemove = () => {
      this.resetTimer();
    };

    document.onkeypress = () => {
      this.resetTimer();
    };

  }

  resetTimer() {
    if (this.time !== 'stop') {
      console.log('no Idle');
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        console.log('timeout');
        this.modalService.dismissAll();

        this.errorMsg = 'Your session has timed-out and your registration could not be completed.';
        localStorage.setItem('errorMsg', this.errorMsg);
        this.removeLocalStorage();
        this.securityQuestionVerficationFailed();

      }, 4 * 60 * 1000);
    }
  }

  stopTimer() {
    clearTimeout(this.time);
    this.time = 'stop';
    console.log('Timer Stop');
    console.log(this.time);
  }

  removeLocalStorage() {
    localStorage.removeItem('OnLoadCheck');
    localStorage.removeItem('tempToken');
  }


  registrationDetails(callback) {
    var data = JSON.parse(sessionStorage.getItem("AzureTableStorageData"));
    data.startSecurityQuestions = this.startSecurityQuestions;
    data.Stream2Method = this.stream2Method;
    data.SecurityQuestionsStatus = this.securityQuestionsStatus;
    data.securityQuestionsSetCompleted = (this.securityQuestionsSetCompleted == 0 ) ? null : this.securityQuestionsSetCompleted;
  
     this.registrationService.registrationDetails(data).subscribe((data: any) => {
        if(data){
          this.azureTableStorageData = data;
          sessionStorage.setItem("AzureTableStorageData", JSON.stringify(this.azureTableStorageData));
        }

        if(callback){
          callback();
        }
     },
       (err: HttpErrorResponse) => {
       });
   }

}
