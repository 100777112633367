import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormBuilder } from '@angular/forms';
import { InputValidationService } from '../helpers/validators/input-validation.service';
import { RegExValidation } from '../helpers/constants/regex-validation.constants';
import { UserService } from '../services/user.service';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})

export class UpdatePasswordComponent implements OnInit {
  updatePasswordForm;
  password;
  errorMsg = '';
  successMsg = '';
  passwordLen: any; 
  passwordDig: any; 
  passwordLow: any; 
  passwordUp: any; 
  passwordSpecial: any;
  
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    public router: Router,
  ) {}

  ngOnInit() {
      this.updatePasswordForm = this.formBuilder.group({
        Password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(RegExValidation.password)]],
        ConfirmPassword: ['', Validators.required]
      }, {
        validator: [
          InputValidationService.passwordMustMatch('Password', 'ConfirmPassword'),
        ],
      });
  }

  get updatePasswordFormControls() { return this.updatePasswordForm.controls; }

  updatePassword() {
    this.errorMsg = '';
    this.successMsg = '';
    if (this.updatePasswordForm.invalid) {
      this.updatePasswordForm.markAllAsTouched();
      return;
    }
    console.log('password updation initiated');
    const param = {
      Password: this.updatePasswordForm.value.Password
    };

    this.userService.userPasswordUpdate(param).subscribe((data: any) => {
      this.successMsg = 'Password updated successfully';  
      this.errorMsg = '';
      this.clearPasswordForm();

      // Set a timeout to redirect to dashboard after 5 seconds
      setTimeout(() => {
         window.location.href = '/portal/dashboard';
      }, 5000); // 5000 milliseconds = 5 seconds
    },
      (err: HttpErrorResponse) => {
          this.successMsg = ''; 
          this.errorMsg = 'Failed to update password, please try again later';
          this.clearPasswordForm();
      });
  }


  classPwrd: boolean = false;
  pwrdTextType: boolean;
  pwrdToggle(event: any) {
    this.classPwrd = !this.classPwrd;
    this.pwrdTextType = !this.pwrdTextType;
  }
  classConfirmPwrd: boolean = false;
  conpwrdTextType: boolean;
  confirmpwrdToggle(event: any) {
    this.classConfirmPwrd = !this.classConfirmPwrd;
    this.conpwrdTextType = !this.conpwrdTextType;
  }

  popOverOpened(){
    if(this.password != null){
      const variations = {
        digits: /\d/.test(this.password),
        lower: /[a-z]/.test(this.password),
        upper: /[A-Z]/.test(this.password),
        nonWords: /[$@$!%*?& "%'()*+,-./:;<=>[\]^_`{|}~#\\]/.test(this.password),
        length: this.password.length >= 8,
      };

      var popover = document.getElementById('ngb-popover-0');
          if(popover != null){
            if (variations.length) {
              var passwordLen = document.getElementById('password-length');
              var lengthSp = document.getElementById('password-length-sp');
              passwordLen.classList.add('password-requirement-matched');
              lengthSp.classList.add('tick-mark');
            }
            if (variations.digits) {
              var passwordDig = document.getElementById('password-digit');
              var DigitSp = document.getElementById('password-digit-sp');
              passwordDig.classList.add('password-requirement-matched');
              DigitSp.classList.add('tick-mark');
            }
            if (variations.lower) {
              var passwordLow = document.getElementById('password-lowercase');
              var LowerSp = document.getElementById('password-lowercase-sp');
              passwordLow.classList.add('password-requirement-matched');
              LowerSp.classList.add('tick-mark');
            }
            if (variations.upper) {
              var passwordUp = document.getElementById('password-uppercase');
              var UpperSp = document.getElementById('password-uppercase-sp');
              passwordUp.classList.add('password-requirement-matched');
              UpperSp.classList.add('tick-mark');
            }
            if (variations.nonWords) {
              var passwordSpecial = document.getElementById('password-specialchar');
              var SpecialSp = document.getElementById('password-specialchar-sp');
              passwordSpecial.classList.add('password-requirement-matched');
              SpecialSp.classList.add('tick-mark');
            }
          }
    }

  }

  PassVal(){
    if(this.password == ''){
      var passwordDig = document.getElementById('password-digit');
      var DigitSp = document.getElementById('password-digit-sp'); 
      var passwordLow = document.getElementById('password-lowercase');
      var LowerSp = document.getElementById('password-lowercase-sp');
      var passwordUp = document.getElementById('password-uppercase');
      var UpperSp = document.getElementById('password-uppercase-sp');    
      var passwordSpecial = document.getElementById('password-specialchar');
      var SpecialSp = document.getElementById('password-specialchar-sp');
      var passwordLen = document.getElementById('password-length');
      var lengthSp = document.getElementById('password-length-sp');

      passwordLen.classList.remove('password-requirement-matched');
      lengthSp.classList.remove('tick-mark');     
      passwordDig.classList.remove('password-requirement-matched');
      DigitSp.classList.remove('tick-mark');
      passwordLow.classList.remove('password-requirement-matched');
      LowerSp.classList.remove('tick-mark');
      passwordUp.classList.remove('password-requirement-matched');
      UpperSp.classList.remove('tick-mark');
      passwordSpecial.classList.remove('password-requirement-matched');
      SpecialSp.classList.remove('tick-mark');

    }
  }

  clearPasswordForm()
  {
    this.updatePasswordForm.reset();
    // Iterate over each control to mark it as untouched, pristine, and clear errors
    Object.keys(this.updatePasswordFormControls).forEach(key => {
      const control = this.updatePasswordForm.get(key);
      control.setErrors(null); 
      control.markAsUntouched();
      control.markAsPristine();
    });
    this.updatePasswordForm.updateValueAndValidity();
  }
}

