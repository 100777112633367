import { Component, OnInit , ViewChild, ElementRef, } from '@angular/core';
import { NgbModal, NgbModalOptions , NgbModule , NgbTooltipModule  } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { ReferralDataService } from '../services/referral-data-service.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-referral-programme',
  templateUrl: './referral-programme.component.html',
  styleUrls: ['./referral-programme.component.css']
})
export class ReferralProgrammeComponent implements OnInit {
  @ViewChild('referralTermPop', { static: false }) referralTermPop: ElementRef;
  ReadMore:boolean = false;
  referralId = null;
  hasReferralFeature = false;
  userType = 1;

  modalOptions: NgbModalOptions;
  constructor(
    private modalService: NgbModal, 
    private cookieService: CookieService,
    private userService: UserService,
    private router: Router,
    private referralDataService: ReferralDataService
  ) { }

  ngOnInit(): void {
    this.checkForReferral();
  }

  
  copyInputMessage(){
    const message = this.getReferralCustomMessage()
    navigator.clipboard.writeText(message);
    $("#readmore").show();
    setTimeout(function () {
      $("#readmore").hide();
  }, 1200);
    
  }

  sendReferralMessageViaWhatsApp() {
    let message = this.getReferralCustomMessage();
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  }

  getReferralCustomMessage()
  {
    let referralCode = this.referralId;
    if(referralCode == null || referralCode ==''){
      this.checkForReferral();
      referralCode = this.referralId;
    }
    let sapiBaseUrl = environment.sapiBaseUrl;
    let redirectionBaseUrl = sapiBaseUrl + `/home?pid=17&websource=referral&referralid=`;
    let referralLink = redirectionBaseUrl + referralCode;
    const message = `Hey, I’m on the road to debt freedom with DebtBusters. I’d like to help you do the same – plus, when you join DebtBusters, we can earn R1,500 cash each. Tap ${referralLink} to learn more.`;
    return message;
  }

  referralTermsPopup(){
    this.modalService.open(this.referralTermPop,{ size: 'xl', backdrop: 'static' } );
  }

  checkForReferral()
  {
    this.referralDataService.getReferralData().subscribe(([referralId, hasReferralFeature]) => {
      this.referralId = referralId;
      this.hasReferralFeature = hasReferralFeature;
    });
    if(!this.hasReferralFeature){
      this.router.navigate(['portal/dashboard']);
    }
  }

}
