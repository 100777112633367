import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import { DashboardService } from './dashboard.service';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private cookieService: CookieService, 
    private dashboardService: DashboardService,
    private userService: UserService) {}

    private redisCacheDown = new Subject<boolean>();
    redisCacheDown$ = this.redisCacheDown.asObservable();

    
    private dbcType1 = new Subject<boolean>();
    dbcType1$ = this.dbcType1.asObservable();

  public showHideTopMenubar() {
    //show li in menu of FAQ
    let uType = this.cookieService.get('uType');
    if (uType != null && uType == '1') { 
      $('#liFAQ').show();
    } else {
      $('#liFAQ').hide(); 
    }

    let hasInsurance = this.cookieService.get('hasInsurance');
    if(hasInsurance == 'true' && uType === '1') {
      $('#liInsurance').show();
    }
    else {
      $('#liInsurance').hide();
    }
 }

 public fetchSimplicityData(callback) {
    let simplicityData = JSON.parse(sessionStorage.getItem('simplicityData'));
    if(!simplicityData) {
      this.dashboardService.getSimplicityDetails().subscribe((data: any) => {
        simplicityData = data;
        if(data.isBalanceDetailsAvailable == true) {
          sessionStorage.setItem('simplicityData', JSON.stringify(simplicityData));
        }

        if (this.cookieService.get('userId') === '') {
          this.cookieService.set('userId', data.userId, null, '/', undefined, true);
        }
  

        if(callback) {
          callback(simplicityData);
        }
      }, (err: HttpErrorResponse) =>{
        if(err.status  === 404){
          this.redisCacheDown.next(true);
        }
        });
    } else if(callback) {
      callback(simplicityData);
    }
 }


  // Remoove in V-40
  // getUserType(){
  //   this.userService.getUserType().subscribe((data: any) => {
  //       if(data === 1){
  //         this.cookieService.set('uType', data, null, '/', undefined, true); 
  //         this.dbcType1.next(true);
  //       }
  //     },
  //     (err: HttpErrorResponse) => {
  //     });
  // }


}
