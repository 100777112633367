<!--footer
<footer class="footer mt-auto">
  <div class="container p-md-0">
      <div class="d-flex row justify-content-between align-items-center">
          <div class="col-md-6 mobilespce">
              <p class="m-0 footer-text pe-md-5 text-md-start text-center">&#64; 2020 DebtBusters &#45; 5th Floor, 11 Adderley Street, Cape Town, South Africa, 8001
                  <br/> <a class="t-link" href="tel:0869990606">086 999 0606</a> </p>
          </div>
          <div class="col-md-5  d-none d-md-block ">
              <div class=" justify-content-end d-flex">
                  <a href="https://www.facebook.com/DebtbustersIntelligentDebtManagement"><i class="social-media-facebook"></i></a>
                  <a href="https://twitter.com/DebtBusters_IDM"><i class="social-media-twitter"></i></a>
                  <a href="https://www.youtube.com/user/DebtBusters1"><i class="social-media-utube"></i></a>
                  <a href="https://soundcloud.com/user-210301453"><i class="social-media-cloud"></i></a>
                  <a href="https://www.linkedin.com/company/intelligent-debt-management-group"><i class="social-media-linkdin"></i></a>--->
                  <!--<a href="mailto:info@debtbusters.co.za"><i class="social-media-mail"></i></a>-->
              <!--</div>
          </div>
      </div>
  </div>
</footer>
footer--->

<footer class="footr b5containeradjust">
    <div class="container ">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
            
            <div class="col- pe-md-2 pe-lg-0">
                <h5 class="cont-caption">Contact Us</h5>
                <ul class="cont-list">
                    <li>
                        <span class="line-break"> 4th Floor, Mutual Park,</span> Jan Smuts Drive, Pinelands, <br /> Cape Town, 7405 <br />
                        <a id="emailLink" class="blackLink" href="mailto:info@debtbusters.co.za">info@debtbusters.co.za</a>
                    </li>
                </ul>
            </div>
            <div class="col- pe-md-2 pe-lg-0">
                <h5 class="cont-caption">Operating Hours:</h5>
                <ul class="cont-list">
                    <li>
                        Mon-Thu: 07:00 - 21:00
                    </li>
                    <li>
                        Fri: 07:00 - 18:00
                    </li>
                    <li>
                        Sat: 09:00 - 12:30
                    </li>
                    <li class="toggle-devices d-none">a</li>
                </ul>
            </div>
            <div class="col- d-flex py-md-2 border-left-black ps-md-3 align-items-center">
                <i class="phicon">
                    <svg id="Layer_1_1_" style="enable-background:new 0 0 16 16;" version="1.1" viewBox="0 0 16 16"
                        xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path
                            d="M5,16h6c1.105,0,2-0.895,2-2V2c0-1.105-0.895-2-2-2H5C3.895,0,3,0.895,3,2v12C3,15.105,3.895,16,5,16z M4,2h8v12H4V2z" />
                    </svg>
                </i>
                <div>
                    <h5 class="cont-caption lightw mb-0">Call our experts now</h5>
                    <ul class="cont-list m-0">
                        <li>
                            <a id="expertContactNumber" class="calllg" href="tel:0861365910">0861 365 910</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col- text-center">
                <img src="../../assets/images/r.png" alt="" class="ricon" />
                
            </div>
            <div class="col-">
                <img src="../../assets/images/ndca.png" class="ndcaimg" alt="" />
            </div>
        </div>
    </div>
</footer>
