import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DashboardService } from '../services/dashboard.service';
import { UserService } from '../services/user.service';
import { AppService } from '../services/app.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  host: { 'class': 'd-flex flex-column h-100' },
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  accountData: any = {};
  otherAccountsData: any = {};
  showMore = {
    accountsSummary: false,
    judgmentInfo: false,
    debtRestructure: false,
    previousSearchInfo: false,
    propertyInfo: false
  };
  creditReportYear; creditReportMonth;
  isDataAvailable: true;
  updatedDate = '';
  userType = undefined;
  simplicityAccData: any = null;
  showDisclaimer: boolean = true;
  isRedisCacheDown: boolean = false;

  constructor(
    private accountService: AccountService,
    private dashboardService: DashboardService,
    private userService: UserService,
    private appService: AppService,
    private cookieService: CookieService,
  ) {
    this.appService.redisCacheDown$.subscribe(res => {
      if (res) {
        this.isRedisCacheDown = true
      }
    });
  }

  ngOnInit() {
    this.creditReportYear = new Date().getFullYear();
    this.creditReportMonth = new Date().getMonth() + 1;
    this.getUserType();
    this.getCreditHistory();
    this.getAccountSummary();
    this.getOtherAccounts();
    // window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    // if ((window as any).fcWidget){
    //   (window as any).fcWidget.destroy();
    // }
  }

  getAccountSummary() {
    var loaddata = sessionStorage.getItem('loadAccountSummary');
    if (loaddata != null) {
      this.BindAccountSummary(JSON.parse(loaddata));
      return;
    }
    this.accountService.accountSummary().subscribe((data: any) => {
      this.BindAccountSummary(data);
    },
      (err: HttpErrorResponse) => {
      });

  }

  BindAccountSummary(data) {
    sessionStorage.removeItem('loadAccountSummary')
    sessionStorage.setItem('loadAccountSummary', JSON.stringify(data));
    console.log(data, '----------------------');
    this.accountData = data;

  }


  showDisclaimerCheck(showDisclaimer) {
    this.showDisclaimer = showDisclaimer;
  }
  getOtherAccounts() {
    var loaddata = sessionStorage.getItem('loadOtherAccounts');
    if (loaddata != null) {
      this.BindOtherAccounts(JSON.parse(loaddata));
      return;
    }
    this.accountService.otherAccounts().subscribe((data: any) => {
      this.BindOtherAccounts(data);
    },
      (err: HttpErrorResponse) => {
      });

  }

  BindOtherAccounts(data) {
    sessionStorage.removeItem('loadOtherAccounts')
    sessionStorage.setItem('loadOtherAccounts', JSON.stringify(data));
    this.otherAccountsData = data;
    console.log(this.otherAccountsData)

  }

  getCreditHistory() {

    var loaddata = sessionStorage.getItem('loadCreditHistory');
    if (loaddata != null) {
      this.BindCreditHistory(JSON.parse(loaddata));
      return;
    }
    const param = { Year: this.creditReportYear, Month: this.creditReportMonth };
    this.dashboardService.creditHistory(param).subscribe((data: any) => {
      this.BindCreditHistory(data);
    },
      (err: HttpErrorResponse) => {
      });
  }

  BindCreditHistory(data) {
    sessionStorage.removeItem('loadCreditHistory')
    sessionStorage.setItem('loadCreditHistory', JSON.stringify(data));

    console.log('dash', data);
    this.isDataAvailable = data?.isDataAvailable;
    this.updatedDate = data?.createdDate;
  }

  scrollToElement(id: any): void {
    const element = document.getElementById(id);
    if (element != null) {
      const offset = 100;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }

  }

  getUserType() {
    let type = this.cookieService.get('uType');
    if (type != '' || type != undefined) {
      this.userType = Number(type);
      if (this.userType === 1) {
        this.showDisclaimerCheck(false);
        this.getSimplicityDetails();
      }
    }
    else {
      this.userService.getUserType().subscribe((data: any) => {
        this.userType = data;
        this.cookieService.set('uType', data, null, '/', undefined, true);
        if (this.userType === 1) {
          this.showDisclaimerCheck(false);
        }
      },
        (err: HttpErrorResponse) => {
        });
    }

  }

  getSimplicityDetails() {
    var data = sessionStorage.getItem('loadSimplicityDetails');
    if (data != null) {
      this.BindSimplicityDetails(JSON.parse(data));
      return;
    }

    this.appService.fetchSimplicityData((data: any) => {
      this.BindSimplicityDetails(data);
    });

  }

  BindSimplicityDetails(data) {
    if (data) {
      this.simplicityAccData = data.balanceDetails.obligations;
    }
  }




}
