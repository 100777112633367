import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../services/user.service';
import { InsuranceService } from '../services/insurance.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class InsuranceRouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private insuranceService: InsuranceService,
    private userService: UserService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.loadUserType((userType) => {
        if (userType == '1') {
          this.isInsurancePending((isPending) => {
            if (isPending) {
              this.router.navigate(['/portal/insurance']);
              window.location.reload();
              observer.next(false);
            } else {
              observer.next(true);
            }
            observer.complete();
          });
        } else {
          observer.next(true);
          observer.complete();
        }
      });
    });
  }

  loadUserType(callback: (userType: string) => void) {
    let type = this.cookieService.get('uType');
    if (type && type != '') {
      callback(type);
    } else {
      this.userService.getUserType().subscribe(
        (data: any) => {
          this.cookieService.set('uType', data, null, '/', undefined, true);
          callback(this.cookieService.get('uType'));
        },
        (error) => {
          callback('2');
        }
      );
    }
  }

  loadPolicyHolderData(callback: (data: any) => void) {
    var loadData = sessionStorage.getItem('loadPolicyHolderDetails');
    if (loadData != null) {
      callback(loadData);
    } else {
      this.insuranceService.getPolicyHolderDetails().subscribe(
        (data: any) => {
          sessionStorage.setItem('loadPolicyHolderDetails', JSON.stringify(data));
          callback(sessionStorage.getItem('loadPolicyHolderDetails'));
        },
        (error) => {
          callback(null);
        }
      );
    }
  }

  isInsurancePending(callback: (isPending: boolean) => void) {
    this.loadPolicyHolderData((data) => {
      if (data == null) {
        callback(false);
        return;
      }

      const jsonData = JSON.parse(data);
      if (
        (jsonData.hasCreditLifeCover ||
          jsonData.hasLifeCover ||
          jsonData.hasFuneralCover) &&
        jsonData.state &&
        jsonData.state.toLowerCase() == 'pending'
      ) {
        callback(true);
      } else {
        callback(false);
      }
    });
  }
}
