   <!-- Header HTML Start -->
   <app-header></app-header>
   <!-- Header HTML End -->
  
  

  <section class="terms">
    <!-- <div class="terms-banner">
      <div class="container p-md-0">
          <div class="d-flex row flex-wrap align-items-center">
              <div class="col-md-9 col-8 no-space-right">
                  <h1 class="page-caption text-white m-0">Terms and Conditions</h1>
              </div>
              <div class="col-md-3 text-md-end col-4">
                <i class="terms-banner-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 512 512" height="120px" viewBox="0 0 512 512" width="120px" class=""><g><g><path d="m446.606 104.394-100-100c-2.813-2.813-6.628-4.394-10.606-4.394h-220c-30.327 0-55 24.673-55 55v402c0 30.327 24.673 55 55 55h280c30.327 0 55-24.673 55-55v-342c0-3.978-1.581-7.793-4.394-10.606zm-95.606-53.181 48.787 48.787h-48.787zm45 430.787h-280c-13.785 0-25-11.215-25-25v-402c0-13.785 11.215-25 25-25h205v85c0 8.284 6.716 15 15 15h85v327c0 13.785-11.215 25-25 25z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#A3D55F"/><path d="m356 202c-18.955 0-35.996-10.532-44.472-27.484l-2.111-4.223c-2.541-5.083-7.735-8.293-13.417-8.293h-80c-5.682 0-10.876 3.21-13.417 8.292l-2.111 4.222c-8.476 16.954-25.517 27.486-44.472 27.486-8.284 0-15 6.716-15 15v85.597c0 33.654 10.619 65.715 30.708 92.716 20.089 27 47.747 46.384 79.982 56.054 1.406.422 2.858.633 4.31.633s2.904-.211 4.31-.633c32.235-9.67 59.893-29.054 79.982-56.054 20.089-27.001 30.708-59.062 30.708-92.716v-85.597c0-8.284-6.716-15-15-15zm-15 100.597c0 54.256-33.971 101.385-85 118.66-51.029-17.275-85-64.404-85-118.66v-71.995c22.701-4.285 42.437-18.2 54.125-38.603h61.75c11.688 20.402 31.424 34.318 54.125 38.603z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#A3D55F"/><path d="m287.82 268.109-45.962 45.961-17.678-17.677c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l28.284 28.284c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394l56.568-56.568c5.858-5.858 5.858-15.355 0-21.213-5.856-5.858-15.354-5.858-21.211 0z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#A3D55F"/></g></g> </svg>
                </i>
              </div>
          </div>  
      </div>
    </div> -->
    <div class="b5containeradjust">
      <div class="container pt-4">
        <h1 class="page-caption m-0">Terms and Conditions</h1>
      </div>
    </div>
    <div class="terms-hld pt-md-5 pt-3 b5containeradjust">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="editor-hld">
              <ul class="hasnav pb-4">
                <li><a id="anchor-contracting-terms" (click)="scrollToElement(contracting)">Who you are contracting with</a></li>
                <li><a id="anchor-service-terms" (click)="scrollToElement(service)">Our service</a></li>
                <li><a id="anchor-verifyIdentity-terms" (click)="scrollToElement(verifyIdentity)">Verify identity</a></li>
                <li><a id="anchor-tratpersonalInfo-terms" (click)="scrollToElement(tratpersonalInfo)">How we treat your personal information</a></li>
                <li><a id="anchor-yourresponsibilities-terms" (click)="scrollToElement(yourresponsibilities)">Your responsibilities</a></li>
                <li><a id="anchor-ourResponsibilities-terms" (click)="scrollToElement(ourResponsibilities)">Our responsibilities</a></li>  
                <li><a id="anchor-restrictAccess-terms" (click)="scrollToElement(restrictAccess)">Restrict access</a></li>
                <li><a id="anchor-weOwnTheContentOfThisSite-terms" (click)="scrollToElement(weOwnTheContentOfThisSite)">We own the content of this site</a></li>
                <li><a id="anchor-WhenThisContractEnds-terms" (click)="scrollToElement(WhenThisContractEnds)">When this contract ends</a></li>
                <li><a id="anchor-aboutThisContract-terms" (click)="scrollToElement(aboutThisContract)">About this contract</a></li>
                <li><a id="anchor-ContactUs-terms" (click)="scrollToElement(ContactUs)">Reach us</a></li>
              </ul>

              <h2>DB CLIENT WEBSITE TERMS OF SERVICE</h2>
              <p>This notice was last updated on 18 July 2020.</p>
              <p>This contract applies to you when you use the DebtBusters Client website or our services, and when you interact with us.</p>

              <h2 #contracting>Who you are contracting with</h2>
              <p>We are DebtBusters Client, a division of Debt Matters (Pty) Ltd t/a DebtBusters. We own and run the DebtBusters Client service and the website <a id="anchor-LinkToDebtbusters-Terms" href="www.debtbusters-client.co.za">www.debtbusters-client.co.za</a></p>
              <p>DebtBusters is a subsidiary of Intelligent Debt Management (Pty) Ltd and the holding company is IDM Holdings (Pty) Ltd.</p>
              
              
              <h2 #service>Our service</h2>
              <p>
                <strong>We give you free access to your credit report and credit score</strong><br/>
                To use our service, you must register through our website. Your profile will give you access to your credit report and your credit score. By registering for our service, you give us permission to obtain these monthly reports for you from a  registered  credit bureau. We will let you know by email when a new report is available. <br/>
                If you have not registered to use our services, but if a credit report is drawn with your permission, we also create a profile for you where you can access your credit report as required, and we may send you communication to visit your profile.
              </p>
              <p>
                <strong>We will help you understand your credit position</strong><br/>
                We analyse and profile your credit information, to help you better understand and manage your credit position.<br/>
                We may provide you with information, tools and tips to help you analyse and better understand your own credit profile.
              </p>
              <p>
                <strong>We will introduce you to other useful services</strong><br/>
                If your credit profile shows that you are over-indebted, or struggling with debt, we may give you access to services to help you manage your debt. And if we find credit products provided by other companies that we think may be suitable for your credit profile, we will let you know. 
              </p>
              <p>
                <strong>Our service is for South African residents over 18</strong><br/>
                You must be a South African resident and at least 18 years old to use our services.<br/>
                You may access and use our service only for your own personal use, and not for commercial purposes.
              </p>


              <h2 #verifyIdentity>We will verify your identity</h2>
              <p>Before we give you access to your credit profile and if we have not identified you previously, we will ask you a few security questions to verify your identity. Our questions are based on information we received about you from a credit bureau. We do this to protect you. We are dealing with sensitive financial information, and we need to be absolutely sure that we share the right information with the right person. </p>


              <h2 #tratpersonalInfo>How we treat your personal information</h2>
              <p>We will keep your personal information confidential. Read our Privacy Notice to see how we collect and use personal information.</p>



              <h2 #yourresponsibilities>Your responsibilities</h2>
              <p class="mb-1">
                <strong>Use our service and our website responsibly</strong><br/>
                You must not submit any content or engage in any activity that:<br/>
              </p>
              <ol class="d mb-4">
                <li>Is illegal</li>
                <li>Is fraudulent</li>
                <li>Is an attempt to impersonate another person or entity</li>
                <li>Violates a third-party right, including the right to privacy or any intellectual property rights</li>
                <li>causes us or our affiliates or service providers harm</li>
              </ol>

              <p>
                <strong>Give us accurate information, and keep it up to date</strong><br/>
                Our service is based on the information that you provide. It is your responsibility to keep it up to date and to let us know immediately if any of your information changes.
              </p>

              <p>
                <strong>Keep your password to yourself</strong><br/>
                Keep your username and password safe and do not share it with anyone else. You are responsible for anything that is done through your profile, whether you allowed it or not. If you think that your information has been exposed or is at risk, you must reset your password immediately and contact us at <a id="anchor-MailToDebtbusters-Terms" href="mailto:client@debtbusters.co.za.">client@debtbusters.co.za</a>.
              </p>
              <p>
                <strong>Protect yourself online</strong><br/>
                It is your responsibility to check the terms and conditions of any website, service or resource linked from our website when you visit it. We cannot take responsibility for the quality of the products or services made available through linked websites and resources.
              </p>
              


              <h2 #ourResponsibilities>Our responsibilities</h2>
              <p>
                <strong>We obtain your credit score and credit report from a credit bureau on your behalf</strong><br/>
                We do not check that the information we receive is accurate or complete. If you think your information is inaccurate or incomplete, you should contact the credit bureau directly.
              </p>

              <p>
                <strong>We give you useful, but general information</strong><br/>
                The information on our website, while it is useful, is general. We do not give you financial advice.
              </p>

              <p>
                <strong>We provide links to related services, but we are not responsible for them</strong><br/>
                We may provide links to other websites, resources or products or services offered by other companies. That does not mean that we recommend their content, products or services. We are not responsible for these websites or resources, and they are governed by their own terms.
              </p>
              <p>
                <strong>We have processes in place to ensure that our information is accurate, available, and virus-free</strong><br/>
                We have processes in place to make sure that the information on our website is accurate, up to date, always available and virus-free. Still, we cannot guarantee that this will always be the case. You cannot hold us responsible for any loss you suffer as a result of errors, inaccuracies, unavailable information, or viruses, unless we caused it by our deliberate and reckless behaviour.
              </p>
              <p>
                <strong>We have reasonable security in place</strong><br/>
                We have implemented reasonable security safeguards to protect your information. But no system is 100% secure. So, while we will do everything reasonably necessary to protect the information, we cannot guarantee that our system will not be hacked or accessed without permission. We cannot guarantee that information will not be lost, or that a data breach will not take place. You can only hold us liable if a security incident was caused by our deliberate and reckless behaviour.
              </p>
              <p>
                Please let us know immediately if you think that your profile has been compromised at <a id="anchor-LinkToDebtbusters1-Terms" href="www.debtbusters-client.co.za">www.debtbusters-client.co.za</a>.  To change your password, <a id="anchor-RedirectToForgotPassword-Terms" href="www.debtbusters-client.co.za/forgot">www.debtbusters-client.co.za/forgot</a>.
              </p>
              
  
              <h2 #restrictAccess>When we may restrict access to the service</h2>
              <p>
                On rare occasions, our service may be unavailable. For instance, you may not be able to access your profile while we conduct maintenance to the site, if we experience technical difficulties, or for security reasons. We do not have to compensate you if you cannot access your profile temporarily. We will do our best to keep this type of interruption to a minimum.
              </p>
              <p class="mb-1">We may restrict or suspend access deliberately if</p>
              <ol class="d mb-4">
                <li>We believe that another person uses your profile</li>
                <li>You breach this contract or applicable law</li>
                <li>We cannot verify or authenticate information that you provide</li>
                <li>We believe that you are conducting activities that are illegal, abusive, threaten the integrity of the service, or may damage our reputation.</li>
              </ol>

              
              <h2 #weOwnTheContentOfThisSite>We own the content of this site</h2>
              <p>
                <strong>We own the content and the trademarks</strong><br/>
                We, or our content suppliers, own the copyright in all material on this site, and the way it&#39;s been put together, whether it&#39;s text, graphics, databases or software (&#39;Content&#39;).
              </p>
              <p>We own all the trademarks on this site.</p>
              <p>
                <strong>You may use the Content with permission</strong><br/>
                You may download or print the Content for your personal use and information only, provided that you acknowledge us appropriately.
              </p>
              <p>
                If you wish to use the Content for any other reason you must have our permission.
              </p>
              <p>
                <strong>You must use our Content responsibly</strong><br/>
                If you use our Content, you may not alter it or use it in a way that will damage our reputation. You must not remove any acknowledgement that we or any of our contributors are the authors of any Content.
              </p>
              <p>
                If you don&#39;t use our Content responsibly and respect our intellectual property, we will enforce our intellectual property rights.
              </p>

              
              <h2 #WhenThisContractEnds>When this contract ends</h2>
              <p>
                The contract ends when you or we close your profile.
              </p>
              <p>
                <strong>You can ask us to close your profile</strong><br/>
                To close your profile, you can contact us and ask that we close your profile.
              </p>
              <p class="mb-1">
                <strong>We can close your profile for other reasons</strong> <br/>
                We can deactivate your profile at any time if:
              </p>
              <ol class="d mb-4">
                <li>You breach this contract or applicable law</li>
                <li>We have reason to believe that the service or your profile is being used fraudulently</li>
                <li>You use the service to harass, abuse, or otherwise infringe or violate the rights of any other person</li>
                <li>You haven&#39;t logged in to your profile for more than twelve consecutive months</li>
                <li>We decide to withdraw the service</li>
                <li>We no longer do business</li>
              </ol>
              <p>We will give you notice by email if we close your profile.</p>
              
              <p class="mb-1">
                <strong>What happens when your profile is closed</strong><br/>
                When this contract ends, for whatever reason you won&#39;t have the right to access the service  and Even after this contract ends, some terms will remain in place, including:
              </p>
              <ol class="d mb-4">
                <li>Use our service and our website responsibly</li>
                <li>We have processes in place to ensure that our information is accurate, available, and virus-free</li>
                <li>We own the content of this site</li>
              </ol>
              <p>
                We are not responsible for any damages or losses you may suffer because your contract is terminated for any reason.
              </p>
              <h2 #aboutThisContract>About this contract</h2>
              <ol class="mb-4">
                <li>South African law governs this contract and any related disputes.</li>
                <li>This is our entire contract regarding the use of the service.</li>
                <li>We will update this contract from time to time. When we do, we will let you know by email and update the date at the top of this page. The &#39;new&#39; contract will be in force from the date published at the top of this page. If you continue to use the service after the contract has changed, it means that you accept the changes.</li>
                <li>This contract cannot be changed or set aside through conduct.</li>
                <li>If one part of this agreement becomes invalid or unenforceable, the rest of it remains enforceable. </li>
                <li>We may freely hand over our rights and obligations under this contract to any of our affiliates or in connection with a merger, acquisition, restructuring, or sale of assets, or by operation of law, or otherwise, and we may transfer your information to any of our affiliates, successor entities, or new owner so that you can continue to use our service.</li>
              </ol>
              
              
              <h2 #ContactUs>How you can reach us</h2>
              <p>
                <strong>You can contact us at:</strong><br/>
                Email: <a id="anchor-MailToDebtbusters2-Terms" href="mailto:client@debtbusters.co.za">client@debtbusters.co.za</a><br/>
                Telephone: <a id="anchor-CellNumber-Terms" href="tel:0869990606">086 999 0606</a>
              </p>

              <p><strong>If you have a question about credit bureau information or a third-party service, we will refer your question to them.</strong></p>
  
  
            </div>
        </div>
        </div>
      </div>
    </div>
  </section>
 



  
  
  
  
  
  

  <!-- Contact HTML Start 
  <app-contact></app-contact>-->
  <!-- Contact HTML End -->
  
  <!-- Footer HTML Start -->
  <app-footer></app-footer>
  <!-- Footer HTML End -->