export namespace Enum {
    export enum CreditScoreStatusType {
        NoScore = 1,
        VeryHighRisk,
        HighRisk,
        MediumHighRisk,
        MediumRisk,
        MediumLowRisk,
        LowRisk,
        VeryLowRisk
    }

    export enum OtpType {
        Sms = 1,
        Email = 2
    }
}
