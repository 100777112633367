<div autoIdButtons class="d-flex flex-column h-100">
    <router-outlet></router-outlet>
    <ngx-spinner id="spinner" bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#60a845" type="ball-circus"></ngx-spinner>
    <ng-template #logoutModel let-modal>
        <div class="modal-header">
            <h4 class="modal-title text-dblack" id="modal-basic-title">Would you like to stay? </h4>
        </div>
        <div class="otp-form">
            <div class="modal-body">
                {{idleState}}
            </div>
            <div class="modal-footer">
                <button name="btnLogoutComponent" id="btn-LogoutComponent-TimeOutPopUp" type="button" (click)="logout(null)" class="btn-border">LOGOUT</button>
                <button name="btnStayComponent" id="btn-StayComponent-TimeOutPopUp" type="button" (click)="stay()" class="btn btn-success">STAY</button>
            </div>
        </div>
    </ng-template>
</div>
