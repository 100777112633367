import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsService } from '../services/appinsights.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  env = environment; 
  appInsights: ApplicationInsights;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private authService: OAuthService,
    private appInsightsService: AppInsightsService

  ) {
    this.appInsights = appInsightsService.appInsightsObj();
  }

  totalRequests = 0;
  completedRequests = 0;
  showLoader: boolean = true;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //  const excludeShowLoader = ['user/validateuserbasicinfowithbureau', 'chat/save', environment.azureAdConfig.b2cDomain];
    // const excludeHideLoader = ['credithistory', environment.azureAdConfig.b2cDomain];
    
    this.spinner.show();
    this.totalRequests++;


    if (request.url.indexOf('communication/IdmLeadCreateAutoInsert') !== -1) {
     this.showLoader = false;
    }
    if (request.url.indexOf('Sapi/SapiRegistraionProcess') !== -1) {
      this.showLoader = false;
     }

    if(!this.showLoader){
      this.spinner.hide();
    }

    if (request.url.indexOf(environment.azureAdConfig.b2cDomain) === -1) {
      request = request.clone({
        headers: this.addExtraHeaders(request.headers)
      });
    }

    return next.handle(request).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          if (request.urlWithParams.startsWith(this.env.webApiUrl)) {
           if(request.body){
             if(request.body.hasOwnProperty('Password') || request.body.hasOwnProperty('ConfirmPassword')) 
              {
                request.body.Password = "****";
                request.body.ConfirmPassword = "****";
              }
           }
            this.appInsights.trackEvent({
              name: 'APIRequestResponseSuccess',
              properties: {
                URL: request.urlWithParams,
                RequestParam: request.body,
                Response: evt,
                SubmissionId: this.cookieService.get('submissionId'),
                UserId: this.cookieService.get('userId')
              }
            });
 
            console.log('interceptor log success:'); 
          }
         
        }
      }),
      catchError(
        err =>
          new Observable<HttpEvent<any>>(observer => {
            if (err instanceof HttpErrorResponse) {
              const errResp = err as HttpErrorResponse;
           
              if (request.urlWithParams.startsWith(this.env.webApiUrl)) { //here first we check is error url is our api url.
                if(request.body){
                  if(request.body.hasOwnProperty('Password') || request.body.hasOwnProperty('ConfirmPassword')) 
                   {
                     request.body.Password = "****";
                     request.body.ConfirmPassword = "****";
                   }
                }
                this.appInsights.trackEvent({
                  name: 'APIRequestResponseException',
                  properties: {
                    URL: request.urlWithParams,
                    RequestParam: request.body,
                    Response: err,
                    SubmissionId: this.cookieService.get('submissionId'),
                    UserId: this.cookieService.get('userId')
                  }
                });

                console.log('interceptor error block log:', request.urlWithParams); 

                if (errResp.status === 401 || errResp.status === 0) {
                  this.cookieService.delete('submissionId', '/');
                  sessionStorage.removeItem('simplicityData');
                  sessionStorage.clear();
                  setTimeout(() => {
                    this.authService.logOut();
                  }, 2000);
                }
              }


            }
            observer.error(err);
            observer.complete();
          })
      )
    ).pipe(
      finalize(() => {
        this.completedRequests++;
        // console.log(this.completedRequests, this.totalRequests);
        if (this.completedRequests === this.totalRequests) {
          // if (hideSpinner) { 
          this.spinner.hide();
          // } 
          this.completedRequests = 0;
          this.totalRequests = 0;
        }
      })
    );
  }

  private addExtraHeaders(headers: HttpHeaders): HttpHeaders {
    let submissionId = '';
    if (this.cookieService.get('submissionId')) {
      submissionId = this.cookieService.get('submissionId');
    }
    headers = headers.append('SubmissionId', submissionId);
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    headers = headers.append('Version', 'V2');
    if (localStorage.getItem('tempToken')) {
      headers = headers.append('Authorization', `Bearer ${localStorage.getItem('tempToken')}`);
    }
    if (this.cookieService.get('CreditHistoryId')) {
      headers = headers.append('CreditHistoryId', this.cookieService.get('CreditHistoryId'));
    }
    return headers;
  }
}
